<si-tree-view
  class="card elevation-1 h-100"
  [enableContextMenuButton]="true"
  [enableSelection]="true"
  [folderStateStart]="true"
  [singleSelectMode]="true"
  [items]="treeItems!"
  (treeItemClicked)="onTreeItemClicked($event)"
  [contextMenuItems]="menuItems"
  [siBackdropWithSpinner]="(isLoading$ | async)!"
>
</si-tree-view>
