import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { ActiveProjectState } from '../../../active-project/models/active-project-state.model';
import {
  SetupFormModel,
  SetupForm,
} from '../../../api-client/services/lcas-api-client/models/setup-form.model';
import { SetupFormItem } from '../../../models/business/setup-form-item.model';
import { SetupFormComponent } from '../setup-form.component';
import { SetupFormOutputService } from './setup-form-output.service';

@Injectable()
export class SetupFormService {
  constructor(
    private router: Router,
    private store: Store<{ project: ActiveProjectState }>,
    private modalService: BsModalService,
    private outputService: SetupFormOutputService,
  ) {}

  display(
    formConfig: SetupFormItem,
    type?: 'device' | 'function',
    shouldNotReloadScheduler?: boolean,
  ): Observable<SetupFormModel | undefined> {
    if (!formConfig.config) {
      throw new Error('Config must be passed as parameter!');
    }

    return this.isFullscreenFormType(formConfig.config)
      ? this.navigateToRoutedSetupForm(
          formConfig,
          type,
          shouldNotReloadScheduler,
        )
      : this.displayModal(formConfig);
  }

  private displayModal(
    config: SetupFormItem,
  ): Observable<SetupFormModel | undefined> {
    this.modalService.show(SetupFormComponent, {
      class: 'modal-dialog-centered',
      ariaLabelledBy: 'electric-meters-setup-modal',
      initialState: {
        formItem: config,
        output: this.outputService.routedSetupFormOutput,
        isModal: true,
      },
    });
    return this.outputService.routedSetupFormOutput;
  }

  private navigateToRoutedSetupForm(
    config: SetupFormItem,
    type?: 'device' | 'function',
    shouldNotReloadScheduler?: boolean,
  ): Observable<SetupFormModel | undefined> {
    if (!type) {
      throw new Error(
        'For the standalone routed setup form providing a type is mandatory.',
      );
    }

    if (!shouldNotReloadScheduler) {
      this.store
        .select((store) => store.project.activeProject?.id)
        .pipe(
          first(),
          switchMap((projectId) =>
            this.router.navigate(
              ['sidemenu', projectId, 'setup-form', config.id, type],
              {
                state: { config },
              },
            ),
          ),
        )
        .subscribe();
    }

    return this.outputService.routedSetupFormOutput.asObservable();
  }

  private isFullscreenFormType(setupForm: SetupForm): boolean {
    return setupForm.additionalData?.displayMode === 'fullscreen';
  }
}
