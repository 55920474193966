export const functionStatusMapping: {
  [k: string]: { icon: string; text: string; colorClass?: string };
} = {
  idle: {
    icon: 'element-not-checked status-caution',
    text: 'GLOBALS.STATUS.IDLE',
  },
  running: {
    icon: 'element-double-right status-success',
    text: 'GLOBALS.STATUS.RUNNING',
  },
  waiting: {
    icon: 'element-double-right status-success',
    text: 'GLOBALS.STATUS.RUNNING',
  },
  halted: {
    icon: 'element-out-of-service status-warning',
    text: 'GLOBALS.STATUS.HALTED',
  },
  'ready-to-deploy': {
    icon: 'element-expand',
    text: 'GLOBALS.STATUS.READY_TO_DEPLOY',
  },
  'connection-lost': {
    icon: 'element-connection-lost',
    text: 'GLOBALS.STATUS.CONNECTION_LOST',
  },
};

// waiting values are duplicated running values on purpose as requested by PM & PO
