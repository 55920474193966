import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalRef } from '@simpl/element-ng';

import { Floor } from '../../../../../shared/api-client/services/lcas-api-client/models/floor.model';
import { LocationsState } from '../../../../shared/location-store/models/location.mode';
import { LocationActions } from '../../../../shared/location-store/store/location.actions';
import { LocationType } from '../../../../shared/models/location-type.enum';

@Component({
  selector: 'app-edit-floor-modal',
  template: `
    <app-edit-location-base-modal
      [type]="locationType.Floor"
      [location]="floor"
      [modalRef]="modalRef"
      (save)="save($event)"
    ></app-edit-location-base-modal>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditFloorModalComponent {
  @Input() modalRef?: ModalRef;

  @Input() floor?: Floor;

  locationType = LocationType;

  constructor(
    private store: Store<{
      floorsAndLocations: LocationsState;
    }>,
  ) {}

  save(name: string) {
    if (this.floor) {
      this.store.dispatch(
        LocationActions.initPatchFloor({
          floor: { ...this.floor, name },
        }),
      );
    }
    this.modalRef?.hide();
  }
}
