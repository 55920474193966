import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SiLandingPageModule } from '@simpl/element-ng';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { SiSharedModule } from '@building-x/common-ui-ng';

import { ApiClientModule } from '../api-client/api-client.module';
import { AuthRedirectComponent } from './auth-redirect/auth-redirect.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AreaAccessService } from './services/area-access.service';
import { AuthService } from './services/auth.service';
import { PermissionService } from './services/permission.service';
import { SsoComponent } from './sso/sso.component';
import { AuthEffects } from './store/auth.effects';
import { authReducers } from './store/auth.reducers';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    StoreModule.forFeature('auth', authReducers),
    EffectsModule.forFeature([AuthEffects]),
    SiLandingPageModule,
    OAuthModule.forRoot(),
    TranslateModule,
    ApiClientModule,
    SiSharedModule,
  ],
  declarations: [SsoComponent, AuthRedirectComponent],
  providers: [
    AuthService,
    AreaAccessService,
    PermissionService,
    { provide: OAuthStorage, useFactory: () => localStorage },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class AuthModule {}
