import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';

import { RouteStorageObject } from './models/route-storage.model';
import { setupFormComponentRoutePath } from '../../sidemenu/sidemenu-routing.module';
import { BuildingComponent } from '../../v2/sidemenu/building/building.component';
import { PlannedDevicesComponent } from '../../v2/sidemenu/commissioning/planned-devices/planned-devices.component';

export class LcasRouteReuseStrategy implements RouteReuseStrategy {
  storedRoutes: Record<string, RouteStorageObject> = {};

  isSetupFormPreviousRoute: boolean | undefined;

  isSetupFormFutureRoute: boolean | undefined;

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data?.reuseComponent && !!this.isSetupFormFutureRoute;
  }

  store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle | null,
  ): void {
    this.storedRoutes = handle
      ? {
          ...this.storedRoutes,
          [route.routeConfig?.path ?? '']: {
            snapshot: route,
            handle,
          },
        }
      : {};
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return (
      !!this.isSetupFormPreviousRoute &&
      !!this.storedRoutes[route.routeConfig?.path ?? '']
    );
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.storedRoutes[route.routeConfig?.path ?? '']?.handle ?? null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    if (future.routeConfig?.path !== curr.routeConfig?.path) {
      if (curr.routeConfig?.path === setupFormComponentRoutePath) {
        this.isSetupFormPreviousRoute = true;
        this.isSetupFormFutureRoute = false;
      } else if (future.routeConfig?.path === setupFormComponentRoutePath) {
        this.isSetupFormFutureRoute = true;
        this.isSetupFormPreviousRoute = false;
      } else {
        this.isSetupFormPreviousRoute = false;
        this.isSetupFormFutureRoute = false;
        this.storedRoutes = {};
      }
    }

    return (
      future.routeConfig === curr.routeConfig ||
      this.isNavigatingBetweenBuildingComponentRoutes(future, curr) ||
      this.isNavigatingBetweenPlannedDevicesComponentRoutes(future, curr)
    );
  }

  private isNavigatingBetweenBuildingComponentRoutes(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    return (
      future.routeConfig?.component === BuildingComponent &&
      curr.routeConfig?.component === BuildingComponent
    );
  }

  private isNavigatingBetweenPlannedDevicesComponentRoutes(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    return (
      future.routeConfig?.component === PlannedDevicesComponent &&
      curr.routeConfig?.component === PlannedDevicesComponent
    );
  }
}
