<div *ngIf="selectedLocation">
  <si-empty-state
    class="card-body"
    icon="element-actuator"
    heading="{{ 'BUILDING.EMPTY_ROOM.NO_DEVICE' | translate }}"
    content="{{ 'BUILDING.EMPTY_ROOM.DESCRIPTION' | translate }}"
  >
    <p>
      <button
        *ngIf="showAddDevicesButton"
        type="button"
        class="btn btn-primary"
        [attr.data-testid]="'add-devices-empty-room-btn'"
        (click)="addDevices.emit()"
      >
        <i class="icon element-plus"></i>
        {{ 'BUILDING.ADD_DEVICES' | translate }}
      </button>
    </p>
  </si-empty-state>
</div>
