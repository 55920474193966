import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FloorWithLocations } from '../../../sidemenu/planning/disciplines-and-functions/models/floor-with-locations.model';
import { MainDetailContainerService } from './main-detail-container.service';

@Injectable({ providedIn: 'root' })
export class LocationNavigationService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mainDetailsService: MainDetailContainerService,
  ) {}

  handleNavigation(
    selectedFloorId: string | undefined,
    selectedLocationId: string | undefined,
    floorsWithLocations: FloorWithLocations[],
    deletedLocationId?: string,
  ): void {
    if (deletedLocationId) {
      this.handleNavigationAfterDeletion(
        selectedFloorId,
        selectedLocationId,
        deletedLocationId,
        floorsWithLocations,
      );
    } else {
      this.handleGeneralNavigation(
        selectedFloorId,
        selectedLocationId,
        floorsWithLocations,
      );
    }
  }

  initNavigation(
    floorId: string | undefined,
    locationId: string | undefined,
    relativeToParent: boolean = false,
  ): void {
    const navigationPath = [floorId, locationId].filter(
      (id) => id !== undefined,
    );

    this.router.navigate(navigationPath, {
      relativeTo: relativeToParent
        ? this.activatedRoute.parent
        : this.activatedRoute,
    });
  }

  private handleGeneralNavigation(
    selectedFloorId: string | undefined,
    selectedLocationId: string | undefined,
    floorsWithLocations: FloorWithLocations[],
  ): void {
    if (!selectedFloorId && !selectedLocationId) {
      this.navigateByFloorAndLocationAvailability(floorsWithLocations);
    } else {
      this.validateFloorAndLocationSelectionIdsFromUrl(
        selectedFloorId,
        selectedLocationId,
        floorsWithLocations,
      );
    }
  }

  private validateFloorAndLocationSelectionIdsFromUrl(
    selectedFloorId: string | undefined,
    selectedLocationId: string | undefined,
    floorsWithLocations: FloorWithLocations[],
  ): void {
    if (!selectedFloorId && selectedLocationId) {
      this.navigateToMainRoot();
      return;
    }
    const floor = floorsWithLocations.find((f) => f.id === selectedFloorId);
    if (
      !floor ||
      (selectedLocationId && !this.isLocationOnFloor(floor, selectedLocationId))
    ) {
      this.navigateToMainRoot();
    }
  }

  private isLocationOnFloor(
    floor: FloorWithLocations,
    locationId: string,
  ): boolean {
    return floor.locations.some((loc) => loc.id === locationId);
  }

  private handleNavigationAfterDeletion(
    selectedFloorId: string | undefined,
    selectedLocationId: string | undefined,
    deletedLocationId: string,
    floorsWithLocations: FloorWithLocations[],
  ): void {
    if (
      deletedLocationId !== selectedFloorId &&
      deletedLocationId !== selectedLocationId
    )
      return;

    if (floorsWithLocations.length === 0) {
      this.navigateToMainRoot();
      return;
    }

    if (!selectedLocationId) {
      this.navigateToFirstAvailableFloor(floorsWithLocations);
      return;
    }

    this.navigateByFloorAndLocationAvailability(floorsWithLocations, true);
  }

  private navigateByFloorAndLocationAvailability(
    floorsWithLocations: FloorWithLocations[],
    navigateRelativeToParent?: boolean,
  ): void {
    const floor = this.findFloorWithLocations(floorsWithLocations);
    if (floor) {
      this.navigateToFirstLocationOfFloor(floor, navigateRelativeToParent);
    } else {
      this.navigateToFirstAvailableFloor(
        floorsWithLocations,
        navigateRelativeToParent,
      );
    }
  }

  private navigateToFirstAvailableFloor(
    floorsWithLocations: FloorWithLocations[],
    relativeToParent?: boolean,
  ): void {
    if (floorsWithLocations[0]) {
      this.initNavigation(
        floorsWithLocations[0].id,
        undefined,
        relativeToParent,
      );
    }
  }

  private navigateToFirstLocationOfFloor(
    floor: FloorWithLocations,
    relativeToParent?: boolean,
  ): void {
    this.initNavigation(floor.id, floor.locations[0].id, relativeToParent);
    if (!relativeToParent) {
      this.mainDetailsService.notifyDetailsActive(true);
    }
  }

  private findFloorWithLocations(
    floorsWithLocations: FloorWithLocations[],
  ): FloorWithLocations | undefined {
    return floorsWithLocations.find((f) => f.locations.length > 0);
  }

  private navigateToMainRoot(): void {
    if (this.activatedRoute.snapshot.params.projectId) {
      if (this.router.url.includes('building')) {
        this.navigateToBuildingRoot();
      } else if (this.router.url.includes('scan-and-assign')) {
        this.navigateToScanAndAssignRoot();
      }
    }
  }

  private navigateToBuildingRoot(): void {
    this.router.navigate([
      '/sidemenu',
      this.activatedRoute.snapshot.params.projectId,
      'building',
    ]);
  }

  private navigateToScanAndAssignRoot(): void {
    this.router.navigate([
      '/sidemenu',
      this.activatedRoute.snapshot.params.projectId,
      'commissioning',
      'scan-and-assign',
    ]);
  }
}
