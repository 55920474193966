import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { EMPTY, Observable } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { authRoutes } from '../auth.routes';
import { SessionService } from '../services/session.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly interceptableUrls = [
    environment.userApi,
    environment.apiURL,
    environment.pointApi,
    environment.locationApi,
    `${environment.deviceApi}/${environment.deviceApiVersion}`,
    environment.subscriptionApi,
    environment.edgeApi,
    `${environment.aodsApi}/${environment.aodsApiVersion}`,
  ];

  constructor(
    private oAuthService: OAuthService,
    private router: Router,
    private sessionService: SessionService,
  ) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      !this.oAuthService.hasValidAccessToken() &&
      this.router.routerState.snapshot.url &&
      !this.isAuthRoute(this.router.routerState.snapshot.url)
    ) {
      this.sessionService.terminateExpiredSession();
      return EMPTY;
    }

    return this.interceptableUrls.some((url) => req.url.startsWith(url))
      ? next.handle(
          req.clone({
            setHeaders: {
              authorization: `Bearer ${this.oAuthService.getAccessToken()}`,
            },
          }),
        )
      : next.handle(req);
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  private isAuthRoute(url: string): boolean {
    return authRoutes.some((x) => x.path && url.startsWith(`/${x.path}`));
  }
}
