import { createAction, props } from '@ngrx/store';

export const TutorialActions = {
  setTutorials: createAction(
    '[Tutorials] Set visible or hidden',
    props<{
      showTutorials: boolean;
    }>(),
  ),
};
