import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

@Injectable()
export class UrlBuilderService {
  build(url: string, queryParams: Params): string {
    return `${url}${Object.entries(queryParams).reduce(
      (acc, [key, value], index) =>
        value === undefined || value === null
          ? acc
          : `${acc}${index === 0 ? '?' : '&'}${key}=${value}`,
      '',
    )}`;
  }
}
