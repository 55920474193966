import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn } from '@angular/router';

import { AuthService } from '../services/auth.service';

export const authCanActivateChildGuard: CanActivateChildFn = (
  childRoute: ActivatedRouteSnapshot,
): boolean => {
  return inject(AuthService).isAuthenticated(
    childRoute.url.map((segment) => segment.path),
  );
};
