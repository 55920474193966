<div class="modal-header">
  <p class="si-title-2 mb-0" id="modal-title">
    {{ 'BUILDING.RELOCATE_EDGE_DEVICE.TITLE' | translate }}
  </p>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    [attr.data-testid]="'close-btn'"
    (click)="modalRef?.hide()"
  ></button>
</div>
<div class="modal-body">
  <p class="si-body-1 mt-2 mb-0">
    <span>{{
      (entityToDelete === 'floor'
        ? 'BUILDING.RELOCATE_EDGE_DEVICE.FLOOR_TEXT'
        : 'BUILDING.RELOCATE_EDGE_DEVICE.ROOM_TEXT'
      ) | translate
    }}</span>
  </p>
</div>
