import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { LcasApiClientService } from '../../../api-client/services/lcas-api-client/lcas-api-client.service';
import { PlannedDevice } from '../../../api-client/services/lcas-api-client/models/planned-device.model';
import { TypedSetupFromHelperService } from '../interfaces/typed-setup-form-helper-service.interface';

@Injectable()
export class SetupFormDeviceHelperService
  implements TypedSetupFromHelperService
{
  constructor(
    private router: Router,
    private apiClientService: LcasApiClientService,
  ) {}

  navigateOnClose(relativeTo: ActivatedRoute): Promise<boolean> {
    return this.router.navigate(['../../../commissioning', 'scan-and-assign'], {
      relativeTo,
    });
  }

  getSetupFormItem(itemId: string): Observable<PlannedDevice> {
    return this.apiClientService.getDevice(itemId);
  }
}
