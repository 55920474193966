import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

import { Location } from '../../../../shared/api-client/services/lcas-api-client/models/location.model';

@Component({
  selector: 'app-empty-location',
  templateUrl: './empty-location.component.html',
})
export class EmptyLocationComponent {
  @HostBinding('class') classes = 'd-flex flex-column flex-grow-1';

  @Input() selectedLocation?: Location;

  @Input() showAddDevicesButton?: boolean;

  @Output() addDevices = new EventEmitter();
}
