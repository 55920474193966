import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { LcasApiClientService } from '../api-client/services/lcas-api-client/lcas-api-client.service';
import { Device } from '../api-client/services/lcas-api-client/models/device.model';
import { SetupFormModel } from '../api-client/services/lcas-api-client/models/setup-form.model';
import { SetupFormService } from '../formly/setup-form/services/setup-form.service';
import { SetupFormItem } from '../models/business/setup-form-item.model';

@Injectable()
export class DeviceCommissioningService {
  constructor(
    private setupFormService: SetupFormService,
    private lcasApiClientService: LcasApiClientService,
  ) {}

  displaySetupForm(
    deviceForm: SetupFormItem,
  ): Observable<SetupFormModel | undefined> {
    return this.setupFormService.display(deviceForm, 'device').pipe(
      first(),
      switchMap((setupFormModel) =>
        setupFormModel ? of(setupFormModel) : of(undefined),
      ),
    );
  }

  displaySetupFormAndCommissionDevice(
    deviceForm: SetupFormItem,
    commission?: boolean,
  ): Observable<Device | undefined> {
    return this.setupFormService.display(deviceForm, 'device').pipe(
      first(),
      switchMap((setupFormModel) => {
        if (setupFormModel) {
          return this.saveSetupForm(
            deviceForm.id,
            setupFormModel,
            deviceForm.eTag,
            commission,
          );
        }
        return of(undefined);
      }),
    );
  }

  private saveSetupForm(
    deviceId: string,
    setupFormModel: SetupFormModel,
    eTag: number,
    commission?: boolean,
    credentialsToken?: string,
  ): Observable<Device> {
    return this.lcasApiClientService
      .patchFormDevice(deviceId, { form: setupFormModel, eTag })
      .pipe(
        switchMap((updatedDevice) =>
          updatedDevice.setupStatus === 'COMMISSIONED' || commission
            ? this.lcasApiClientService.commissionPeripheralDevice(
                updatedDevice.id,
                {
                  eTag: updatedDevice.eTag,
                  borderRouterId: setupFormModel?.borderRouter as
                    | string
                    | undefined,
                  credentialsToken,
                },
              )
            : of(updatedDevice),
        ),
        first(),
      );
  }
}
