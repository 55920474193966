import { ColumnMode } from '@siemens/ngx-datatable';
import { SI_DATATABLE_CONFIG } from '@simpl/element-ng';

export const datatableConfig = {
  columnMode: ColumnMode.force,
  cssClasses: SI_DATATABLE_CONFIG.cssClasses,
  tableClass: 'table-element elevation-1',
  headerClass: 'text-body py-5',
  headerHeight: 50,
  rowHeightSmall: 64,
  rowHeightMedium: 90,
  rowHeightLarge: 112,
  actionsColumnWidth: 64,
  arrowColumnWidth: 50,
  trackByProp: 'id',
  rowIdentity: (row: { id: string }) => row.id,
};
