import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Customer } from '@building-x/common-ui-ng';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  first,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { isEmpty } from 'lodash';

import { LcasApiClientService } from '../../api-client/services/lcas-api-client/lcas-api-client.service';
import { LocationApiClientService } from '../../api-client/services/location-api-client/location-api-client.service';
import { AuthState } from '../../auth/models/auth-state.model';
import { AuthActions } from '../../auth/store/auth.actions';
import { EdgeDeviceActions } from '../../devices-commissioning/store/edge-device.actions';
import { ActiveProjectState } from '../models/active-project-state.model';
import { ActiveProjectActions } from '../store/active-project.actions';
import { Building } from '../../api-client/services/location-api-client/models/building.model';
import { LocationActions } from '../../../v2/shared/location-store/store/location.actions';

@Injectable()
export class ActiveProjectService {
  constructor(
    private lcasApiClientService: LcasApiClientService,
    private locationApiClientService: LocationApiClientService,
    private store: Store<{ project: ActiveProjectState; auth: AuthState }>,
    private router: Router,
  ) {}

  checkProjectAccess(projectId: string): Observable<boolean | UrlTree> {
    return projectId
      ? this.store.pipe(
          first(),
          switchMap((store) =>
            projectId === store.project.activeProject?.id
              ? of(true)
              : this.checkProjectIdValidityAndSet(
                  projectId,
                  store.auth.customers,
                ),
          ),
        )
      : of(this.router.createUrlTree(['/sidemenu', 'planning', 'projects']));
  }

  private checkProjectIdValidityAndSet(
    projectId: string,
    customers: Customer[],
  ): Observable<boolean | UrlTree> {
    return this.lcasApiClientService.getCustomerIdByPartitionId(projectId).pipe(
      switchMap((ownerCustomer) =>
        this.store
          .select((store) => store.auth.selectedCustomer)
          .pipe(
            first(),
            tap((storedSelectedCustomer) => {
              if (!ownerCustomer?.id) {
                throw new Error('Customer not found!');
              }

              const ownerCustomerAccess = customers.find(
                (c) => c.id === ownerCustomer?.id,
              );
              const sharedCustomerAccess = customers.find((c) =>
                ownerCustomer?.sharedWith?.includes(c.id!),
              );
              const selectedCustomerAccess =
                (ownerCustomerAccess &&
                  storedSelectedCustomer?.id === ownerCustomer?.id) ||
                ownerCustomer?.sharedWith?.includes(
                  storedSelectedCustomer?.id ?? '',
                );
              const validCustomer = ownerCustomerAccess ?? sharedCustomerAccess;

              if (!selectedCustomerAccess && !validCustomer) {
                throw new Error('No valid customer found!');
              }

              if (
                validCustomer &&
                (!storedSelectedCustomer?.id ||
                  (storedSelectedCustomer.id && !selectedCustomerAccess))
              ) {
                this.store.dispatch(
                  AuthActions.setSelectedCustomer({
                    selectedCustomer: validCustomer,
                  }),
                );
              }
            }),
          ),
      ),
      switchMap(() => this.lcasApiClientService.getProject(projectId)),
      tap((project) => {
        this.store.dispatch(ActiveProjectActions.set({ project }));
        this.store.dispatch(ActiveProjectActions.initLoadDevicesCatalog());
        this.store.dispatch(ActiveProjectActions.initLoadFunctionsCatalog());
      }),
      tap(() => {
        this.store.dispatch(EdgeDeviceActions.clearEdgeStore());
        this.store.dispatch(LocationActions.clearState());
      }),
      mergeMap(() =>
        this.locationApiClientService.getBuildings().pipe(
          map(
            (buildingsData) =>
              <Building>{
                ...(isEmpty(buildingsData.data) ? {} : buildingsData.data[0]),
                address: isEmpty(buildingsData.included)
                  ? {}
                  : buildingsData.included[0],
              },
          ),
          tap((building) =>
            this.store.dispatch(ActiveProjectActions.setBuilding({ building })),
          ),
        ),
      ),
      map(() => true),
      catchError(() =>
        of(this.router.createUrlTree(['/active-project-error'])),
      ),
    );
  }
}
