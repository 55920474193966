import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, Observable, of, tap } from 'rxjs';

import { ActiveProjectState } from '../active-project/models/active-project-state.model';
import { ActiveProjectActions } from '../active-project/store/active-project.actions';
import { LcasApiClientService } from '../api-client/services/lcas-api-client/lcas-api-client.service';
import { Product } from '../api-client/services/lcas-api-client/models/product.model';
import { ProjectStatus } from '../api-client/services/lcas-api-client/models/project-status.enum';
import { Project } from '../api-client/services/lcas-api-client/models/project.model';

@Injectable()
export class UpdateProjectStatusService {
  private project!: Project;

  constructor(
    private lcasApiClientService: LcasApiClientService,
    private store: Store<{ project: ActiveProjectState }>,
  ) {}

  updateProjectStatus(
    isEdgeDeviceCommissioningFlow: boolean,
    products?: Product[],
  ): Observable<Project> {
    this.store
      .select((store) => store.project.activeProject)
      .pipe(
        first(),
        tap((project) => {
          if (project) {
            this.project = project;
          }
        }),
      )
      .subscribe();

    if (this.project.status === 'operational') {
      return of(this.project);
    }

    if (isEdgeDeviceCommissioningFlow) {
      return this.patchProject('operational');
    }

    const shouldSetStatusToInstallationReady =
      this.hasProductsWithQuantity(products!) &&
      this.project.status === 'hw-selection';
    const shouldSetStatusToHwSelection =
      !this.hasProductsWithQuantity(products!) &&
      this.project.status === 'installation-ready';

    if (!shouldSetStatusToHwSelection && !shouldSetStatusToInstallationReady) {
      return of(this.project);
    }

    return shouldSetStatusToInstallationReady
      ? this.patchProject('installation-ready')
      : this.patchProject('hw-selection');
  }

  private hasProductsWithQuantity(products: Product[]): boolean {
    return products && products.some((product) => product.quantity > 0);
  }

  private patchProject(nextStatus: ProjectStatus): Observable<Project> {
    return this.lcasApiClientService
      .patchProject(this.project.id, {
        eTag: this.project.eTag,
        status: nextStatus,
      })
      .pipe(
        tap((updatedProject) =>
          this.store.dispatch(
            ActiveProjectActions.set({
              project: {
                ...this.project,
                status: updatedProject.status,
                eTag: updatedProject.eTag,
              },
              skipGetBuilding: true,
            }),
          ),
        ),
      );
  }
}
