<div class="row" *ngIf="!props.hidden">
  <div *ngIf="props.hideLabel ? hideLabel(to) : null"></div>
  <label *ngIf="props.description" class="col px-0 h5">{{ props.description }}</label>
  <div class="col input m-auto">
    <ng-container #fieldComponent></ng-container>
    <div *ngIf="showError" class="invalid-feedback d-block">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
  </div>
</div>
