export enum DeviceStatusCondition {
  EQUALS = 'equals',
  NOT_EQUALS = 'not_equals',
}

export const deviceStatusConfig = [
  {
    title: 'Battery state empty',
    dataType: 'BattStaEmpty',
    priority: 0,
    slot: 1,
    condition: DeviceStatusCondition.EQUALS,
    value: 'true',
  },
  {
    title: 'Frost protection state',
    dataType: 'FrPrtSta',
    priority: 1,
    slot: 1,
    condition: DeviceStatusCondition.EQUALS,
    value: 'true',
  },
  {
    title: 'Battery state low',
    dataType: 'BattStaLow',
    priority: 2,
    slot: 1,
    condition: DeviceStatusCondition.EQUALS,
    value: 'true',
  },
  {
    title: 'Actuator fault',
    dataType: 'ActrFlt',
    priority: 3,
    slot: 1,
    condition: DeviceStatusCondition.NOT_EQUALS,
    value: '0',
  },
];
