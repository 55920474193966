import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { isEqual } from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { SetupFormItem } from '../../../../shared/models/business/setup-form-item.model';
import { SetupFormModel } from '../../../../shared/api-client/services/lcas-api-client/models/setup-form.model';
import { FormlySchemaConverterService } from './services/formly-schema-converter.service';

@Component({
  selector: 'app-setup-form',
  templateUrl: './setup-form.component.html',
  styleUrls: ['./setup-form.component.scss'],
})
export class SetupFormComponent implements OnInit {
  @Input() formItem: SetupFormItem | undefined;

  @Input() isModal: boolean | undefined;

  @Input() output?: Subject<SetupFormModel | undefined>;

  form = new FormGroup({});

  fields$?: Observable<{ config: FormlyFieldConfig[]; model: SetupFormModel }>;

  initialModel: SetupFormModel | undefined = undefined;

  constructor(
    public bsModalRef: BsModalRef,
    private formlySchemaConverterService: FormlySchemaConverterService,
  ) {}

  ngOnInit(): void {
    this.fields$ = this.getFields();
  }

  submit(model: SetupFormModel) {
    if (!isEqual(this.initialModel, model)) {
      this.output?.next(model);
    }
    this.output?.next(undefined);
    this.close();
  }

  close(): void {
    this.output?.next(undefined);
    this.bsModalRef.hide();
  }

  private getFields(): Observable<{
    config: FormlyFieldConfig[];
    model: SetupFormModel;
  }> {
    return this.formlySchemaConverterService
      .getFieldConfig(
        this.formItem!.config!.schema,
        this.formItem!.config!.definitions,
      )
      .pipe(
        map((config) => {
          const configWithoutHiddenFields = config.map((model) => {
            return {
              ...model,
              fieldGroup:
                model.type === 'scan'
                  ? model.fieldGroup
                  : model.fieldGroup?.filter((fg) => fg.props?.hidden !== true),
            };
          });
          return {
            config: configWithoutHiddenFields,
            model: { ...this.formItem!.config!.model },
          };
        }),
      );
  }
}
