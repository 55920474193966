import { Component } from '@angular/core';
import { OAuthInfoEvent, OAuthService } from 'angular-oauth2-oidc';

import { LanguageService } from './shared/i18n/language.service';
import { OrientationService } from './shared/services/orientation.service';
import { SessionService } from './shared/auth/services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    public orientationService: OrientationService,
    private languageService: LanguageService,
    private oauthService: OAuthService,
    private sessionService: SessionService,
  ) {
    this.languageService.setLanguage();
    this.oauthService.events.subscribe((event) => {
      if (
        event instanceof OAuthInfoEvent &&
        event.type === 'token_expires' &&
        event.info === 'access_token'
      ) {
        this.sessionService.terminateExpiredSession();
      }
    });
  }
}
