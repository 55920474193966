import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { SetupFormModel } from '../../../api-client/services/lcas-api-client/models/setup-form.model';

@Injectable()
export class SetupFormOutputService {
  routedSetupFormOutput = new Subject<SetupFormModel | undefined>();

  isSchedulerLoading$ = new BehaviorSubject<boolean>(false);
}
