import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { defer, Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { AuthState } from '../models/auth-state.model';
import { AuthActions } from '../store/auth.actions';

@Injectable()
export class AuthService {
  private readonly config: AuthConfig = {
    issuer: environment.issuer,
    redirectUri: `${window.location.origin}/login`,
    clientId: environment.clientId,
    requireHttps: 'remoteOnly',
    responseType: 'code',
    scope: 'openid profile email',
    oidc: true,
    logoutUrl: `${environment.siemensIdBaseUrl}/v2/logout?returnTo=${encodeURI(
      environment.issuer,
    )}v2%2Flogout%3FreturnTo%3D${encodeURI(
      window.location.origin,
    )}%26client_id%3D${environment.clientId}`,
    postLogoutRedirectUri: `${window.location.origin}/sso`,
    disableAtHashCheck: true,
    customQueryParams: {
      display: 'sup',
      connection: environment.tenant,
      audience: environment.audience,
    },
  };

  constructor(
    private oAuthService: OAuthService,
    private store: Store<{ auth: AuthState }>,
    private router: Router,
  ) {}

  login(): Observable<boolean | undefined> {
    return this.store
      .select((store) => store.auth.shouldSkipSso)
      .pipe(
        distinctUntilChanged(),
        tap((shouldSkipSso) => {
          this.oAuthService.configure(this.config);
          this.oAuthService.loadDiscoveryDocumentAndTryLogin();
          if (shouldSkipSso) {
            this.oAuthService.initCodeFlow();
          } else {
            this.router.navigate(['/sso']);
          }
        }),
      );
  }

  collectClaims(): Observable<boolean> {
    return defer(() => {
      this.oAuthService.configure(this.config);
      return this.oAuthService.loadDiscoveryDocumentAndTryLogin();
    });
  }

  logOut(): void {
    this.oAuthService.configure(this.config);
    this.oAuthService.logOut();
  }

  isAuthenticated(path: string[] = ['/']): boolean {
    if (this.oAuthService.hasValidAccessToken()) {
      this.store.dispatch(AuthActions.loginInitiliazed({ loggedIn: true }));
      return true;
    }

    this.store.dispatch(
      AuthActions.initLogin({
        shouldSkipSso: false,
        shouldLoadOrganizations: true,
        redirectComponentRoute: path,
      }),
    );
    return false;
  }
}
