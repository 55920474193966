import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import {
  AnySchedule,
  AnyScheduleValueBasicType,
  AnyWeeklySchedule,
  SwitchingPoint,
} from '@simpl/element-value-types';
import { SchedulerOptions } from '@simpl/scheduler-ng';
import * as _ from 'lodash';

import { PrototypeValueService } from '../prototype-value/prototype-value.service';
import { SchedulerConstants } from '../../scheduler.constants';

const PUSH_INTERVAL = 5000;

@Injectable()
export class SchedulerApiService {
  private readonly modelSubject$: BehaviorSubject<{
    [key: string]: AnySchedule;
  }> = new BehaviorSubject<{ [key: string]: AnySchedule }>(
    SchedulerConstants.schedulerModel,
  );

  private model$: Observable<{ [key: string]: AnySchedule }> = combineLatest([
    timer(0, PUSH_INTERVAL),
    this.modelSubject$,
    this.prototypeValueService.prototypeValue$,
  ]).pipe(
    // eslint-disable-next-line no-empty-pattern
    map(([{}, model, prototypeValue]) =>
      Object.entries(model).reduce(
        (acc, [name, value]) => ({
          ...acc,
          [name]: this.updateTime({
            ...value,
            prototypeValue: prototypeValue[name] || value.prototypeValue,
          }),
        }),
        {},
      ),
    ),
  );

  constructor(private prototypeValueService: PrototypeValueService) {}

  getSchedule(objectId: string): Observable<AnySchedule> {
    return this.model$.pipe(map((models) => models[objectId]));
  }

  updateWeeklySchedule(
    objectId: string,
    days: AnyWeeklySchedule,
  ): Observable<AnyWeeklySchedule> {
    this.updateModel(objectId, `${objectId}.value.days`, days).pipe(
      map((schedule) => schedule.value.days),
    );

    return this.model$.pipe(
      first(),
      map((model) =>
        Object.entries(days).reduce(
          (updatedDays, [key, val]) => ({
            ...updatedDays,
            [key]: val.map(
              (setPoint: SwitchingPoint<AnyScheduleValueBasicType>) => ({
                ...setPoint,
                value:
                  setPoint.value === null
                    ? model[objectId].value.defaultValue
                    : setPoint.value,
              }),
            ),
          }),
          {} as AnyWeeklySchedule,
        ),
      ),
      switchMap((updatedDays) =>
        this.updateModel(objectId, `${objectId}.value.days`, updatedDays),
      ),
      map((schedule) => schedule.value.days),
    );
  }

  updateScheduleOptions(
    objectId: string,
    options: SchedulerOptions,
  ): Observable<AnySchedule> {
    return this.updateModel(objectId, `${objectId}.value`, {
      ...this.modelSubject$.value[objectId].value,
      defaultValue: options.defaultValue,
      effectivePeriod: options.effectivePeriod,
    });
  }

  private updateTime(model: AnySchedule): AnySchedule {
    const now = new Date();
    return {
      ...model,
      currentDate: `${now.getFullYear()}-${
        now.getMonth() + 1
      }-${now.getDate()}`,
      currentTime: `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`,
    };
  }

  private updateModel(
    objectId: string,
    path: string,
    value: any,
  ): Observable<AnySchedule> {
    this.modelSubject$.next(_.set(this.modelSubject$.value, path, value));
    return this.model$.pipe(
      first(),
      map((model) => model[objectId]),
    );
  }
}
