import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { SetupFormComponent } from './setup-form/setup-form.component';
import { activeProjectCanActivateGuard } from '../active-project/guards/active-project-can-activate.guard';

const setupFormChildRoute: Route = {
  component: SetupFormComponent,
  canActivate: [activeProjectCanActivateGuard],
  data: { breadcrumb: 'COMMISSIONING.SETUP_FORM.BREADCRUMB.TITLE' },
};

const routes: Routes = [
  {
    path: ':id/function',
    ...setupFormChildRoute,
  },
  {
    path: ':id/device',
    ...setupFormChildRoute,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedFormlyRoutingModule {}
