import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDecimal',
  pure: true,
})
export class LocalizedDecimalPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string | number | undefined, pattern = '1.0-9'): string {
    if (value === undefined) {
      return '';
    }
    const decimalPipe: DecimalPipe = new DecimalPipe(
      this.translateService.currentLang,
    );
    const result = decimalPipe.transform(value, pattern);
    return result || '';
  }
}
