import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Uuid, UuidService } from '../providers/uuid.provider';

@Injectable()
export class CorrelationIdInterceptor implements HttpInterceptor {
  private readonly interceptableUrls: string[] = [environment.apiURL];

  constructor(@Inject(UuidService) private uuidService: Uuid) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.interceptableUrls.some((url) => request.url.startsWith(url))
      ? next.handle(
          request.clone({
            setHeaders: {
              'X-Correlation-ID': this.uuidService.v4(),
            },
          }),
        )
      : next.handle(request);
  }
}
