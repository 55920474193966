import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable()
export class ContentActionBarService {
  private renderer: Renderer2;

  constructor(
    public rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * workaround for SIMPL issue https://code.siemens.com/simpl/simpl/-/issues/671#note_18150228
   * SIMPL states that it will be fixed with https://code.siemens.com/simpl/simpl-element/-/issues/948
   * Some of us has to follow that, update to the last SIMPL version and recheck the bahavior after SIMPL releases the fix
   */
  repositionSiDropdownContainer(e: MouseEvent): void {
    const siActionBar = e.target as HTMLElement;

    setTimeout(() => {
      const siDropdownContainerDiv = this.document.querySelector(
        'body > si-dropdown-container > div',
      ) as HTMLDivElement;
      const siDropdownContainerSiMenu = this.document.querySelector(
        'body > si-dropdown-container > div > si-menu',
      ) as HTMLElement;
      const { right } = this.renderer
        .parentNode(siActionBar)
        .getBoundingClientRect();

      if (!siDropdownContainerSiMenu) {
        this.repositionSiDropdownContainer(e);
      }

      if (
        right > 0 &&
        window.innerHeight <
          siDropdownContainerSiMenu?.getBoundingClientRect().bottom
      ) {
        const y =
          this.renderer.parentNode(siActionBar).getBoundingClientRect().y -
          siDropdownContainerSiMenu.getBoundingClientRect().height -
          parseInt(getComputedStyle(siDropdownContainerSiMenu).marginTop, 10);
        const transformString = `translate3d(${right}px, ${y}px, 0px)`;

        this.renderer.setStyle(
          siDropdownContainerDiv,
          'transform',
          transformString,
        );
      }
    }, 10);
  }
}
