import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable()
export class ScanFormPatternService {
  resolveValuesByFieldsPattern(
    fieldGroup: FormlyFieldConfig[],
    scannedCode: string,
  ): Record<string, string> {
    return fieldGroup.reduce(
      (acc, field) => {
        if (field.props?.pattern) {
          const match = new RegExp(field.props.pattern).exec(scannedCode);
          if (match) {
            return { ...acc, [field.key as string]: match[0] };
          }
          console.log(
            `No match found for '${field.key}' while resolving its pattern.`,
          );
          return acc;
        }
        throw new Error(`Pattern field not set for '${field.key}'.`);
      },
      {} as Record<string, string>,
    );
  }
}
