import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';

import { LocationApiClientService } from '../api-client/services/location-api-client/location-api-client.service';
import { BuildingUpdate } from '../api-client/services/location-api-client/models/update-building.model';
import { AddressUpdate } from '../api-client/services/location-api-client/models/update-address.model';

@Injectable()
export class UpdateLocationVerticalService {
  private readonly retryTimer = 2.5 * 1000;

  private readonly retryNumber = 3;

  constructor(private locationApiClientService: LocationApiClientService) {}

  updateBuilding(
    buildingId: string,
    updatedBuilding: BuildingUpdate,
  ): Observable<unknown> {
    return this.locationApiClientService
      .patchBuilding(buildingId, updatedBuilding)
      .pipe(retry({ count: this.retryNumber, delay: this.retryTimer }));
  }

  updateAddress(
    addressId: string,
    updatedAddress: AddressUpdate,
  ): Observable<unknown> {
    return this.locationApiClientService
      .patchAddress(addressId, updatedAddress)
      .pipe(retry({ count: this.retryNumber, delay: this.retryTimer }));
  }
}
