import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { merge, Observable } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';

@Injectable()
export class FormAbstractionService<T extends { id: string; name: string }> {
  constructor(private formBuilder: FormBuilder) {}

  createForm(
    array: T[],
    validators?: ((control: AbstractControl) => ValidationErrors | null)[],
    additionFormFields?: Record<string, unknown>,
  ): FormGroup {
    return this.formBuilder.group(
      array.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: [
            additionFormFields
              ? { value: curr.name, ...additionFormFields }
              : curr.name,
            { validators, updateOn: 'blur' },
          ],
        }),
        {},
      ),
    );
  }

  onControlChange(form: FormGroup): Observable<{
    id: string;
    value: string;
  }> {
    return merge(
      ...Object.entries(form.controls).map(([id, control]) =>
        control.valueChanges.pipe(
          filter(() => control.valid),
          startWith(control.value),
          pairwise(),
          filter(([previousValue, nextValue]) => previousValue !== nextValue),
          map(([, nextValue]) => ({ id, value: nextValue })),
        ),
      ),
    );
  }
}
