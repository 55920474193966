import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';

export const dataTestIdExtension: FormlyExtension = {
  prePopulate(config: FormlyFieldConfig) {
    if (config.key) {
      const field = config;
      field.props = {
        ...field.props,
        attributes: {
          'data-testid': field.key as string,
        },
      };
    }
  },
};
