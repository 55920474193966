import { inject } from '@angular/core';
import { CanMatchFn, Route, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AreaAccessService } from '../../services/area-access.service';

export const areaAccessCanMatchGuard: CanMatchFn = (
  route: Route,
): Observable<boolean | UrlTree> => {
  return inject(AreaAccessService).accessAreaGuard(route?.data?.area);
};
