<div class="modal-header">
  <h4 class="modal-title" id="sample-modal-title">
    {{ 'COMMISSIONING.FORCE_DELETE.TITLE' | translate }}
  </h4>
  <button
    type="button"
    class="btn btn-circle btn-sm btn-ghost element-cancel"
    aria-label="Close modal"
    (click)="onCloseModal()"
  ></button>
</div>
<div class="modal-body">
  <span>
    {{ 'COMMISSIONING.FORCE_DELETE.BODY' | translate }}
  </span>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCloseModal()">
    {{ 'GLOBALS.BUTTON.CLOSE' | translate }}
  </button>
  <button type="button" class="btn btn-danger" (click)="onForceDelete()">
    {{ 'GLOBALS.BUTTON.FORCE_DELETE' | translate }}
  </button>
</div>
