import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiToastNotificationService } from '@simpl/element-ng';

import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class ErrorToastInterceptor implements HttpInterceptor {
  constructor(private siToastNotificationService: SiToastNotificationService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          request.url.startsWith(environment.apiURL) &&
          !request.url.includes('things')
        ) {
          this.siToastNotificationService.queueToastNotification(
            'danger',
            'ERROR_TOAST.TITLE',
            '',
          );
        }
        return throwError(() => error);
      }),
    );
  }
}
