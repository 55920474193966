import '@angular/common/locales/global/de';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/it';
import '@angular/common/locales/global/nl';

import { Injectable, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable()
export class LanguageService implements OnDestroy {
  private langChangeSubscription: Subscription;

  constructor(private translateService: TranslateService) {
    this.langChangeSubscription = translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        localStorage.setItem('lang', event.lang);
      },
    );
  }

  ngOnDestroy(): void {
    this.langChangeSubscription.unsubscribe();
  }

  getSupportedLanguages(): string[] {
    return ['en', 'de', 'it'];
  }

  setLanguage(): void {
    this.translateService.use(this.getCurrentLanguage());
  }

  private getCurrentLanguage(): string {
    const userLanguage = localStorage.getItem('lang');
    if (userLanguage) {
      return userLanguage;
    }

    const browserLanguage = this.translateService.getBrowserLang();
    if (browserLanguage && this.isSupportedLanguage(browserLanguage)) {
      return browserLanguage;
    }
    return 'en';
  }

  private isSupportedLanguage(language: string): boolean {
    const supportedLanguages = this.getSupportedLanguages();
    return supportedLanguages.indexOf(language) >= 0;
  }
}
