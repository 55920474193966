import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { Store } from '@ngrx/store';

import { CreateDevice } from '../../../shared/api-client/services/lcas-api-client/models/create-device.model';
import { LocationActions } from '../location-store/store/location.actions';
import { LocationsState } from '../location-store/models/location.mode';
import { selectFloorsWithLocations } from '../location-store/store/location.reducers';
import { FloorLocation } from '../modal-dialogs/move-device-modal/models/floor-location.model';

@Injectable({ providedIn: 'root' })
export class DeviceActionService {
  openMoveDeviceModal$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  openForceDeleteDeviceModal$: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  openAddDevicesModal$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private store: Store<{
      floorsAndLocations: LocationsState;
    }>,
  ) {}

  getLocations(): Observable<FloorLocation[]> {
    return this.store.select(selectFloorsWithLocations).pipe(
      map((floors) => {
        return floors
          .map((f) =>
            f.locations.map((r) => {
              return {
                floor: { id: f.id, name: f.name, eTag: f.eTag },
                location: r,
              } as FloorLocation;
            }),
          )
          .flat()
          .sort((l1, l2) => {
            return (
              l1.floor.name.localeCompare(l2.floor.name) ||
              l1.location.name.localeCompare(l2.location.name)
            );
          });
      }),
    );
  }

  addDevices(devices: CreateDevice[]): void {
    this.store.dispatch(
      LocationActions.addMultipleDevices({
        devices,
      }),
    );
  }
}
