import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BackdropService {
  get visible(): boolean {
    return this.innerVisible;
  }

  readonly click$: Subject<MouseEvent> = new Subject<MouseEvent>();

  private innerVisible = false;

  toggle(visible?: boolean): void {
    setTimeout(() => {
      if (!visible) {
        this.innerVisible = !this.innerVisible;
      } else {
        this.innerVisible = visible;
      }
    });
  }
}
