<div *ngIf="device" class="card elevation-1 h-100">
  <div class="d-flex justify-content-center my-6">
    <div class="d-flex me-4">
      <img class="device-image" [src]="
          (mediaBaseUrl || '') +
          (device.thumbnail || '/assets/images/device-fallback.svg')
        " [width]="80" alt="Device image" [attr.data-testid]="
          'details-device-' + device.instance?.type + '-image'
        " />
    </div>
    <div class="d-flex ms-4" *ngIf="device.status"
      [attr.data-testid]="'details-device-' + device.instance?.type + '-status'">
      <i class="display-4 align-self-center {{
          statusMapping[device.status].icon
        }}" [attr.data-testid]="
          'details-card-status-icon' + statusMapping[device.status].icon
        " placement="right">
      </i>
      <p class="si-body-1 align-self-center m-0 {{
          statusMapping[device.status].colorClass
        }}">
        {{ statusMapping[device.status].text | translate }}
      </p>
    </div>
  </div>
  <div class="d-flex justify-content-start ps-6">
    <div class="d-flex ps-4">
      <p class="si-body-1 text-secondary col" [attr.data-testid]="'details-card-' + device.type + '-version'">
        {{ device.instance?.version?.model }}
      </p>
    </div>
  </div>
  <div class="h-100 mb-2 d-flex flex-column">
    <ng-scrollbar class="mb-0 device-properties-scrollbar">
      <ng-container *ngIf="properties$ | async as properties; else loadingSpinner">
        <ul class="ps-0 mb-0">
          <ng-container *ngFor="let property of properties; index as inputIndex">
            <li *ngIf="property.data.readOnly"
              class="row d-flex list-group-item border-start-0 border-end-0 justify-content-between">
              <p class="si-body-1 text-secondary col" [attr.data-testid]="'prop-title-' + property.data.title">
                {{ property.data.title }}
              </p>
              <p class="col text-end text-break" [attr.data-testid]="'prop-value-' + property.data.title" [class.text-muted]="
                  !edgeConnectivityInfo?.connected &&
                  edgeConnectivityInfo?.fetchedConnectivity
                ">
                <ng-container *ngIf="property.data['btzf:hasEnumMap']; else primitiveValue">
                  {{
                  (!property.status || (property.status.toLowerCase() !== 'timeout' &&
                  property.status.toLowerCase() !== 'failure')) ?
                  (property.value! | propertyEnum : property.data | translate) : '- - -'
                  }}
                </ng-container>
                <ng-template #primitiveValue>
                  {{
                  (!property.status || (property.status.toLowerCase() !== 'timeout' &&
                  property.status.toLowerCase() !== 'failure')) ?
                  (property.data.multipleOf ? (property.value | roundingDecimal : property.data.multipleOf | pointVerticalValuePipe) : (property.value | pointVerticalValuePipe)) : '- - -'
                  }}{{
                  property.data.unit
                  ? ' ' + (property.data.unit | translate)
                  : ''
                  }}
                </ng-template>
              </p>
            </li>
          </ng-container>
        </ul>
        <app-details-card-form [properties]="properties" [edgeConnectivityInfo]="edgeConnectivityInfo"
          (stopPolling)="stopPolling()" (startPolling)="startPolling()"
          (controlValueChange)="setValueForPoint($event)"></app-details-card-form>
      </ng-container>
    </ng-scrollbar>
  </div>
</div>

<ng-template #loadingSpinner>
  <div class="mt-6">
    <si-loading-spinner></si-loading-spinner>
  </div>
</ng-template>
