import { Device } from '../../../../shared/api-client/services/lcas-api-client/models/device.model';
import { Product } from '../../../../shared/api-client/services/lcas-api-client/models/product.model';
import { FloorWithLocations } from '../../../../sidemenu/planning/disciplines-and-functions/models/floor-with-locations.model';

export enum LoadedStatus {
  NOT_LOADED = 'NOT_LOADED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export interface LocationsState {
  floorsAndLocationsLoadedStatus: LoadedStatus;
  buildingProductLoadedStatus: LoadedStatus;
  floorsWithLocations: FloorWithLocations[];
  locationsWithDevices: { [key: string]: Device[] };
  products: Product[];
  isLoading: boolean;
}
