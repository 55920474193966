<div
  *ngIf="locations$ | async; else moveDeviceModalComponentLoading"
  class="si-modal-content-icon-wrapper si-modal-content-icon-spacer"
>
  <ng-container *ngIf="filteredLocations">
    <div class="modal-header">
      <h4 class="modal-title text-break" id="sample-modal-title">
        {{ 'BUILDING.MOVE_DEVICE_MODAL.TITLE' | translate : {locationName: device?.name} }}
      </h4>
      <button
        id="btn-close"
        type="button"
        class="btn btn-circle btn-sm btn-ghost element-cancel"
        aria-label="Close modal"
        [attr.data-testid]="'move-device-modal-close-btn'"
        (click)="close()"
      ></button>
    </div>
    <div class="modal-body">
      <si-filtered-search
        [attr.data-testid]="'filtered-search-input-move-device'"
        [placeholder]="'BUILDING.MOVE_DEVICE_MODAL.SEARCH' | translate"
        [submitText]="'BUILDING.SUBMIT_TEXT' | translate"
        [showIcon]="true"
        [colorVariant]="'base-0'"
        [criteria]="searchCriteria"
        [exclusiveCriteria]="true"
        (doSearch)="filterLocations($event)"
      ></si-filtered-search>

      <div class="list-group list-group-flush overflow-auto max-vh-50 mt-7">
        <button *ngFor="let item of filteredLocations"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-start"
          [class.disabled]="item.location.id === location?.id"
          [attr.data-testid]="'location-' + item.floor.name + '-' + item.location.name"
          (click)="selectedLocation = item"
          >
          <i class="icon element-room"></i>
          <div class="ms-5 me-auto text-break">
            <div class="fw-bold">{{item.location.name}}</div>
            <div class="si-caption">{{item.floor.name}} | {{item.location.name}}</div>
          </div>
        </button>
      </div>
    </div>
    <div class="modal-footer">
      <button
        id="btn-cancel"
        type="button"
        class="btn btn-secondary"
        [attr.data-testid]="'move-device-modal-cancel-btn'"
        (click)="close()"
      >
        {{ 'GLOBALS.BUTTON.CANCEL' | translate }}
      </button>
      <button
        id="btn-move-device"
        type="button"
        class="btn btn-primary"
        [disabled]="!selectedLocation || !location || !device"
        [attr.data-testid]="'move-device-modal-save-btn'"
        (click)="moveDevice()"
      >
        {{ 'BUILDING.MOVE_DEVICE_MODAL.MOVE' | translate }}
      </button>
    </div>
  </ng-container>
</div>
<ng-template #moveDeviceModalComponentLoading>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
