import { Injectable } from '@angular/core';

import { FloorWithLocations } from '../../../sidemenu/planning/disciplines-and-functions/models/floor-with-locations.model';
import { LocationTreeItemDefinition } from '../models/location-tree-item-definition.model';
import { TreeItemWithId } from '../models/tree-item-with-id.model';

@Injectable({ providedIn: 'root' })
export class TreeItemBuilderService {
  buildTreeItems(
    floorsAndLocations: FloorWithLocations[],
    selectedFloorId?: string,
    selectedLocationId?: string,
  ): TreeItemWithId[] {
    return floorsAndLocations.reduce((acc, floor) => {
      const isOpen = floor.id === selectedFloorId;
      const treeItem = this.buildTreeItem({
        item: floor,
        hasChildren: floor.locations.length > 0,
        open: isOpen,
      });
      return [
        ...acc,
        {
          ...treeItem,
          children: floor.locations.length
            ? floor.locations.map((location) =>
                this.buildTreeItem({
                  item: location,
                  open: false,
                  child: !!treeItem,
                  selectedLocationId,
                }),
              )
            : [],
        },
      ];
    }, [] as TreeItemWithId[]);
  }

  buildTreeItem(definition: LocationTreeItemDefinition): TreeItemWithId {
    return {
      id: definition.item.id,
      name: definition.item.name,
      state: definition.child || !definition.hasChildren ? 'leaf' : 'collapsed',
      label: definition.item.name,
      icon: definition.child ? 'element-room' : 'element-floor',
      selected: definition.item.id === definition.selectedLocationId,
      expanded: definition.open,
    };
  }
}
