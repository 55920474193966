import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, of, first, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';

import { UserPermission } from '../models/user-permission.enum';
import { AreaAccess } from '../models/area-access.model';
import { Area } from '../models/area.enum';
import { AuthActions } from '../store/auth.actions';
import { AuthState } from '../models/auth-state.model';
import { PermissionService } from './permission.service';

@Injectable()
export class AreaAccessService {
  private readonly accessMappings: AreaAccess[] = [
    {
      area: Area.ManageProjects,
      allowedPermissions: [UserPermission.LCASAdministrator],
    },
    {
      area: Area.Planning,
      allowedPermissions: [
        UserPermission.LCASAdministrator,
        UserPermission.LCASEngineer,
      ],
    },
    {
      area: Area.Commissioning,
      allowedPermissions: [
        UserPermission.LCASAdministrator,
        UserPermission.LCASEngineer,
      ],
    },
    {
      area: Area.Operation,
      allowedPermissions: [
        UserPermission.LCASAdministrator,
        UserPermission.LCASEngineer,
        UserPermission.LCASOperator,
      ],
    },
  ];

  constructor(
    private store: Store<{ auth: AuthState }>,
    private permissionService: PermissionService,
    private router: Router,
  ) {}

  hasAccessToArea(
    userPermissions: UserPermission[] | undefined,
    area: Area,
  ): boolean {
    const areaMapping = this.accessMappings.find(
      (accessMapping) => accessMapping.area === area,
    );
    if (!areaMapping || !userPermissions || !userPermissions.length) {
      return false;
    }

    return areaMapping.allowedPermissions.some((permission) =>
      Array.isArray(permission)
        ? permission.every((compositePermission) =>
            userPermissions.some(
              (userPermission) => userPermission === compositePermission,
            ),
          )
        : userPermissions.some(
            (userPermission) => userPermission === permission,
          ),
    );
  }

  accessAreaGuard(area: Area | undefined): Observable<boolean | UrlTree> {
    if (!area) {
      return of(true);
    }

    return this.store
      .select((store) => store.auth.selectedCustomerUserPermissions)
      .pipe(
        first(),
        switchMap((userPermissions) => {
          const hasAccessToArea = this.hasAccessToArea(userPermissions, area);
          if (!hasAccessToArea) {
            return this.permissionService
              .getSelectedCustomerUserPermissions()
              .pipe(
                tap((refreshedUserPermissions) => {
                  this.store.dispatch(
                    AuthActions.setSelectedCustomerUserPermissions({
                      selectedCustomerUserPermissions: refreshedUserPermissions,
                    }),
                  );
                }),
                switchMap((refreshedUserPermissions) => {
                  const hasAccessToAreaRefreshed = this.hasAccessToArea(
                    refreshedUserPermissions,
                    area,
                  );
                  return of(
                    !hasAccessToAreaRefreshed
                      ? this.router.createUrlTree(['/active-project-error'])
                      : hasAccessToAreaRefreshed,
                  );
                }),
              );
          }

          return of(hasAccessToArea);
        }),
      );
  }
}
