import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { UrlBuilderService } from '../details-card/services/url-builder.service';
import { AppsUrlModule } from './apps-url.module';

@Injectable({ providedIn: AppsUrlModule })
export class AppsUrlHelperService {
  constructor(private urlBuilder: UrlBuilderService) {}

  getAccountAppUrl(customerId?: string, partitionId?: string): string {
    const url =
      environment.pipelineEnvironment === 'dev'
        ? environment.devEnvAccountManagerAppUrl
        : environment.accountManagerAppUrl;
    return this.urlBuilder.build(url, { customerId, partitionId });
  }

  getAssetsAppUrl(customerId?: string, partitionId?: string): string {
    const url =
      environment.pipelineEnvironment === 'dev'
        ? environment.devEnvAssetsAppUrl
        : environment.assetsAppUrl;
    return this.urlBuilder.build(url, { customerId, partitionId });
  }
}
