import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { LanguageService } from '../../i18n/language.service';
import { AuthState } from '../models/auth-state.model';
import { AuthActions } from '../store/auth.actions';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
})
export class SsoComponent {
  cookieUrl = environment.cookieUrl;

  constructor(
    private store: Store<{ auth: AuthState }>,
    private languageService: LanguageService,
  ) {}

  login(): void {
    this.store.dispatch(
      AuthActions.initLogin({
        shouldLoadOrganizations: true,
        shouldSkipSso: true,
      }),
    );
  }

  getAvailableLanguages(): string[] {
    return this.languageService.getSupportedLanguages();
  }
}
