export const environment = {
  test: false,
  production: true,
  cookieUrl: '//assets.adobedtm.com/5dfc7d97c6fb/9f2b9af520c3/launch-5f57f1252476.min.js',
  apiURL: 'https://prod.lcas.horizoncloud.io/api',
  apiUrlVersion: 'v2',
  userApi: 'https://um.user.horizoncloud.io/api',
  userApiVersion: 'v2',
  subscriptionApi: 'https://subscription.horizoncloud.io/api',
  subscriptionApiVersion: 'v2',
  pointApi: 'https://pv.point.horizoncloud.io/api/v2',
  pointApiVersion: 'v2',
  locationApi: 'https://locations.horizoncloud.io',
  locationApiVersion: 'v2',
  deviceApi: 'https://dm.device.horizoncloud.io/api',
  deviceApiVersion: 'v2',
  edgeApi: 'https://hedge.horizoncloud.io/api',
  edgeApiVersion: 'v1',
  aodsApi:'https://aods.horizoncloud.io/api',
  aodsApiVersion:'v2',
  product: 'lcas-app',
  clientId: 'T25XL1DGWccJJOIHpzKTuHrsOJFxiyY9',
  audience: 'https://horizon.siemens.com',
  issuer: 'https://siemens-bt-015.eu.auth0.com/',
  msIssuer: 'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',
  siemensIdBaseUrl: 'https://login.siemens.com',
  tenant: 'main-tenant-oidc',
  accountManagerAppUrl: 'https://account.bpcloudapps.siemens.com',
  devEnvAccountManagerAppUrl: 'https://account.apps.horizonint.cloud',
  assetsAppUrl: 'https://assets.bpcloudapps.siemens.com',
  devEnvAssetsAppUrl: 'https://assets.apps.horizonint.cloud',
  mediaBaseUrl: '',
  subscriptionManagerAppUrl: 'https://siemens-sandbox.subscription-suite.io',
  lcasAppVersion: 'v1-3-0',
  googleMapsApiKey: 'AIzaSyCUdGQXVSeeCE8-ek8gKyBUckIE3FvOacc',
  scanditApiKey: 'ATqyCoaBEl3lQoVEXCL4ptES8Sc/Row+u1nfli4U8OgQXyUkXl5IwMRobl21NdxP9iwG4+p+nMcFHDpN3V2k1Wd3syskRI+ifSG7pJMbTarlVFk0iVq9EJpN1yz/fwtLMmlgUN1jE5vcVXyyulNnxBJs1IMOA8D1Eix+OtBFdPDESdldQEY0P4h/6rCzd91clyuXElMggzkbBQjQyw87FaqCOjzJC4CTHJx0dkjIHMxgQtoXzxGOhTru73801mfgUBvekNdRbRTvardYeDgr6nwrJLgz4KcutkVZ56+sHaZ1T2SaFegTbuecI5VLKwpLwm2nBIMdpa0LTIZqS9VPOoXeLRGvJjpJHGoN+66xyBM/PzEFCg6DWvNsI/Wn0uY4gb710CBPjjuE77LLkm027hFOU0BN+DPpZFWKD+YMD0LPit7h4wE8w5S3CWCr3xcpeRW7eRXx0vvSWxEOtQ0BQi/xpJreangUgvWe0u0m53R3xYJ6aetMSEtrjolx/Oadq+tYtNOTYw7l16iMbmya30SenTsZoiuqYziu1309C7mdzhAqn5jLUWzkuE4BZFBzgeFb9ymjYWJK8jL8yQ1Rm/QR1E8FHYNdTz69ITRTk8x/rON9YGneOwpyADYx5eM8DeOM12BuVPrlkt/bYvRnX2JZW0Win3sIhHnK8/klHYo1xVHa72DUuKCBM2wlVLWYqJFKwwbUNBz+3p8BvZRcAjxJ9P5Cl0TtHyPv42E+IyYH+dzx2rZ0a0VZjdl6ZHid2zRrwRSnpWPDYgQ24IveVYhhyesdGLlHBBPPbRzF/jZRZvhL8hrOC4sVI2ZDPryg2/Z7NOwFUVRL5IxW92RDLWten7aOcSzjJszHFNDecqHGngQMqaNNKrbYqVmxxRgk',
  isChinaDeployment: '',
  pipelineEnvironment: 'prod',
};
