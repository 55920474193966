import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { PendingRequestsInterceptor } from './interceptors/pending-requests.interceptor';
import { PendingRequestsService } from './services/pending-requests.service';

@NgModule({
  providers: [
    PendingRequestsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PendingRequestsInterceptor,
      multi: true,
    },
  ],
})
export class PendingRequestsModule {}
