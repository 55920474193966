import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { FloorWithLocations } from '../../../sidemenu/planning/disciplines-and-functions/models/floor-with-locations.model';

@Injectable({ providedIn: 'root' })
export class StoreBridgeService {
  private newLocationToNavigateSubject$ = new Subject<{
    floorId: string;
    locationId: string;
  }>();

  newLocationToNavigate$ = this.newLocationToNavigateSubject$.asObservable();

  private deletedLocationIdSubject$ = new Subject<{
    deletedLocationId: string;
    floorsWithLocations: FloorWithLocations[];
  }>();

  deletedLocationId$ = this.deletedLocationIdSubject$.asObservable();

  notifyNewLocationToNavigate(floorId: string, locationId: string): void {
    this.newLocationToNavigateSubject$.next({ floorId, locationId });
  }

  notifyDeletedLocationId(
    deletedLocationId: string,
    floorsWithLocations: FloorWithLocations[],
  ): void {
    this.deletedLocationIdSubject$.next({
      deletedLocationId,
      floorsWithLocations,
    });
  }
}
