import { Injectable } from '@angular/core';
import { differenceInMinutes } from 'date-fns';

interface StoredDetailsPollingList {
  id: string;
  value: any;
  timestamp: number;
}

@Injectable()
export class DetailsCardSessionStorageService {
  private readonly maximumAgeInMinutes = 10;

  private readonly sessionStorageName = 'storedDetailsPollingList';

  getId(id: string): StoredDetailsPollingList | undefined {
    return this.getList().find((i) => i.id === id);
  }

  addId(id: string, value: any): void {
    sessionStorage.setItem(
      this.sessionStorageName,
      JSON.stringify([
        ...this.getList().filter((i) => i.id !== id),
        { id, value, timestamp: Date.now() },
      ]),
    );
  }

  deleteOldIds(): void {
    sessionStorage.setItem(
      this.sessionStorageName,
      JSON.stringify(
        this.getList().filter(
          (x) =>
            differenceInMinutes(new Date(), x.timestamp) <
            this.maximumAgeInMinutes,
        ),
      ),
    );
  }

  private getList(): StoredDetailsPollingList[] {
    return JSON.parse(sessionStorage.getItem(this.sessionStorageName) ?? '[]');
  }
}
