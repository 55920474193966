import { Action, createReducer, on } from '@ngrx/store';

import { AuthState } from '../models/auth-state.model';
import { AuthActions } from './auth.actions';

export const initialState: AuthState = {
  shouldSkipSso: undefined,
  shouldLoadOrganizations: undefined,
  customers: [],
  selectedCustomer: undefined,
  selectedCustomerUserPermissions: undefined,
  selectedCustomerSubscriptions: undefined,
  redirectComponentRoute: ['/'],
  loggedIn: false,
};

const innerAuthReducers = createReducer(
  initialState,
  on(AuthActions.initLogin, (state, { type, ...props }) => ({
    ...state,
    ...props,
  })),
  on(AuthActions.loginInitiliazed, (state, { loggedIn }) => ({
    ...state,
    loggedIn,
  })),
  on(AuthActions.setCustomers, (state, { customers }) => ({
    ...state,
    customers,
  })),
  on(AuthActions.setSelectedCustomer, (state, { selectedCustomer }) => ({
    ...state,
    selectedCustomer,
    selectedCustomerUserPermissions: undefined,
  })),
  on(
    AuthActions.setSelectedCustomerUserPermissions,
    (state, { selectedCustomerUserPermissions }) => ({
      ...state,
      selectedCustomerUserPermissions,
    }),
  ),
  on(
    AuthActions.setSelectedCustomerSubscriptions,
    (state, { selectedCustomerSubscriptions }) => ({
      ...state,
      selectedCustomerSubscriptions,
    }),
  ),
  on(AuthActions.reset, (state) => ({
    ...initialState,
    selectedCustomer: state.selectedCustomer,
    selectedCustomerUserPermissions: undefined,
    selectedCustomerSubscriptions: undefined,
  })),
);

export function authReducers(
  state: AuthState | undefined,
  action: Action,
): AuthState {
  return innerAuthReducers(state, action);
}
