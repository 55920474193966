import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { ApiClientModule } from '../../api-client.module';
import { ApiClientHelperService } from '../api-client-helper.service';
import { Attributes } from './models/attributes.model';
import { Command } from './models/command.model';
import { DeviceData } from './models/device-data.model';
import { PostDeviceType } from './models/post-device-type.model';
import { Feature } from './models/feature.model';

@Injectable({ providedIn: ApiClientModule })
export class DeviceApiClientService {
  constructor(
    private httpClient: HttpClient,
    private apiHelperService: ApiClientHelperService,
  ) {}

  claimDevice(
    activationKey: string,
    devicePayloadType: PostDeviceType,
  ): Observable<{ data: DeviceData }> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.post<{ data: DeviceData }>(
        `${environment.deviceApi}/${environment.deviceApiVersion}/partitions/${store.project?.id}/devices`,
        {
          data: {
            type: devicePayloadType,
            attributes: {
              activationKey,
            },
          },
        },
      ),
    );
  }

  setAttributes({ id, ...attributes }: Attributes): Observable<void> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch<void>(
        `${environment.deviceApi}/${environment.deviceApiVersion}/partitions/${store.project?.id}/devices/${id}`,
        {
          data: {
            type: 'Device',
            id,
            attributes,
          },
        },
      ),
    );
  }

  getDeviceFeatures(id: string): Observable<{ data: Feature[] }> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<{ data: Feature[] }>(
        `${environment.deviceApi}/${environment.deviceApiVersion}/partitions/${store.project?.id}/devices/${id}/features`,
      ),
    );
  }

  setEdgeTimezone(featureId: string): Observable<{ data: Command }> {
    return this.apiHelperService.callAndAddStore((store) => {
      const commandBody = {
        data: {
          type: 'DeviceInfo_SetTimezone',
          attributes: {
            parameters: {
              timezone: store.building?.attributes.timeZone,
            },
          },
        },
      };

      return this.httpClient.post<{ data: Command }>(
        `${environment.deviceApi}/${environment.deviceApiVersion}/partitions/${store.project?.id}/features/${featureId}/commands`,
        commandBody,
      );
    });
  }

  getCommand(commandId: string): Observable<{ data: Command }> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<{ data: Command }>(
        `${environment.deviceApi}/${environment.deviceApiVersion}/partitions/${store.project?.id}/commands/${commandId}`,
      ),
    );
  }
}
