import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { defer, Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';

import { LcasApiClientService } from '../api-client/services/lcas-api-client/lcas-api-client.service';
import { FunctionalBlock } from '../api-client/services/lcas-api-client/models/functional-block.model';
import { SetupFormModel } from '../api-client/services/lcas-api-client/models/setup-form.model';
import { SetupFormOutputService } from '../formly/setup-form/services/setup-form-output.service';
import { SetupFormService } from '../formly/setup-form/services/setup-form.service';
import { SetupFormItem } from '../models/business/setup-form-item.model';

@Injectable()
export class FunctionCommissioningService {
  private latestFunctionalBlock?: FunctionalBlock;

  constructor(
    private setupFormService: SetupFormService,
    private lcasApiClientService: LcasApiClientService,
    private setupFormOutputService: SetupFormOutputService,
    private router: Router,
  ) {}

  displaySetupForm(
    functionForm: SetupFormItem,
  ): Observable<SetupFormModel | undefined> {
    return this.setupFormService.display(functionForm, 'function').pipe(
      first(),
      switchMap((setupFormModel) =>
        setupFormModel ? of(setupFormModel) : of(undefined),
      ),
    );
  }

  updateSetupForm(
    formConfig: SetupFormItem,
    shouldNotReloadScheduler?: boolean,
  ): Observable<FunctionalBlock | undefined> {
    return defer(() =>
      this.displaySetupFormAndCommissionFunction(
        formConfig,
        shouldNotReloadScheduler,
      ).pipe(
        first(),
        switchMap((functionalBlock: FunctionalBlock | undefined) =>
          this.handleUpdatedFunction(functionalBlock, formConfig),
        ),
        catchError(() => {
          throw new Error('Setup form could not be updated!');
        }),
      ),
    );
  }

  isCommissioned(functionalBlock: FunctionalBlock): boolean {
    return ['COMMISSIONED', 'COMMISSIONED_READY_FOR_UPDATE'].some(
      (x) => x === functionalBlock.setupStatus,
    );
  }

  private displaySetupFormAndCommissionFunction(
    functionForm: SetupFormItem,
    shouldNotReloadScheduler?: boolean,
  ): Observable<FunctionalBlock | undefined> {
    return this.setupFormService
      .display(functionForm, 'function', shouldNotReloadScheduler)
      .pipe(
        first(),
        switchMap((setupFormModel) => {
          if (setupFormModel) {
            return this.saveSetupForm(
              functionForm.id,
              setupFormModel,
              functionForm.eTag,
            );
          }
          return of(undefined);
        }),
      );
  }

  private saveSetupForm(
    functionId: string,
    setupFormModel: SetupFormModel,
    eTag: number,
  ): Observable<FunctionalBlock> {
    return this.lcasApiClientService
      .patchFunctionForm(functionId, {
        form: setupFormModel,
        eTag,
      })
      .pipe(
        switchMap((updatedFunction) =>
          this.isCommissioned(updatedFunction)
            ? this.lcasApiClientService.commissionFunction(
                updatedFunction.id,
                updatedFunction.eTag,
              )
            : of(updatedFunction),
        ),
        first(),
      );
  }

  private handleUpdatedFunction(
    functionalBlock: FunctionalBlock | undefined,
    formConfig: SetupFormItem,
  ): Observable<FunctionalBlock | undefined> {
    if (functionalBlock) {
      if (formConfig.config?.model.days) {
        const updatedFormConfig = {
          ...formConfig,
          eTag: functionalBlock.eTag,
        };
        this.latestFunctionalBlock = functionalBlock;
        this.setupFormOutputService.isSchedulerLoading$.next(false);

        return this.router.url.includes('setup-form')
          ? this.updateSetupForm(updatedFormConfig, true)
          : of(functionalBlock);
      }
      return of(functionalBlock);
    }

    return of(this.latestFunctionalBlock);
  }
}
