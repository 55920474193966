export const statusMapping: {
  [k: string]: { icon: string; text: string; colorClass?: string };
} = {
  connected: {
    icon: 'element-connection-established status-success',
    text: 'GLOBALS.STATUS.CONNECTED',
    colorClass: 'text-success',
  },
  'not-connected': {
    icon: 'element-connection-lost status-danger',
    text: 'GLOBALS.STATUS.NOT_CONNECTED',
    colorClass: 'text-danger',
  },
  error: {
    icon: 'element-connection-lost status-danger',
    text: 'GLOBALS.STATUS.ERROR',
    colorClass: 'text-danger',
  },
  pending: {
    icon: 'element-busy',
    text: 'GLOBALS.STATUS.PENDING',
  },
  'connection-lost': {
    icon: 'element-connection-lost',
    text: 'GLOBALS.STATUS.CONNECTION_LOST',
  },
  'battery-empty': {
    icon: 'element-battery-empty status-danger',
    colorClass: 'text-danger',
    text: 'GLOBALS.STATUS.BATTERY_EMPTY',
  },
  frost: {
    icon: 'element-cooling-state status-information',
    colorClass: 'text-information',
    text: 'GLOBALS.STATUS.FROST',
  },
  'battery-low': {
    icon: 'element-battery-low status-warning',
    colorClass: 'text-warning',
    text: 'GLOBALS.STATUS.BATTERY_LOW',
  },
  'actuator-fault': {
    icon: 'element-alarm status-danger',
    colorClass: 'text-danger',
    text: 'GLOBALS.STATUS.ACTUATOR_FAULT',
  },
  'authentication-failure': {
    icon: 'element-out-of-service status-warning',
    colorClass: 'text-warning',
    text: 'GLOBALS.STATUS.AUTHENTICATION_FAILURE',
  },
};
