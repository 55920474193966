import { createAction, props } from '@ngrx/store';

import { FunctionalBlock } from '../../../../shared/api-client/services/lcas-api-client/models/functional-block.model';
import { Location } from '../../../../shared/api-client/services/lcas-api-client/models/location.model';
import { UpdateFunctionalBlock } from '../../../../shared/api-client/services/lcas-api-client/models/update-functional-block.model';
import { FunctionDelete } from '../functions/functions-table/models/function-delete.model';
import { FunctionDeploy } from '../functions/functions-table/models/function-deploy.model';

const tag = 'Functions Commissioning';

export const FunctionsCommissioningActions = {
  initLoadFunctionsAndLocations: createAction(
    `[${tag}] Init Load Functions And Locations`,
  ),
  loadFunctionsAndLocationsSuccess: createAction(
    `[${tag}] Load Functions And Locations Success`,
    props<{ functionalBlocks: FunctionalBlock[]; locations: Location[] }>(),
  ),
  loadFunctionsAndLocationsError: createAction(
    `[${tag}] Load Functions And Locations Error`,
  ),
  initPatchFunction: createAction(
    `[${tag}] Init Patch Function`,
    props<{ functionalBlock: UpdateFunctionalBlock }>(),
  ),
  patchFunctionSuccess: createAction(
    `[${tag}] Patch Function Success`,
    props<{ functionalBlock: FunctionalBlock }>(),
  ),
  patchFunctionError: createAction(`[${tag}] Patch Function Error`),
  initCommissionFunction: createAction(
    `[${tag}] Init Commission Function`,
    props<{ functionDeploy: FunctionDeploy }>(),
  ),
  commissionFunctionSuccess: createAction(
    `[${tag}] Commission Function Success`,
    props<{ updatedFunction: FunctionalBlock }>(),
  ),
  commissionFunctionError: createAction(`[${tag}] Commission Function Error`),
  initDeleteFunction: createAction(
    `[${tag}] Init Delete Function`,
    props<{ functionalBlock?: FunctionDelete; force?: boolean }>(),
  ),
  deleteFunctionSuccess: createAction(
    `[${tag}] Delete Function Success`,
    props<{ functionalBlockId: string }>(),
  ),
  forceDeleteFunctionCancel: createAction(
    `[${tag}] Force Delete Function Cancel`,
  ),
  deleteFunctionError: createAction(`[${tag}] Delete Function Error`),
  simpleDeleteFunctionError: createAction(
    `[${tag}] Delete Function with simple parameters failed`,
  ),
  setSelectedDiscipline: createAction(
    `[${tag}] Set Selected Discipline`,
    props<{ discipline: string }>(),
  ),
  clearFunctionsCommissioningState: createAction(
    `[${tag}] Clear Functions And Locations`,
  ),
};
