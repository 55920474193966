import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from '@building-x/common-ui-ng';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, throwError } from 'rxjs';
import { first, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { UserApiClientService } from '../../api-client/services/user-api-client/user-api-client.service';
import { AuthState } from '../models/auth-state.model';
import { AuthService } from '../services/auth.service';
import { PermissionService } from '../services/permission.service';
import { AuthActions } from './auth.actions';
import { SubscriptionApiClientService } from '../../api-client/services/subscription-api-client/subscription-api-client.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private store: Store<{ auth: AuthState }>,
    private userApiClientService: UserApiClientService,
    private permissionService: PermissionService,
    private subscriptionApiClientService: SubscriptionApiClientService,
  ) {}

  initiateLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.initLogin),
      mergeMap(() => this.authService.login()),
      map(() => AuthActions.loginInitiliazed({ loggedIn: false })),
    ),
  );

  initiateOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadCustomers),
      mergeMap(({ redirectComponentRoute }) =>
        this.userApiClientService
          .getCustomers()
          .pipe(
            map((customers: Customer[]) =>
              AuthActions.setCustomers({ customers, redirectComponentRoute }),
            ),
          ),
      ),
    ),
  );

  setDefaultCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setCustomers),
      mergeMap(({ customers }) =>
        this.store.pipe(
          map((store) => ({
            selectedCustomer: store.auth.selectedCustomer,
            customers,
          })),
          first(),
        ),
      ),
      switchMap(({ selectedCustomer, customers }) => {
        if (
          selectedCustomer &&
          this.isValidCustomer(selectedCustomer, customers)
        ) {
          return of(AuthActions.setSelectedCustomer({ selectedCustomer }));
        }
        if (customers?.length > 0) {
          return of(
            AuthActions.setSelectedCustomer({ selectedCustomer: customers[0] }),
          );
        }
        return throwError(() => new Error('Customers must be set'));
      }),
    ),
  );

  navigateRedirectComponent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.setCustomers),
        tap(({ redirectComponentRoute }) =>
          this.router.navigate(redirectComponentRoute),
        ),
      ),
    { dispatch: false },
  );

  loadSelectedCustomerUserPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.loadSelectedCustomerUserPermissions,
        AuthActions.setSelectedCustomer,
      ),
      mergeMap(() =>
        this.permissionService.getSelectedCustomerUserPermissions(),
      ),
      switchMap((userPermissions) =>
        of(
          AuthActions.setSelectedCustomerUserPermissions({
            selectedCustomerUserPermissions: userPermissions,
          }),
        ),
      ),
    ),
  );

  loadSelectedCustomerSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.loadSelectedCustomerSubscriptions,
        AuthActions.setSelectedCustomer,
      ),
      mergeMap(() =>
        this.subscriptionApiClientService.getCustomerSubscriptions(),
      ),
      switchMap((subscriptions) =>
        of(
          AuthActions.setSelectedCustomerSubscriptions({
            selectedCustomerSubscriptions: subscriptions,
          }),
        ),
      ),
    ),
  );

  logOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logOut),
      tap(() => this.authService.logOut()),
      map(() => AuthActions.reset()),
    ),
  );

  private isValidCustomer(customer: Customer, customers: Customer[]): boolean {
    return customers.some((customerItem) => customerItem.id === customer.id);
  }
}
