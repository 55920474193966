import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, expand, Observable, reduce } from 'rxjs';

import { ApiClientModule } from '../../api-client.module';

type PaginatedApi<T> = { data: T[]; links: { next?: string } };

@Injectable({ providedIn: ApiClientModule })
export class UserPaginationService {
  constructor(private httpClient: HttpClient) {}

  getAllPaginatedItems<T>(apiUrl: string, pageSize = 100): Observable<T[]> {
    return this.getItems<T>(apiUrl, pageSize).pipe(
      expand((data) =>
        data.links?.next
          ? this.getItems<T>(apiUrl, pageSize, data.links.next)
          : EMPTY,
      ),
      reduce((acc, value) => [...acc, ...value.data], [] as T[]),
    );
  }

  private getItems<T>(
    apiUrl: string,
    pageSize: number,
    next?: string,
  ): Observable<PaginatedApi<T>> {
    const pageNext = next ? `&page[next]=${next}` : '';

    return this.httpClient.get<PaginatedApi<T>>(
      `${apiUrl}?page[size]=${pageSize}${pageNext}`,
    );
  }
}
