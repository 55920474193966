import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { map, Observable, of, switchMap, take, tap } from 'rxjs';

import { ActiveProjectState } from '../active-project/models/active-project-state.model';
import { LcasApiClientService } from '../api-client/services/lcas-api-client/lcas-api-client.service';
import { Device } from '../api-client/services/lcas-api-client/models/device.model';
import { DevicesTableRow } from '../devices-commissioning/devices-table/models/devices-table-row.model';
import { DevicesRelocationModalComponent } from '../modal-dialogs/devices-relocation-modal/devices-relocation-modal.component';

@Injectable()
export class BorderRouterDeletionService {
  constructor(
    private store: Store<{
      project: ActiveProjectState;
    }>,
    private bsModalService: BsModalService,
    private lcasApiClientService: LcasApiClientService,
  ) {}

  isBorderRouterAndCommissioned(device: Device | DevicesTableRow): boolean {
    return device.type === 'BrdRout' && device.setupStatus === 'COMMISSIONED';
  }

  canDeleteBorderRouter(device: Device | DevicesTableRow): Observable<boolean> {
    return this.lcasApiClientService.hasSubordinateDevices(device.id).pipe(
      map((hasSubordinateDevices) => !hasSubordinateDevices),
      switchMap((canDelete) =>
        canDelete ? of(true) : this.openDevicesRelocationModal(device),
      ),
    );
  }

  private openDevicesRelocationModal(
    device: Device | DevicesTableRow,
  ): Observable<boolean> {
    return this.store
      .select((store) => store.project.activeProject?.id)
      .pipe(
        take(1),
        tap((projectId) =>
          this.bsModalService.show(DevicesRelocationModalComponent, {
            class: 'modal-dialog-centered',
            initialState: {
              entityToDelete: 'borderRouter',
              linkToCommissioning: this.getLinkToCommissioning(
                device,
                projectId || '',
              ),
              isCommissioningView: !!(device as DevicesTableRow).measurement,
            },
            keyboard: false,
          }),
        ),
        map(() => false),
      );
  }

  private getLinkToCommissioning(
    device: Device | DevicesTableRow,
    projectId: string,
  ): string {
    return `/sidemenu/${projectId}/commissioning/scan-and-assign/${
      (device as Device).location?.floor.id
    }/${(device as Device).location?.id}`;
  }
}
