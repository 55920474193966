<form *ngIf="form" [formGroup]="form">
  <div class="si-modal-content-icon-wrapper si-modal-content-icon-spacer">
    <div class="modal-header">
      <h4 class="modal-title" id="sample-modal-title">
        {{ 'BUILDING.EDIT_LOCATION_MODAL.TITLE' | translate }}
      </h4>
      <button
        type="button"
        class="btn btn-circle btn-sm btn-ghost element-cancel"
        aria-label="Close modal"
        data-testid="edit-sort-modal-close-btn"
        (click)="modalRef?.hide()"
      ></button>
    </div>
    <div class="modal-body">
      <ul class="list-group">
        <li class="list-group-item border-0">
          <div class="d-flex flex-column">
            <input
              type="text"
              class="form-control"
              [attr.data-testid]="'location-rename-input'"
              [formControlName]="location!.id"
            />
            <div
              *ngIf="form.get(location!.id)?.errors?.required"
              class="invalid-feedback"
            >
              {{ 'FORM.INPUT_VALIDATION.REQUIRED' | translate }}
            </div>
            <div
              *ngIf="form.get(location!.id)?.errors?.minlength"
              class="invalid-feedback"
            >
              {{
                'FORM.INPUT_VALIDATION.MINLENGTH_NAME_ERROR'
                  | translate : { requiredLength: minimumLengthForName }
              }}
            </div>
            <div
              *ngIf="form.get(location!.id)?.errors?.maxlength"
              class="invalid-feedback"
            >
              {{
                'FORM.INPUT_VALIDATION.MAXLENGTH_NAME_ERROR'
                  | translate : { requiredLength: maximumLengthForName }
              }}
            </div>
            <ng-container [ngSwitch]="type">
              <span *ngSwitchCase="locationType.Room" class="mt-2 text-break">
                {{ asLocation(location)?.floor?.name }} |
                {{ asLocation(location)?.name }}
              </span>
              <span *ngSwitchDefault class="mt-2 text-break">{{ location?.name }}</span>
            </ng-container>
          </div>
        </li>
      </ul>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-testid="edit-sort-modal-cancel-btn"
        (click)="close()"
      >
        {{ 'BUILDING.ADD_DEVICES_MODAL.CANCEL' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        data-testid="edit-sort-modal-save-btn"
        [disabled]="form.invalid"
        (click)="save.emit(form.get(location!.id)?.value)"
      >
        {{ 'BUILDING.ADD_DEVICES_MODAL.SAVE' | translate }}
      </button>
    </div>
  </div>
</form>
