<si-landing-page
  data-cy="login-landing-page"
  backgroundImageUrl="./assets/images/login-background.webp"
  subheading="{{ 'LANDING_PAGE.SUBHEADING' | translate }}"
  heading="{{ 'LANDING_PAGE.HEADING' | translate }}"
  subtitle=""
  copyright="@ Siemens 2021-2022"
  [links]="[
    {
      title: 'Corporate Information',
      href: 'http://www.siemens.com/corporate-information'
    },
    { title: 'Privacy Notice', href: 'http://www.siemens.com/privacy-notice' },
    {
      title: 'Cookie Notice',
      href: 'https://new.siemens.com/global/en/general/cookie-notice.html'
    },
    {
      title: 'Acceptable Use Policy',
      href: 'https://www.sw.siemens.com/en-US/sw-terms/aup/'
    }
  ]"
  [availableLanguages]="getAvailableLanguages()"
>
  <button
    type="button"
    class="btn btn-primary btn-block btn-lg w-100"
    [attr.data-testid]="'log-in-btn'"
    (click)="login()"
  >
    {{ 'LANDING_PAGE.LOG_IN_SIGN_OUT' | translate }}
  </button>

  <div class="mt-9">
    <span class="si-title-2 text-tertiary">Note:</span>
    <h4 class="si-body-2 text-tertiary">
      {{ 'LANDING_PAGE.WARNING_NOTE' | translate }}
    </h4>
  </div>

  <si-cookie-banner
    [cookieUrl]="cookieUrl"
  ></si-cookie-banner>
</si-landing-page>
