import { Injectable } from '@angular/core';
import { SiToastNotificationService } from '@simpl/element-ng';
import { EdgeConnectivityInfo } from './models/edge-connectivity-info.model';

@Injectable({
  providedIn: 'root',
})
export class EdgeConnectivityModalService {
  shouldDisplayEdgeDisconnectedModal = true;

  constructor(private siToastNotificationService: SiToastNotificationService) {}

  public checkEdgeConnection(
    edgeConnectivityInfo: EdgeConnectivityInfo | undefined,
  ): void {
    if (this.isEdgeDeviceDisconnected(edgeConnectivityInfo)) {
      if (this.shouldDisplayEdgeDisconnectedModal) {
        this.shouldDisplayEdgeDisconnectedModal = false;
        if (!this.isEdgeDisconnectedToastCurrentlyDisplayed()) {
          this.siToastNotificationService.queueToastNotification(
            'info',
            'EDGE_DISCONNECTED_TOAST.TITLE',
            'EDGE_DISCONNECTED_TOAST.MESSAGE',
          );
        }
      }
    } else {
      this.shouldDisplayEdgeDisconnectedModal = true;
    }
  }

  private isEdgeDisconnectedToastCurrentlyDisplayed(): boolean {
    return !!this.siToastNotificationService.activeToasts.find(
      (toast) => toast.title === 'EDGE_DISCONNECTED_TOAST.TITLE',
    );
  }

  private isEdgeDeviceDisconnected(
    edgeConnectivityInfo: EdgeConnectivityInfo | undefined,
  ): boolean {
    return !!(
      edgeConnectivityInfo &&
      edgeConnectivityInfo.connected === false &&
      edgeConnectivityInfo.fetchedConnectivity === true
    );
  }
}
