<ng-container *ngIf="viewData$ | async as viewData; else loadingSpinner">
  <si-main-detail-container
    *ngIf="
      viewData.floorsWithLocationsLoadedStatus === 'LOADED';
      else loadingSpinner
    "
    [heading]="(projectName$ | async) || ''"
    [detailsHeading]="viewData.selectedLocation?.name || ''"
    [truncateHeading]="true"
    [resizableParts]="true"
    [mainContainerWidth]="30"
    [minMainSize]="368"
    [(detailsActive)]="detailsActive"
  >
    <ng-container
      slot="masterActions"
      *ngTemplateOutlet="masterActionsTemplate; context: { viewData: viewData }"
    ></ng-container>
    <ng-container
      slot="masterData"
      *ngTemplateOutlet="masterDataTemplate; context: { viewData: viewData }"
    ></ng-container>
    <ng-container
      slot="detailActions"
      *ngTemplateOutlet="detailActionsTemplate; context: { viewData: viewData }"
    ></ng-container>
    <ng-container
      slot="details"
      *ngTemplateOutlet="
        devicesDetailsTemplate;
        context: { viewData: viewData }
      "
    ></ng-container>
  </si-main-detail-container>
</ng-container>

<ng-template #loadingSpinner>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
