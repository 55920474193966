<div class="modal-header">
  <p
    class="si-title-1 mt-9 mb-0"
    id="modal-title"
    *ngIf="entityToDelete === 'floor'"
  >
    {{ 'BUILDING.RELOCATE_DEVICES_FLOOR.TITLE' | translate }}
  </p>
  <p
    class="si-title-1 mt-9 mb-0"
    id="modal-title"
    *ngIf="entityToDelete === 'location'"
  >
    {{ 'BUILDING.RELOCATE_DEVICES_ROOM.TITLE' | translate }}
  </p>
  <p
    class="si-title-1 mt-9 mb-0"
    id="modal-title"
    *ngIf="entityToDelete === 'borderRouter'"
  >
    {{ 'BUILDING.RELOCATE_ACTUATORS.TITLE' | translate }}
  </p>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    [attr.data-testid]="'close-btn'"
    (click)="modalRef?.hide()"
  ></button>
</div>
<div class="modal-body">
  <p class="si-body-1 mt-4 mb-0">
    <span
      *ngIf="entityToDelete === 'floor'"
      [innerHtml]="
        'BUILDING.RELOCATE_DEVICES_FLOOR.MESSAGE_WITH_LINK'
          | translate
            : { link: '<a href=' + linkToCommissioning + '><b>here</b></a>' }
      "
    ></span>
    <span
      *ngIf="entityToDelete === 'location'"
      [innerHtml]="
        'BUILDING.RELOCATE_DEVICES_ROOM.MESSAGE_WITH_LINK'
          | translate
            : { link: '<a href=' + linkToCommissioning + '><b>here</b></a>' }
      "
    ></span>
    <span
      *ngIf="entityToDelete === 'borderRouter'"
      [innerHtml]="
        'BUILDING.RELOCATE_ACTUATORS.MESSAGE_WITH_LINK'
          | translate
            : { link: '<a href=' + linkToCommissioning + '><b>here</b></a>' }
      "
    ></span>
  </p>
</div>
