import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MainDetailContainerService {
  private detailsActiveSubject$ = new Subject<boolean>();

  detailsActive$ = this.detailsActiveSubject$.asObservable();

  notifyDetailsActive(detailsActive: boolean): void {
    this.detailsActiveSubject$.next(detailsActive);
  }
}
