import { AbstractControl } from '@angular/forms';
import { FieldValidatorFn } from '@ngx-formly/core/lib/models/config';

export const integerValidator: FieldValidatorFn = (
  control: AbstractControl,
) => {
  return Number.isInteger(control.value)
    ? null
    : {
        int: true,
      };
};

export const booleanValidator: FieldValidatorFn = (
  control: AbstractControl,
) => {
  return typeof control.value === 'boolean'
    ? null
    : {
        boolean: true,
      };
};

export const floatingPointValidator: FieldValidatorFn = (
  control: AbstractControl,
) => {
  const floatingPointRegex = /^[+-]?(\d+([.]\d*)?|[.]\d+)$/;
  return floatingPointRegex.test(control.value)
    ? null
    : { floatingPoint: true };
};
