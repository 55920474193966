import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BackdropComponent } from './backdrop/backdrop.component';
import { BackdropService } from './services/backdrop.service';

@NgModule({
  imports: [CommonModule],
  declarations: [BackdropComponent],
  providers: [BackdropService],
  exports: [BackdropComponent],
})
export class BackdropModule {}
