<si-empty-state
  icon="element-floor"
  heading="{{ 'BUILDING.EMPTY_BUILDING.NO_FLOOR' | translate }}"
  content="{{ 'BUILDING.EMPTY_BUILDING.DESCRIPTION' | translate }}"
>
  <p>
    <button
      type="button"
      class="btn btn-primary"
      [attr.data-testid]="'add-floor-empty-building-btn'"
      (click)="addFloor.next()"
    >
      <i class="icon element-plus"></i>
      {{ 'BUILDING.ADD_FLOOR' | translate }}
    </button>
  </p>
</si-empty-state>
