<app-code-scanner
  *ngIf="cameraActive; else formActive"
  (sendCode)="afterScan($event)"
></app-code-scanner>

<ng-template #formActive>
  <div class="d-flex mb-8">
    <div>
      <legend *ngIf="props.label" class="col row px-0 py-5 h5 fw-bold">
        {{ props.label }}
      </legend>
      <span class="col row px-0">{{
        'COMMISSIONING.SETUP_FORM.SCAN.INTRODUCTION' | translate
      }}</span>
    </div>
    <div class="icon-container">
      <button
        type="button"
        class="btn btn-circle btn-lg btn-primary element-scan-qr-code me-6"
        aria-label="scan"
        [attr.data-testid]="'scan-btn'"
        (click)="cameraActive = true"
      ></button>
    </div>
  </div>

  <div class="form-group form-horizontal row mx-6 d-flex mb-6">
    <formly-field
      *ngFor="let fieldItem of field.fieldGroup"
      [field]="fieldItem"
      class="row px-4 mb-6"
    ></formly-field>
  </div>
</ng-template>
