import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { flatten } from 'lodash';

import { LcasApiClientService } from '../../api-client/services/lcas-api-client/lcas-api-client.service';
import { ActiveProjectActions } from './active-project.actions';

@Injectable()
export class ActiveProjectEffects {
  constructor(
    private actions$: Actions,
    private lcasApiClientService: LcasApiClientService,
  ) {}

  loadDevicesCatalog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveProjectActions.initLoadDevicesCatalog),
      switchMap(() =>
        this.lcasApiClientService.getDeviceCatalog().pipe(
          map((devicesCatalog) =>
            ActiveProjectActions.loadDevicesCatalogSuccess({
              latestDeviceProducts: devicesCatalog,
            }),
          ),
        ),
      ),
    ),
  );

  loadFunctionsCatalog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveProjectActions.initLoadFunctionsCatalog),
      switchMap(() =>
        this.lcasApiClientService.getDisciplinesAndFunctionsCatalog().pipe(
          map((disciplinesAndFunctions) =>
            ActiveProjectActions.loadFunctionsCatalogSuccess({
              latestFunctionsProducts: flatten(
                disciplinesAndFunctions.map((group) => group.items),
              ),
            }),
          ),
        ),
      ),
    ),
  );
}
