import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, switchMap } from 'rxjs/operators';

import { AuthState } from '../models/auth-state.model';
import { AuthService } from '../services/auth.service';
import { AuthActions } from '../store/auth.actions';

@Component({
  selector: 'app-auth-redirect',
  template: '',
})
export class AuthRedirectComponent implements OnInit {
  constructor(
    private store: Store<{ auth: AuthState }>,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.authService
      .collectClaims()
      .pipe(
        switchMap(() => this.store.select((store) => store.auth)),
        first(),
      )
      .subscribe(({ redirectComponentRoute, shouldLoadOrganizations }) => {
        if (shouldLoadOrganizations) {
          this.store.dispatch(
            AuthActions.loadCustomers({ redirectComponentRoute }),
          );
        } else {
          this.store.dispatch(
            AuthActions.setCustomers({
              customers: [],
              redirectComponentRoute,
            }),
          );
        }
      });
  }
}
