<select
  #selectElement
  class="form-select"
  [attr.data-testid]="'details-card-enum-value-select'"
  [ngModel]="value"
  (ngModelChange)="onChange!($event)"
  (mousedown)="controlClicked()"
  (focusout)="optionsClosed.emit()"
  [disabled]="
    isDisabled ||
    !!(
      !edgeConnectivityInfo?.connected &&
      edgeConnectivityInfo?.fetchedConnectivity
    )
  "
>
  <option
    *ngFor="let option of options"
    [ngValue]="option.value"
    [attr.data-testid]="'details-card-enum-value-option' + option.displayValue"
  >
    {{ option.displayValue | translate }}
  </option>
</select>
