import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalRef } from '@simpl/element-ng';

import {
  maximumLengthForName,
  minimumLengthForName,
} from '../../../shared/devices-setup-form/constants/form-input.constants';

@Component({
  selector: 'app-create-floor-or-location-modal',
  templateUrl: './create-floor-or-location-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateFloorOrLocationModalComponent implements OnInit {
  @Input() modalRef: ModalRef | undefined;

  @Input() title: string | undefined;

  @Input() defaultName: string | undefined;

  @Output() save: EventEmitter<string> = new EventEmitter();

  form?: FormGroup;

  errorCodeTranslateKeyMap = new Map<string, string>([
    ['required', 'FORM.INPUT_VALIDATION.REQUIRED'],
    ['minlength', 'FORM.INPUT_VALIDATION.MINLENGTH_NAME_ERROR'],
    ['maxlength', 'FORM.INPUT_VALIDATION.MAXLENGTH_NAME_ERROR'],
  ]);

  controlNameTranslateKeyMap = new Map<string, string>([
    ['name', 'FORM.CONTROL.NAME'],
  ]);

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.getForm();
  }

  private getForm(): FormGroup {
    return this.formBuilder.group({
      name: [
        this.defaultName,
        [
          Validators.required,
          Validators.minLength(minimumLengthForName),
          Validators.maxLength(maximumLengthForName),
        ],
      ],
    });
  }
}
