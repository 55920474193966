import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanToNumber',
  pure: true,
})
export class BooleanToNumberPipe implements PipeTransform {
  transform(value: string | number | undefined): string | number | undefined {
    if (!['true', 'false'].includes(value as string)) {
      return value;
    }
    return value === 'true' ? 1 : 0;
  }
}
