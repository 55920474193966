import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { PendingRequestsService } from '../services/pending-requests.service';

@Injectable()
export class PendingRequestsInterceptor implements HttpInterceptor {
  private readonly apiUrl = environment.apiURL;

  private readonly endpoints = [
    'products',
    'devices',
    'floors',
    'locations',
    'functions',
    'affects',
    'projects',
    'commissioning',
    'forms',
  ];

  private readonly httpMethods = ['DELETE', 'POST', 'PATCH', 'GET'];

  constructor(private pendingRequestsService: PendingRequestsService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      req.url.startsWith(this.apiUrl) &&
      this.endpoints.some((endpoint) => req.url.includes(endpoint)) &&
      this.httpMethods.includes(req.method)
    ) {
      this.pendingRequestsService.addPendingHttpRequest();
      return next
        .handle(req)
        .pipe(
          finalize(() =>
            this.pendingRequestsService.removePendingHttpRequest(),
          ),
        );
    }

    return next.handle(req);
  }
}
