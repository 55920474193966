import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

import { AuthService } from '../services/auth.service';

export const authCanMatchGuard: CanMatchFn = (_route, segments): boolean => {
  return inject(AuthService).isAuthenticated(
    segments.map((segment) => segment.path),
  );
};
