import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PendingRequestsService {
  private readonly pendingHttpRequests$: BehaviorSubject<number> =
    new BehaviorSubject(0);

  existPendingHttpRequests$ = this.pendingHttpRequests$.pipe(
    map((numberOfPendingHttpRequests) => numberOfPendingHttpRequests > 0),
  );

  addPendingHttpRequest(): void {
    this.pendingHttpRequests$.next(this.pendingHttpRequests$.value + 1);
  }

  removePendingHttpRequest(): void {
    this.pendingHttpRequests$.next(this.pendingHttpRequests$.value - 1);
  }
}
