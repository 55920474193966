import { Component, Input } from '@angular/core';
import { ModalRef } from '@simpl/element-ng';

import { ParentEntity } from '../../../../../shared/models/business/parent-entity.enum';

@Component({
  selector: 'app-devices-relocation-modal',
  templateUrl: './devices-relocation-modal.component.html',
})
export class DevicesRelocationModalComponent {
  @Input() entityToDelete: ParentEntity | undefined;

  @Input() linkToCommissioning: string | undefined;

  @Input() modalRef: ModalRef<unknown> | undefined;
}
