import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AreaAccessService } from '../../services/area-access.service';

export const areaAccessCanActivateChildGuard: CanActivateChildFn = (
  childRoute: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> => {
  return inject(AreaAccessService).accessAreaGuard(childRoute?.data?.area);
};
