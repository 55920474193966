import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { Partition } from '@building-x/common-ui-ng';

import { SubscriptionApiClientService } from '../api-client/services/subscription-api-client/subscription-api-client.service';
import { UpdatePartitionRequestBody } from '../api-client/services/subscription-api-client/models/update-partition-request-body.model';

@Injectable()
export class UpdatePartitionService {
  private readonly retryTimer = 2.5 * 1000;

  private readonly retryNumber = 3;

  constructor(
    private subscriptionApiClientService: SubscriptionApiClientService,
  ) {}

  updatePartition(
    companyId: string,
    updatedPartition: UpdatePartitionRequestBody,
  ): Observable<Partition> {
    return this.subscriptionApiClientService
      .patchPartition(companyId, updatedPartition)
      .pipe(retry({ count: this.retryNumber, delay: this.retryTimer }));
  }
}
