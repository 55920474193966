import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyEnum',
})
export class PropertyEnumPipe implements PipeTransform {
  transform(
    value: string | number | undefined,
    catalogData: {
      'btzf:hasEnumMap': {
        'btzf:hasMapMember': Record<string, { ref: string }>;
      };
    },
  ): string {
    return (value === 0 || value) &&
      catalogData['btzf:hasEnumMap']['btzf:hasMapMember'][value]
      ? catalogData['btzf:hasEnumMap']['btzf:hasMapMember'][value].ref
      : '- - -';
  }
}
