import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Symbology } from 'scandit-web-datacapture-barcode';

@Component({
  selector: 'app-code-scanner',
  templateUrl: './code-scanner.component.html',
})
export class CodeScannerComponent implements OnInit, OnDestroy {
  @Output() sendCode: EventEmitter<string | undefined> = new EventEmitter();

  readonly allowedFormats = [
    Symbology.EAN13UPCA,
    Symbology.EAN8,
    Symbology.UPCE,
    Symbology.QR,
    Symbology.DataMatrix,
    Symbology.InterleavedTwoOfFive,
  ];

  readonly scanningTimeout = 30000;

  readonly debounceTimeDelay = 300;

  showLoadingSpinner = true;

  codeForm = new FormGroup({
    code: new FormControl(''),
  });

  subscription = new Subscription();

  private scanTimeout: ReturnType<typeof setTimeout> | undefined;

  ngOnInit(): void {
    this.subscription.add(
      this.codeForm.valueChanges
        .pipe(debounceTime(this.debounceTimeDelay))
        .subscribe((value) => {
          this.sendCode.emit(value.code!);
        }),
    );
    this.scanTimeout = setTimeout(
      () => this.sendCode.emit(undefined),
      this.scanningTimeout,
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    clearTimeout(this.scanTimeout);
  }
}
