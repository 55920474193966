import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BreadcrumbItem } from '@simpl/element-ng';
import { flatten } from 'lodash';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ActiveProjectState } from '../active-project/models/active-project-state.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  @Input() startPage = '/sidemenu/planning/projects';

  breadcrumbs?: BreadcrumbItem[];

  private subscriptions: Subscription[] = [];

  private projectId$ = this.projectStore.select(
    (store) => store.project?.activeProject?.id,
  );

  constructor(
    private router: Router,
    private projectStore: Store<{ project: ActiveProjectState }>,
  ) {}

  ngOnInit(): void {
    this.setBreadcrumbs();

    this.subscriptions.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => this.setBreadcrumbs()),
    );

    this.subscriptions.push(
      this.projectId$.pipe(filter((id) => !!id)).subscribe((id) => {
        this.startPage = `/sidemenu/${id}/dashboard`;
        this.setBreadcrumbs();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private setBreadcrumbs(): void {
    this.breadcrumbs = this.concatStartPage(
      this.getBreadcrumbs(this.router.routerState.root),
    );
  }

  private concatStartPage(items: BreadcrumbItem[]): BreadcrumbItem[] {
    return [{ title: '', link: this.startPage } as BreadcrumbItem].concat(
      items,
    );
  }

  private getBreadcrumbs(
    route: ActivatedRoute,
    items: BreadcrumbItem[] = [],
  ): BreadcrumbItem[] {
    const { breadcrumb, dynamicData } = route.snapshot.data;
    const nextChild = route.firstChild;

    const currentBreadcrumbs = this.concatBreadcrumb(
      breadcrumb,
      this.concatBreadcrumb(dynamicData?.breadcrumb, items, undefined),
      route.component ? this.getLink(route) : undefined,
    );

    return nextChild
      ? this.getBreadcrumbs(nextChild, currentBreadcrumbs)
      : currentBreadcrumbs;
  }

  private concatBreadcrumb(
    breadcrumb: string | undefined,
    items: BreadcrumbItem[],
    link: string | undefined,
  ): BreadcrumbItem[] {
    return breadcrumb
      ? items.concat({
          title: breadcrumb,
          link,
        })
      : items;
  }

  private getLink(route: ActivatedRoute): string {
    return `/${flatten(
      route.pathFromRoot.map((data) => data.snapshot.url),
    ).join('/')}`;
  }
}
