import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SiSharedModule } from '@building-x/common-ui-ng';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SiLoadingSpinnerModule } from '@simpl/element-ng';
import { SchedulerApi, SimplSchedulerNgModule } from '@simpl/scheduler-ng';

import { BreadcrumbModule } from '../breadcrumb/breadcrumb.module';
import { CodeScannerModule } from '../code-scanner/code-scanner.module';
import { dataTestIdExtension } from './extensions/data-test.extension';
import { ObjectFormTypeComponent } from './form-types/object-form-type/object-form-type.component';
import { ScanFormTypeComponent } from './form-types/scan-form-type/scan-form-type.component';
import { ScanFormPatternService } from './form-types/scan-form-type/services/scan-form-pattern.service';
import { SchedulerFormTypeComponent } from './form-types/scheduler-form-type/scheduler-form-type.component';
import { PrototypeValueService } from './form-types/scheduler-form-type/services/prototype-value/prototype-value.service';
import { SchedulerApiService } from './form-types/scheduler-form-type/services/scheduler-api/scheduler-api.service';
import { FormlyFieldBooleanWrapperComponent } from './formly-field-boolean-wrapper/formly-field-boolean-wrapper.component';
import { FormlyFieldWrapperComponent } from './formly-field-wrapper/formly-field-wrapper.component';
import { FormlySchemaConverterService } from './formly-schema-converter.service';
import { TypedSetupFormHelperFactory } from './setup-form/factories/typed-setup-form-helper.factory';
import { SetupFormOutputService } from './setup-form/services/setup-form-output.service';
import { SetupFormService } from './setup-form/services/setup-form.service';
import { SetupFormComponent } from './setup-form/setup-form.component';
import { SharedFormlyRoutingModule } from './shared-formly-routing.module';
import { registerValidation } from './validation/register-validation';

@NgModule({
  imports: [
    SimplSchedulerNgModule.forRoot({
      schedulerApi: {
        provide: SchedulerApi,
        useClass: SchedulerApiService,
      },
    }),
    CommonModule,
    FormlyModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    SiLoadingSpinnerModule,
    SiSharedModule,
    CodeScannerModule,
    FormlyModule.forRoot({
      wrappers: [
        { name: 'wrapper', component: FormlyFieldWrapperComponent },
        {
          name: 'boolean-wrapper',
          component: FormlyFieldBooleanWrapperComponent,
        },
      ],
      types: [
        {
          name: 'string',
          extends: 'input',
          wrappers: ['wrapper'],
        },
        {
          name: 'number',
          extends: 'input',
          wrappers: ['wrapper'],
          defaultOptions: {
            props: {
              type: 'number',
            },
          },
        },
        {
          name: 'boolean',
          extends: 'checkbox',
          wrappers: ['boolean-wrapper'],
        },
        {
          name: 'enum',
          extends: 'select',
          wrappers: ['wrapper'],
        },
        {
          name: 'array',
          extends: 'input',
        },
        { name: 'object', component: ObjectFormTypeComponent },
        { name: 'scan', component: ScanFormTypeComponent },
        { name: 'scheduler', component: SchedulerFormTypeComponent },
      ],
      extensions: [
        {
          name: 'data-testid-extension',
          extension: dataTestIdExtension,
        },
      ],
    }),
    FormlyBootstrapModule,
    BreadcrumbModule,
    SharedFormlyRoutingModule,
  ],
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerValidation,
      deps: [TranslateService],
    },
    FormlySchemaConverterService,
    ScanFormPatternService,
    SchedulerApiService,
    PrototypeValueService,
    SetupFormService,
    SetupFormOutputService,
    TypedSetupFormHelperFactory,
  ],
  declarations: [
    ObjectFormTypeComponent,
    FormlyFieldWrapperComponent,
    SetupFormComponent,
    ScanFormTypeComponent,
    FormlyFieldBooleanWrapperComponent,
    SchedulerFormTypeComponent,
  ],
})
export class SharedFormlyModule {}
