<div class="row align-items-center position-relative">
  <div *ngIf="showLoadingSpinner" class="position-absolute">
    <si-loading-spinner></si-loading-spinner>
  </div>
  <app-scandit-wrapper
    [formats]="allowedFormats"
    (scan)="sendCode.emit($event)"
  ></app-scandit-wrapper>
</div>
<form [formGroup]="codeForm" class="d-none">
  <input formControlName="code" type="text" [attr.data-testid]="'code-reading-input'">
</form>
