import { Injectable } from '@angular/core';
import {
  Observable,
  catchError,
  concatWith,
  first,
  forkJoin,
  of,
  tap,
  throwError,
} from 'rxjs';

import { Project } from '../../../shared/api-client/services/lcas-api-client/models/project.model';
import { LocationUpdateType } from '../../../shared/api-client/services/location-api-client/models/location-update-type.enum';
import { AddressUpdate } from '../../../shared/api-client/services/location-api-client/models/update-address.model';
import { BuildingUpdate } from '../../../shared/api-client/services/location-api-client/models/update-building.model';
import { SubscriptionUpdateType } from '../../../shared/api-client/services/subscription-api-client/models/subscription-update-type.enum';
import { UpdatePartitionRequestBody } from '../../../shared/api-client/services/subscription-api-client/models/update-partition-request-body.model';
import { Address } from '../../../shared/models/business/address.model';
import { UpdateLocationVerticalService } from '../../../shared/services/update-location-vertical.service';
import { UpdatePartitionService } from '../../../shared/services/update-partition.service';
import { ProjectFormValue } from '../create-new-project/models/project-form-value.model';

@Injectable()
export class EditProjectService {
  constructor(
    private updatePartitionService: UpdatePartitionService,
    private updateLocationVerticalService: UpdateLocationVerticalService,
  ) {}

  updateProject(
    projectFormValue: ProjectFormValue,
    originalProject: Project,
  ): Observable<unknown> {
    const addressId = originalProject.buildingData.address.id!;
    const buildingId = originalProject.buildingData.id;
    const partitionId = originalProject.id;
    const { customerId } = originalProject;
    const newProjectName =
      projectFormValue.name !== originalProject.name
        ? projectFormValue.name
        : undefined;
    const rollbackActions: Observable<unknown>[] = [];

    const updatePartition = (): Observable<unknown> => {
      if (!newProjectName) {
        return of(null);
      }
      const updatePartitionRequestBody = this.buildUpdatePartitionRequestBody(
        partitionId,
        newProjectName,
      );
      return this.updatePartitionService
        .updatePartition(customerId, updatePartitionRequestBody)
        .pipe(
          tap(() => {
            rollbackActions.push(
              this.updatePartitionService.updatePartition(
                customerId,
                this.buildUpdatePartitionRequestBody(
                  partitionId,
                  originalProject.name,
                ),
              ),
            );
          }),
          first(),
        );
    };

    const updateBuilding = (): Observable<unknown> => {
      const newTimeZone =
        projectFormValue.timeZone !== originalProject.buildingData.timeZone
          ? projectFormValue.timeZone
          : undefined;
      if (!newProjectName && !newTimeZone) {
        return of(null);
      }
      const buildingUpdateRequestBody =
        this.buildLocationVerticalBuildingUpdate(
          buildingId,
          newProjectName,
          newTimeZone,
        );
      return this.updateLocationVerticalService
        .updateBuilding(buildingId, buildingUpdateRequestBody)
        .pipe(first());
    };

    const updateAddress = (): Observable<unknown> => {
      const updates = this.getAddressUpdatedFields(
        originalProject.buildingData.address,
        projectFormValue.address,
      );
      if (Object.keys(updates).length === 0) {
        return of(null);
      }
      const addressUpdateRequestBody = this.buildLocationVerticalAddressUpdate(
        updates,
        addressId,
      );
      return this.updateLocationVerticalService
        .updateAddress(addressId, addressUpdateRequestBody)
        .pipe(
          tap(() => {
            rollbackActions.push(
              this.updateLocationVerticalService.updateAddress(
                addressId,
                this.buildLocationVerticalAddressUpdate(
                  this.getAddressUpdatedFields(
                    projectFormValue.address,
                    originalProject.buildingData.address,
                  ),
                  addressId,
                ),
              ),
            );
          }),
          first(),
        );
    };

    return updatePartition().pipe(
      concatWith(updateAddress(), updateBuilding()),
      catchError((error) => {
        this.rollbackProjectUpdate(rollbackActions);
        return throwError(
          () => new Error(`Updates failed with error: ${error}`),
        );
      }),
    );
  }

  rollbackProjectUpdate(rollbackActions: Observable<unknown>[]): void {
    forkJoin(rollbackActions).subscribe();
  }

  private getAddressUpdatedFields(
    original: Address,
    modified: Address,
  ): Partial<Address> {
    const changes: Partial<Address> = {};
    Object.keys(original).forEach((key) => {
      if (
        original[key as keyof Address] !== modified[key as keyof Address] &&
        key in modified
      ) {
        changes[key as keyof Address] = modified[key as keyof Address];
      }
    });

    return changes;
  }

  private buildLocationVerticalAddressUpdate(
    updateAddress: Partial<Address>,
    addressId: string,
  ): AddressUpdate {
    return {
      id: addressId,
      type: LocationUpdateType.ADDRESS,
      attributes: {
        street: updateAddress.street,
        locality: updateAddress.locality,
        region: updateAddress.region,
        countryCode: updateAddress.countryCode,
        postalCode: updateAddress.postalCode,
      },
    };
  }

  private buildUpdatePartitionRequestBody(
    partitionId: string,
    newProjectName: string,
  ): UpdatePartitionRequestBody {
    return {
      id: partitionId,
      type: SubscriptionUpdateType.PARTITION,
      attributes: {
        name: newProjectName,
      },
    };
  }

  private buildLocationVerticalBuildingUpdate(
    buildingId: string,
    newProjectName: string | undefined,
    newTimeZone: string | undefined,
  ): BuildingUpdate {
    return {
      id: buildingId,
      type: LocationUpdateType.BUILDING,
      attributes: {
        label: newProjectName,
        timeZone: newTimeZone,
      },
    };
  }
}
