import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { SetupFormComponent } from '../setup-form.component';
import { SetupFormOutputService } from './setup-form-output.service';
import { SetupFormModel } from '../../../../../shared/api-client/services/lcas-api-client/models/setup-form.model';
import { SetupFormItem } from '../../../../../shared/models/business/setup-form-item.model';

@Injectable()
export class SetupFormService {
  constructor(
    private modalService: BsModalService,
    private outputService: SetupFormOutputService,
  ) {}

  display(config: SetupFormItem): Observable<SetupFormModel | undefined> {
    this.modalService.show(SetupFormComponent, {
      class: 'modal-dialog-centered',
      ariaLabelledBy: 'electric-meters-setup-modal',
      initialState: {
        formItem: config,
        output: this.outputService.routedSetupFormOutput,
        isModal: true,
      },
    });
    return this.outputService.routedSetupFormOutput;
  }
}
