import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { JSONSchema7 } from 'json-schema';
import { set } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
  SetupFormSchema,
  SetupFormSchemaProperty,
} from '../../../../../shared/api-client/services/lcas-api-client/models/setup-form.model';

@Injectable({ providedIn: 'root' })
export class FormlySchemaConverterService {
  constructor(private formlyJsonSchema: FormlyJsonschema) {}

  getFieldConfig(
    schemas: SetupFormSchema[],
    definitions?: Record<string, any>,
  ): Observable<FormlyFieldConfig[]> {
    return forkJoin(
      schemas.map((schema) =>
        forkJoin([
          this.getSchema(schema),
          this.getPropertiesSchema(schema.properties),
        ]).pipe(
          map(([translatedSchema, properties]) => ({
            ...translatedSchema,
            properties,
          })),
        ),
      ),
    ).pipe(
      map((translatedSchemas) =>
        translatedSchemas.map((schema) =>
          this.formlyJsonSchema.toFieldConfig({
            ...schema,
            definitions,
          } as JSONSchema7),
        ),
      ),
    );
  }

  private getSchema(schema: SetupFormSchema): Observable<SetupFormSchema> {
    return of({
      ...schema,
      title: schema.defaultTitle ?? schema.title,
    });
  }

  private getPropertiesSchema(properties: {
    [key: string]: SetupFormSchemaProperty;
  }): Observable<{ [key: string]: SetupFormSchemaProperty }> {
    return forkJoin(
      Object.keys(properties).map((propertyName) =>
        this.getProperty(properties[propertyName]).pipe(
          map((property) => ({
            property,
            propertyName,
          })),
        ),
      ),
    ).pipe(
      map((propertyArray) =>
        propertyArray.reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            [currentValue.propertyName]: currentValue.property,
          }),
          {},
        ),
      ),
    );
  }

  private getProperty(
    schemaProperty: SetupFormSchemaProperty,
  ): Observable<SetupFormSchemaProperty> {
    return of({
      ...schemaProperty,
      title: schemaProperty.defaultTitle ?? schemaProperty.title,
    }).pipe(
      mergeMap((property) =>
        (property.properties
          ? this.getPropertiesSchema(property.properties)
          : of({})
        ).pipe(
          map((childProperty) =>
            Object.keys(childProperty).length > 0
              ? set(property, 'properties', childProperty)
              : property,
          ),
        ),
      ),
    );
  }
}
