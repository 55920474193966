import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { LcasApiClientService } from '../../../api-client/services/lcas-api-client/lcas-api-client.service';
import { FunctionalBlock } from '../../../api-client/services/lcas-api-client/models/functional-block.model';
import { TypedSetupFromHelperService } from '../interfaces/typed-setup-form-helper-service.interface';

@Injectable()
export class SetupFormFunctionHelperService
  implements TypedSetupFromHelperService
{
  constructor(
    private router: Router,
    private apiClientService: LcasApiClientService,
  ) {}

  navigateOnClose(relativeTo: ActivatedRoute): Promise<boolean> {
    return this.router.navigate(
      ['../../../commissioning', 'disciplines-and-functions'],
      { relativeTo },
    );
  }

  getSetupFormItem(itemId: string): Observable<FunctionalBlock> {
    return this.apiClientService.getFunction(itemId);
  }
}
