import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authRoutes } from './shared/auth/auth.routes';
import { authCanMatchGuard } from './shared/auth/guards/auth-can-match.guard';
import { authCanActivateChildGuard } from './shared/auth/guards/auth-can-activate-child.guard';
import { areaAccessCanMatchGuard } from './shared/auth/guards/area-access/area-access-can-match.guard';
import { areaAccessCanActivateChildGuard } from './shared/auth/guards/area-access/area-access-can-activate-child.guard';

const routes: Routes = [
  ...authRoutes,
  {
    path: 'sidemenu',
    loadChildren: () =>
      import('./sidemenu/sidemenu.module').then((m) => m.SidemenuModule),
    canActivateChild: [
      authCanActivateChildGuard,
      areaAccessCanActivateChildGuard,
    ],
    canMatch: [authCanMatchGuard, areaAccessCanMatchGuard],
  },
  {
    path: 'camera',
    loadChildren: () =>
      import('./fullscreen/camera/camera.module').then((m) => m.CameraModule),
  },
  {
    path: 'active-project-error',
    loadChildren: () =>
      import(
        './shared/active-project/active-project-error/active-project-error.module'
      ).then((m) => m.ActiveProjectErrorModule),
  },
  { path: '', redirectTo: '/sidemenu/planning/projects', pathMatch: 'full' },
  { path: '**', redirectTo: '/sidemenu/planning/projects' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
