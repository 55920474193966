import { AnySchedule, ScheduleValueTypes } from '@simpl/element-value-types';
import { SchedulerConfig } from '@simpl/scheduler-ng';

export class SchedulerConstants {
  static readonly prototypeValue: ScheduleValueTypes = {
    type: 'number',
    value: 0,
    resolution: 0.1,
    min: -20,
    max: 65,
    unit: '°C',
    decimalsAllowed: true,
    typeMin: -20,
    typeMax: 65,
  };

  static readonly schedulerModel: { [key: string]: AnySchedule } = {
    main: {
      type: 'composition',
      kind: 'schedule',
      value: {
        presentValue: 21.5,
        defaultValue: 18,
        days: {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
      },
      prototypeValue: this.prototypeValue,
    },
  };

  static readonly schedulerConfig: SchedulerConfig = {
    dateFormat: 'dd.mm.yyyy',
    timeFormat: 'hh:mm:ss',
    timeWithHundredths: false,
    exceptionView: false,
    scheduleOptionsView: true,
    compositeView: true,
    relinquishDefault: false,
    timeResolutionSteps: 1,
    continuousSchedule: false,
    exceptionWeekdays: false,
    disableExceptionPeriodType: {
      date: false,
      range: false,
      weeknday: false,
      calendar: false,
    },
    exceptionPriorities: false,
    effectivePeriod: false,
  };
}
