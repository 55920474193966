import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ApiClientModule } from '../api-client/api-client.module';
import { SharedModule } from '../shared.module';
import { activeProjectReducers } from './store/active-project.reducers';
import { ActiveProjectService } from './services/active-project.service';
import { ActiveProjectEffects } from './store/active-project.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('project', activeProjectReducers),
    EffectsModule.forFeature([ActiveProjectEffects]),
    ApiClientModule,
    SharedModule,
  ],
  providers: [ActiveProjectService],
})
export class ActiveProjectModule {}
