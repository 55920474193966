import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ParentEntity } from '../../models/business/parent-entity.enum';

@Component({
  selector: 'app-devices-relocation-modal',
  templateUrl: './devices-relocation-modal.component.html',
})
export class DevicesRelocationModalComponent implements OnInit {
  @Input() entityToDelete: ParentEntity | undefined;

  @Input() linkToCommissioning: string | undefined;

  @Input() isCommissioningView: boolean | undefined;

  constructor(
    private bsModalRef: BsModalRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.isCommissioningView) {
      this.linkToCommissioning = this.router.url;
    }
  }

  onClickCloseModal(): void {
    this.bsModalRef?.hide();
  }
}
