import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { expand, MenuItem, TreeItem } from '@simpl/element-ng';
import { isNumber } from 'lodash';
import { first, Observable, tap } from 'rxjs';

import { PlannedDevice } from '../../../../shared/api-client/services/lcas-api-client/models/planned-device.model';
import { ParentEntity } from '../../../../shared/models/business/parent-entity.enum';
import { LocationsState } from '../../location-store/models/location.mode';
import { LocationType } from '../../models/location-type.enum';
import { TreeItemWithId } from '../../models/tree-item-with-id.model';
import { DeleteTreeItemService } from '../../services/delete-tree-item.service';
import { LocationNavigationService } from '../../services/location-navigation.service';

@Component({
  selector: 'app-locations-tree-view',
  templateUrl: './locations-tree-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationsTreeViewComponent implements OnInit, OnChanges {
  private entityToDelete: ParentEntity | undefined = undefined;

  @Input() treeItems?: TreeItemWithId[];

  @Input() showActionButtons?: boolean;

  @Output() openEdgeRelocationModal: EventEmitter<ParentEntity> =
    new EventEmitter();

  @Output() openDevicesRelocationModal: EventEmitter<{
    floorId: string;
    roomId: string | undefined;
    entityToDelete: ParentEntity;
  }> = new EventEmitter();

  @Output() locationType: EventEmitter<LocationType> =
    new EventEmitter<LocationType>();

  @Output() selectFloor: EventEmitter<string> = new EventEmitter<string>();

  @Output() editLocation: EventEmitter<TreeItemWithId> =
    new EventEmitter<TreeItemWithId>();

  @Output() closeDetails: EventEmitter<void> = new EventEmitter<void>();

  isLoading$: Observable<boolean> = this.store.select(
    (store) => store.floorsAndLocations.isLoading || false,
  );

  menuItems!: MenuItem[];

  constructor(
    private deleteTreeItemService: DeleteTreeItemService,
    private locationNavigationService: LocationNavigationService,
    private store: Store<{
      floorsAndLocations: LocationsState;
    }>,
  ) {}

  ngOnInit(): void {
    this.menuItems = this.showActionButtons
      ? [
          {
            title: 'GLOBALS.BUTTON.EDIT',
            action: (treeItem: TreeItemWithId) =>
              this.editLocation.emit(treeItem),
            icon: 'element-edit',
          },
          {
            title: 'GLOBALS.BUTTON.DELETE',
            action: (treeItem: TreeItem) => {
              this.deleteTreeItemService
                .handleDelete(treeItem)
                .pipe(
                  first(),
                  tap((devices) => {
                    this.openModal(treeItem, devices);
                  }),
                )
                .subscribe();
            },
            icon: 'element-delete',
          },
        ]
      : [];
  }

  ngOnChanges(): void {
    expand(this.treeItems?.find((item) => item.expanded) ?? {});
  }

  onTreeItemClicked(treeItem: TreeItem): void {
    this.locationType.emit(this.resolveLocationType(treeItem.level));
    if (isNumber(treeItem.parent?.level) && treeItem.parent.level > -1) {
      this.locationNavigationService.initNavigation(
        (treeItem.parent as TreeItemWithId).id,
        (treeItem as TreeItemWithId).id,
        true,
      );
      this.selectFloor.emit((treeItem.parent as TreeItemWithId).id);
      this.closeDetails.next();
    } else if (
      isNumber(treeItem.parent?.level) &&
      treeItem.parent.level === -1
    ) {
      this.selectFloor.emit((treeItem as TreeItemWithId).id);
    }
  }

  private openModal(treeItem: TreeItem, devices: PlannedDevice[]) {
    const floorIdTodelete = this.checkTreeItemLevel(treeItem);

    if (devices.length > 0) {
      const edge = devices.find((device) => device.type === 'SysXController');
      if (edge) {
        this.openEdgeRelocationModal.emit(this.entityToDelete);
      } else {
        const commissionedDevice = devices.find(
          (device) => device.setupStatus === 'COMMISSIONED',
        );

        this.openDevicesRelocationModal.emit({
          floorId: floorIdTodelete,
          roomId:
            this.entityToDelete === 'floor'
              ? commissionedDevice?.location?.id
              : (treeItem as TreeItemWithId).id,
          entityToDelete: this.entityToDelete!,
        });
      }
    }
  }

  private checkTreeItemLevel(treeItem: TreeItem): string {
    if (treeItem.level === 0) {
      this.entityToDelete = 'floor';
      return (treeItem as TreeItemWithId).id;
    }
    this.entityToDelete = 'location';
    return (treeItem.parent as TreeItemWithId).id;
  }

  private resolveLocationType(level?: number): LocationType {
    switch (level) {
      case 0:
        return LocationType.Floor;
      case 1:
        return LocationType.Room;
      default:
        return LocationType.Floor;
    }
  }
}
