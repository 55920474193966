import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  share,
  timer,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class DetailPropertiesPollingService {
  private pollingSubject = new BehaviorSubject(true);

  pollingOperator(intervalDuration: number): Observable<number> {
    return timer(0, intervalDuration).pipe(
      withLatestFrom(this.pollingSubject),
      // eslint-disable-next-line no-unused-vars
      filter(([_, shouldContinuePolling]) => shouldContinuePolling),
      map(([timerValue]) => timerValue),
      share(),
    );
  }

  resume() {
    this.pollingSubject.next(true);
  }

  pause() {
    this.pollingSubject.next(false);
  }
}
