import { Component, EventEmitter, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalRef, SiToastNotificationService } from '@simpl/element-ng';
import { defer, first, map, Observable, tap } from 'rxjs';

import { FunctionsTableRow } from '../../../sidemenu/commissioning/disciplines-and-functions/functions/functions-table/models/functions-table-row.model';
import { ActiveProjectState } from '../../active-project/models/active-project-state.model';
import { LcasApiClientService } from '../../api-client/services/lcas-api-client/lcas-api-client.service';
import { SetupFormModel } from '../../api-client/services/lcas-api-client/models/setup-form.model';
import { ThingUpdateResponse } from '../../api-client/services/lcas-api-client/models/thing-update-response.model';
import { AppsUrlHelperService } from '../../apps-url/apps-url-helper.service';
import { AuthState } from '../../auth/models/auth-state.model';
import { DeviceCommissioningService } from '../../device-commissioning/device-commissioning.service';
import { DevicesTableRow } from '../../devices-commissioning/devices-table/models/devices-table-row.model';
import { FunctionCommissioningService } from '../../function-commissioning/function-commissioning.service';
import { SetupFormItem } from '../../models/business/setup-form-item.model';

@Component({
  selector: 'app-thing-instance-update-modal',
  templateUrl: './thing-instance-update-modal.component.html',
})
export class ThingInstanceUpdateModalComponent {
  @Input() device?: DevicesTableRow;

  @Input() functionalBlock?: FunctionsTableRow;

  @Input() reloadTable?: EventEmitter<void>;

  linkToAssetsManager$: Observable<string> = this.store
    .select((store) => ({
      selectedCustomer: store.auth.selectedCustomer,
      selectedPartition: store.project.activeProject,
    }))
    .pipe(
      map(({ selectedCustomer, selectedPartition }) =>
        this.appsUrlHelperService.getAssetsAppUrl(
          selectedCustomer?.id,
          selectedPartition?.id,
        ),
      ),
    );

  private newSetupForm?: SetupFormModel;

  constructor(
    public modalRef: ModalRef,
    private appsUrlHelperService: AppsUrlHelperService,
    private store: Store<{
      project: ActiveProjectState;
      auth: AuthState;
    }>,
    private lcasApiClientService: LcasApiClientService,
    private siToastNotificationService: SiToastNotificationService,
    private deviceCommissioningService: DeviceCommissioningService,
    private functionCommissioningService: FunctionCommissioningService,
  ) {}

  onClickConfirm(): void {
    defer(() =>
      this.device
        ? this.lcasApiClientService.updateDeviceThing(
            this.device.id,
            { eTag: this.device.eTag },
            this.newSetupForm!,
          )
        : this.lcasApiClientService.updateFunctionThing(
            this.functionalBlock!.id,
            this.functionalBlock!.eTag,
            this.newSetupForm!,
          ),
    )
      .pipe(first())
      .subscribe({
        next: (thingUpdateResponse: ThingUpdateResponse) => {
          this.handleSuccessUpdate(thingUpdateResponse);
        },
        error: () => {
          this.displayFailedUpdateNotification();
        },
      });
    this.modalRef.hide();
  }

  private handleSuccessUpdate(thingUpdateResponse: ThingUpdateResponse): void {
    if (thingUpdateResponse.status === 'NEEDS_USER_INPUT') {
      this.displayIncompleteFormNotification();

      if (this.device) {
        this.updateDeviceForm({
          id: this.device.id,
          config: thingUpdateResponse.setupForm,
          eTag: this.device.eTag,
        });
      } else if (this.functionalBlock) {
        this.updateFunctionForm({
          id: this.functionalBlock.id,
          config: thingUpdateResponse.setupForm,
          eTag: this.functionalBlock.eTag,
        });
      }
    } else {
      this.displaySuccessUpdateNotification();
      this.reloadTable?.next();
    }
  }

  private updateDeviceForm(setupFormItem: SetupFormItem): void {
    this.deviceCommissioningService
      .displaySetupForm(setupFormItem)
      .pipe(
        first(),
        tap((updatedSetupForm) => {
          if (updatedSetupForm) {
            this.newSetupForm = updatedSetupForm;
            this.onClickConfirm();
          }
        }),
      )
      .subscribe();
  }

  private updateFunctionForm(setupFormItem: SetupFormItem): void {
    this.functionCommissioningService
      .displaySetupForm(setupFormItem)
      .pipe(
        first(),
        tap((updatedSetupForm) => {
          if (updatedSetupForm) {
            this.newSetupForm = updatedSetupForm;
            this.onClickConfirm();
          }
        }),
      )
      .subscribe();
  }

  private displaySuccessUpdateNotification(): void {
    this.siToastNotificationService.queueToastNotification(
      'success',
      'THING_INSTANCE_UPDATE_TOAST.TITLE',
      'THING_INSTANCE_UPDATE_TOAST.SUCCESS_MESSAGE',
    );
  }

  private displayFailedUpdateNotification(): void {
    this.siToastNotificationService.queueToastNotification(
      'danger',
      'THING_INSTANCE_UPDATE_TOAST.TITLE',
      'THING_INSTANCE_UPDATE_TOAST.FAIL_MESSAGE',
    );
  }

  private displayIncompleteFormNotification(): void {
    this.siToastNotificationService.queueToastNotification(
      'danger',
      'THING_INSTANCE_UPDATE_TOAST.TITLE',
      'THING_INSTANCE_UPDATE_TOAST.INCOMPLETE_FORM_MESSAGE',
    );
  }
}
