import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { DetailCardProperty } from '../models/detail-card-property.model';

function validateStringLength(
  control: AbstractControl,
  property: DetailCardProperty,
): ValidationErrors | null {
  const { maxLength, minLength } = property.data;

  if (control.value.length > maxLength || control.value.length < minLength) {
    control.setErrors({ exceededStringLength: true });
    return { exceededStringLength: true };
  }

  if (control.hasError('exceededStringLength')) {
    control.setErrors({ exceededStringLength: null });
    control.updateValueAndValidity();
  }

  return null;
}

function validateMultipleOf(
  control: AbstractControl,
  property: DetailCardProperty,
): ValidationErrors | null {
  const lengthOfDigits = Math.max(
    control.value.toString().split('.')[0]?.length,
    property.data.multipleOf.toString().split('.')[0]?.length,
  );

  const lengthOfDecimals = Math.max(
    control.value.toString().split('.')[1]?.length ?? 0,
    property.data.multipleOf.toString().split('.')[1]?.length ?? 0,
  );

  const division = Number(
    (control.value / property.data.multipleOf).toPrecision(
      lengthOfDigits + lengthOfDecimals,
    ),
  );

  if (division !== Math.floor(division)) {
    control.setErrors({ wrongAmountOfDecimals: true });
    return { wrongAmountOfDecimals: true };
  }

  if (control.hasError('wrongAmountOfDecimals')) {
    control.setErrors({ wrongAmountOfDecimals: null });
    control.updateValueAndValidity();
  }

  return null;
}

export function checkInput(property: DetailCardProperty): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value === null) {
      return null;
    }

    if (property.data.type === 'string' && control.value !== undefined) {
      return validateStringLength(control, property);
    }
    if (
      property.data.multipleOf !== undefined &&
      control.value !== undefined &&
      control.value !== 0
    ) {
      return validateMultipleOf(control, property);
    }

    return null;
  };
}
