import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { PlannedDevice } from '../../api-client/services/lcas-api-client/models/planned-device.model';
import { Uuid, UuidService } from '../../providers/uuid.provider';
import { EdgeDeviceState } from '../models/edge-device-state.model';

@Injectable()
export class DevicePropertiesService {
  constructor(
    @Inject(UuidService) private uuidService: Uuid,
    private store: Store<{ edgeDeviceState: EdgeDeviceState }>,
  ) {}

  calculateMainPropertyId(device: PlannedDevice): Observable<PlannedDevice> {
    return this.store
      .select((state) => state.edgeDeviceState.edgeDevice)
      .pipe(
        first(),
        map((edgeDevice) => {
          if (
            device.instance?.properties &&
            device.instance?.properties[device.instance?.mainPropertyName] &&
            edgeDevice?.instance?.edgeConfig?.appId
          ) {
            return edgeDevice.instance?.edgeConfig.appId;
          }
          return undefined;
        }),
        map((appId) => {
          return appId
            ? ({
                ...device,
                instance: {
                  ...device.instance,
                  properties: Object.keys(
                    device.instance?.properties ?? {},
                  ).reduce(
                    (attrs, key) => ({
                      ...attrs,
                      [key]: {
                        ...device.instance?.properties[key],
                        computedId: this.uuidService.v5(
                          // eslint-disable-next-line no-underscore-dangle
                          device.instance!.properties[key]._id,
                          appId,
                        ),
                      },
                    }),
                    {},
                  ),
                  mainPropertyId: this.uuidService.v5(
                    // eslint-disable-next-line no-underscore-dangle
                    device.instance!.properties[
                      device.instance!.mainPropertyName
                    ]._id,
                    appId,
                  ),
                },
              } as PlannedDevice)
            : device;
        }),
      );
  }
}
