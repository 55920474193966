import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { sortBy } from 'lodash';
import { combineLatest, filter, map, Observable, switchMap, tap } from 'rxjs';

import { ActiveProjectState } from '../../../shared/active-project/models/active-project-state.model';
import { ProductCatalogArticle } from '../../../shared/api-client/services/lcas-api-client/models/catalog-product.model';
import { Product } from '../../../shared/api-client/services/lcas-api-client/models/product.model';
import { TreeItemBuilderService } from './tree-item-builder.service';
import {
  selectFloorsWithLocations,
  selectFloorsAndLocationsLoadedStatus,
} from '../location-store/store/location.reducers';
import { LocationNavigationService } from './location-navigation.service';
import { TreeItemWithId } from '../../../shared/tree-view/models/tree-item-with-id.model';
import { Location } from '../../../shared/api-client/services/lcas-api-client/models/location.model';
import {
  LoadedStatus,
  LocationsState,
} from '../location-store/models/location.mode';

@Injectable({ providedIn: 'root' })
export class ViewDataService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private treeItemBuilderService: TreeItemBuilderService,
    private locationNavigationService: LocationNavigationService,
    private store: Store<{
      project: ActiveProjectState;
      floorsAndLocations: LocationsState;
    }>,
  ) {}

  getSelectedLocation(): Observable<Location | undefined> {
    return this.activatedRoute.paramMap
      .pipe(
        map((paramMap) => ({
          selectedLocationId: paramMap.get('selectedLocationId') as string,
          selectedFloorId: paramMap.get('selectedFloorId') as string,
        })),
      )
      .pipe(
        switchMap(({ selectedLocationId, selectedFloorId }) =>
          combineLatest([
            this.store.select(selectFloorsWithLocations),
            this.store.select(selectFloorsAndLocationsLoadedStatus),
          ]).pipe(
            filter(([, loadedStatus]) => loadedStatus === LoadedStatus.LOADED),
            tap(([floorsWithLocations]) => {
              this.locationNavigationService.handleNavigation(
                selectedFloorId,
                selectedLocationId,
                floorsWithLocations,
              );
            }),
            map(([floorsAndLocations]) => {
              return floorsAndLocations
                .flatMap((floor) => floor.locations)
                .find((location) => location.id === selectedLocationId);
            }),
          ),
        ),
      );
  }

  getLocationTree(): Observable<TreeItemWithId[]> {
    return combineLatest([
      this.getSelectedLocation(),
      this.store.select(selectFloorsWithLocations),
    ]).pipe(
      map(([selectedLocation, floorsWithLocations]) => {
        return this.treeItemBuilderService.buildTreeItems(
          floorsWithLocations,
          selectedLocation?.floor.id,
          selectedLocation?.id,
        );
      }),
    );
  }

  getAddDevicesViewData(): Observable<ProductCatalogArticle[]> {
    return combineLatest([
      this.store.select((store) => store.project.latestDevicesCatalog),
      this.store.select((store) => store.floorsAndLocations.products),
      this.store.select(
        (store) => store.floorsAndLocations.buildingProductLoadedStatus,
      ),
      this.store.select((store) => store.project.loadedStatusDeviceCatalog),
    ]).pipe(
      filter(
        ([, , loadedStatusProducts, loadedStatusDeviceCatalog]) =>
          loadedStatusProducts === LoadedStatus.LOADED &&
          loadedStatusDeviceCatalog === LoadedStatus.LOADED,
      ),
      map(([catalogItems, products]) =>
        this.mergeInstances(catalogItems, products),
      ),
      map((data) =>
        data.map((item) => ({
          ...item,
          thumbnail: this.extractThumbnailAppHref(item),
        })),
      ),
      map((deviceCatalog) => sortBy(deviceCatalog, 'title')),
    );
  }

  private mergeInstances(
    catalogItems: ProductCatalogArticle[],
    products: Product[],
  ): ProductCatalogArticle[] {
    return catalogItems.map((item) => {
      const product = products.find((p) => p.productCatalogId === item.model);
      return {
        ...item,
        instances: product
          ? (product.quantity ?? 0) - (product.quantityLeft ?? 0)
          : 0,
        isFavorite: product ? product.isFavorite : undefined,
      };
    });
  }

  private extractThumbnailAppHref(
    catalogItem: ProductCatalogArticle,
  ): string | undefined {
    const thumbnailLink =
      catalogItem.links &&
      catalogItem.links.find(
        (link) =>
          link.sizes === '80x80' &&
          (link.rel === 'imageUrl' || link.rel === 'icon'),
      );

    return thumbnailLink?.appHref;
  }
}
