import { Injectable } from '@angular/core';
import { ScheduleValueTypes } from '@simpl/element-value-types';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { SchedulerConstants } from '../../scheduler.constants';

@Injectable()
export class PrototypeValueService {
  private readonly prototypeValueSubject$: BehaviorSubject<{
    [key: string]: ScheduleValueTypes;
  }> = new BehaviorSubject<{ [key: string]: ScheduleValueTypes }>({
    main: SchedulerConstants.prototypeValue,
  });

  prototypeValue$: Observable<{ [key: string]: ScheduleValueTypes }> =
    this.prototypeValueSubject$.asObservable();

  setPrototypeValue(
    objectId: string,
    prototypeValue: ScheduleValueTypes,
  ): void {
    this.prototypeValueSubject$.next(
      _.set(this.prototypeValueSubject$.value, objectId, prototypeValue),
    );
  }
}
