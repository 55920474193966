<form *ngIf="form" [formGroup]="form">
  <div class="si-modal-content-icon-wrapper si-modal-content-icon-spacer">
    <div class="modal-header">
      <h4 class="modal-title" id="sample-modal-title">
        {{ title || '' | translate }}
      </h4>
      <button
        type="button"
        class="btn btn-circle btn-sm btn-ghost element-cancel"
        aria-label="Close modal"
        [attr.data-testid]="'create-floor-room-modal-close-btn'"
        (click)="modalRef?.hide()"
      ></button>
    </div>
    <div class="modal-body">
      <si-form-container
        #formContainer
        [form]="form"
        [enableValidationHelp]="false"
        [errorCodeTranslateKeyMap]="errorCodeTranslateKeyMap"
        [controlNameTranslateKeyMap]="controlNameTranslateKeyMap"
      >
        <div si-form-container-content>
          <input
            type="text"
            class="form-control input-name"
            placeholder="defaultName"
            formControlName="name"
            [attr.data-testid]="'create-floor-or-room-input'"
          />
          <div
            class="invalid-feedback"
            *ngFor="
              let error of formContainer.getValidationErrors('name');
              let i = index
            "
          >
            <span
              *ngIf="i < 1 && formContainer.getValidationErrors()"
              [translate]="error.errorCodeTranslationKey!"
              [translateParams]="error.errorParams"
            ></span>
          </div>
        </div>
      </si-form-container>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        [attr.data-testid]="'create-floor-room-modal-cancel-btn'"
        (click)="modalRef?.hide()"
      >
        {{ 'GLOBALS.BUTTON.CANCEL' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!form.valid"
        [attr.data-testid]="'create-floor-room-modal-save-btn'"
        (click)="save.emit(form.value.name)"
      >
        {{ 'GLOBALS.BUTTON.ADD' | translate }}
      </button>
    </div>
  </div>
</form>
