import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AodsApiClientService } from '../../api-client/services/aods-api-client/aods-api-client.service';
import { UserPermission } from '../models/user-permission.enum';

@Injectable()
export class PermissionService {
  constructor(private aodsApiClientService: AodsApiClientService) {}

  getSelectedCustomerUserPermissions(): Observable<UserPermission[]> {
    return this.aodsApiClientService
      .getPermissionsByCurrentCustomerId()
      .pipe(
        map((customerPermissions) =>
          Object.values(UserPermission).filter((userPermission) =>
            customerPermissions.partitions.some((partition) =>
              partition.permissions.some(
                (permission) => permission === userPermission,
              ),
            ),
          ),
        ),
      );
  }
}
