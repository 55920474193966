import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SiLoadingSpinnerModule } from '@simpl/element-ng';

import { CodeScannerComponent } from './code-scanner.component';
import { ScanditWrapperComponent } from './scandit-wrapper/scandit-wrapper.component';

@NgModule({
  declarations: [CodeScannerComponent, ScanditWrapperComponent],
  imports: [CommonModule, SiLoadingSpinnerModule, ReactiveFormsModule],
  exports: [CodeScannerComponent, ScanditWrapperComponent],
})
export class CodeScannerModule {}
