import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/bootstrap/form-field';

import { ScanFormPatternService } from './services/scan-form-pattern.service';

@Component({
  selector: 'app-scan-form-type',
  templateUrl: './scan-form-type.component.html',
})
export class ScanFormTypeComponent extends FieldType {
  cameraActive = false;

  constructor(private scanFormPatternService: ScanFormPatternService) {
    super();
  }

  afterScan(scannedCode?: string): void {
    this.cameraActive = false;
    if (scannedCode) {
      if (this.field.fieldGroup) {
        const formValues =
          this.scanFormPatternService.resolveValuesByFieldsPattern(
            this.field.fieldGroup,
            scannedCode,
          );
        this.formControl.patchValue(formValues);
      } else {
        throw new Error('FieldGroup is not defined.');
      }
    }
  }
}
