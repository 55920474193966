import { CdkPortal } from '@angular/cdk/portal';
import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { SiModalService, SiSidePanelService } from '@simpl/element-ng';
import { first, map, Subscription, tap, withLatestFrom } from 'rxjs';

import { ActiveProjectState } from '../../../shared/active-project/models/active-project-state.model';
import { Floor } from '../../../shared/api-client/services/lcas-api-client/models/floor.model';
import { Location } from '../../../shared/api-client/services/lcas-api-client/models/location.model';
import { ParentEntity } from '../../../shared/models/business/parent-entity.enum';
import { FloorWithLocations } from '../../../sidemenu/planning/disciplines-and-functions/models/floor-with-locations.model';
import { DeviceDetailsRow } from '../../shared/generic-components/devices-details/models/device-details-row.model';
import { LocationType } from '../../shared/models/location-type.enum';
import { TreeItemWithId } from '../../shared/models/tree-item-with-id.model';
import { DeviceActionService } from '../../shared/services/device-action.service';
import { LocationNavigationService } from '../../shared/services/location-navigation.service';
import { StoreBridgeService } from '../../shared/services/store-bridge.service';
import { DevicesTableConfig } from '../../shared/generic-components/devices-details/models/devices-table-config.model';
import { MainDetailContainerService } from '../../shared/services/main-detail-container.service';
import { datatableConfig } from '../../../shared/datatable-config/datatable.config';

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  providers: [LocationNavigationService],
})
export class BuildingComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'd-flex flex-column flex-grow-1';

  @ViewChild('edgeRelocationModal') edgeRelocationModal!: TemplateRef<any>;

  @ViewChild('devicesRelocationModal')
  devicesRelocationModal!: TemplateRef<any>;

  @ViewChild('createFloorModal') createFloorModalRef?: TemplateRef<any>;

  @ViewChild('createRoomModal') createRoomModalRef?: TemplateRef<any>;

  @ViewChild('editFloorModal') editFloorModal?: TemplateRef<any>;

  @ViewChild('editLocationModal') editLocationModal?: TemplateRef<any>;

  @ViewChild('addDevicesModal') addDevicesModalRef?: TemplateRef<Element>;

  @ViewChild('sidePanelContent', { read: CdkPortal })
  sidePanelContent?: CdkPortal;

  entityToDelete: ParentEntity | undefined = undefined;

  linkToCommissioning: string | undefined = undefined;

  locationTypeEnum = LocationType;

  selectedLocationType: LocationType = LocationType.Floor;

  selectedFloorId?: string;

  selectedLocation?: Location;

  selectedFloor?: Floor;

  selectedDetailRow?: DeviceDetailsRow;

  dropdownActive = false;

  devicesTableConfig: DevicesTableConfig = {
    showReadonlyDeviceName: false,
    showMoveActionButton: true,
    showReplaceEdgeActionButton: false,
    showAddDevicesButton: true,
    showAssignButton: false,
    showStatusColumn: false,
    showValueColumn: false,
    showStatusAndConnectColumn: false,
    showDetailsButtonOnSmallerScreens: true,
    showUpdateThingInstanceActionButton: false,
    tableConfig: {
      ...datatableConfig,
      cellClass: 'visible-overflow',
      rowHeightSmall: 76,
      columnMinWidth: 80,
      deviceNameColumnMinWidth: 100,
      secondaryActionsColumnMinWidthOnLargerScreens: 145,
      secondaryActionsColumnMinWidthOnSmallerScreens: 45,
      limitMediaScreenBreak: 768,
    },
  };

  private newLocationToNavigateSubscription: Subscription = new Subscription();

  private deletedLocationIdSubscription: Subscription = new Subscription();

  private paramMapSubscription: Subscription = new Subscription();

  private addDevicesSubscription: Subscription = new Subscription();

  constructor(
    private siModalService: SiModalService,
    private store: Store<{
      project: ActiveProjectState;
    }>,
    private activatedRoute: ActivatedRoute,
    private deviceActionService: DeviceActionService,
    private siSidePanelService: SiSidePanelService,
    private storeBridgeService: StoreBridgeService,
    private locationNavigationService: LocationNavigationService,
    private mainDetailContainerService: MainDetailContainerService,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.siSidePanelService.close();
    this.completeSubscriptions();
  }

  onAddDevicesClick(selectedLocation: Location): void {
    this.selectedLocation = selectedLocation;
    this.deviceActionService.openAddDevicesModal$.next(true);
  }

  onAddLocationClick(type: LocationType): void {
    if (this.createFloorModalRef && this.createRoomModalRef) {
      this.siModalService.show(
        type === LocationType.Floor
          ? this.createFloorModalRef
          : this.createRoomModalRef,
        {
          class: 'modal-dialog-centered',
          ignoreBackdropClick: false,
          keyboard: false,
        },
      );
    }
  }

  openDevicesRelocationModal(data: {
    floorId: string;
    roomId: string | undefined;
    entityToDelete: ParentEntity;
  }): void {
    this.entityToDelete = data.entityToDelete;
    this.store
      .select((store) => store.project.activeProject?.id)
      .pipe(
        first(),
        tap((projectId) => {
          this.linkToCommissioning = `/sidemenu/${projectId}/commissioning/scan-and-assign/${data.floorId}/${data.roomId}`;
          this.siModalService.show(this.devicesRelocationModal, {
            class: 'modal-dialog-centered',
            ignoreBackdropClick: false,
            keyboard: false,
          });
        }),
      )
      .subscribe();
  }

  openEdgeRelocationModal(entityToDelete: ParentEntity): void {
    this.entityToDelete = entityToDelete;
    this.siModalService.show(this.edgeRelocationModal, {
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      keyboard: false,
    });
  }

  openDetails(row: DeviceDetailsRow): void {
    this.selectedDetailRow = row;
    this.siSidePanelService.setSidePanelContent(this.sidePanelContent);
    this.siSidePanelService.open();
  }

  openEditModal(
    treeItem: TreeItemWithId,
    floorsWithLocations: FloorWithLocations[],
  ): void {
    this.setSelectedTreeItem(treeItem, floorsWithLocations);
    const modalTemplate =
      treeItem.level === 0 ? this.editFloorModal : this.editLocationModal;
    if (modalTemplate) {
      this.siModalService.show(modalTemplate, {
        class: 'modal-dialog-centered',
        ignoreBackdropClick: false,
        keyboard: false,
      });
    }
  }

  closeDetailPanel() {
    this.siSidePanelService.close();
  }

  setSelectedLocationType(locationType: LocationType) {
    this.selectedLocationType = locationType;
    if (locationType === LocationType.Room) {
      this.mainDetailContainerService.notifyDetailsActive(true);
    }
  }

  private setSelectedTreeItem(
    treeItem: TreeItemWithId,
    floorsWithLocations: FloorWithLocations[],
  ): void {
    if (treeItem.level === 0) {
      this.selectedFloor = floorsWithLocations.find(
        (floor) => floor.id === treeItem.id,
      );
    } else {
      this.selectedLocation = floorsWithLocations
        .flatMap((floor) => floor.locations)
        .find((location) => location.id === treeItem.id);
    }
  }

  private initSubscriptions(): void {
    this.newLocationToNavigateSubscription.add(
      this.storeBridgeService.newLocationToNavigate$.subscribe(
        ({ floorId, locationId }) => {
          this.locationNavigationService.initNavigation(
            floorId,
            locationId,
            true,
          );
          this.mainDetailContainerService.notifyDetailsActive(true);
        },
      ),
    );

    this.deletedLocationIdSubscription.add(
      this.storeBridgeService.deletedLocationId$
        .pipe(
          withLatestFrom(
            this.activatedRoute.paramMap.pipe(
              map((paramMap) => ({
                selectedFloorId: paramMap.get('selectedFloorId') as string,
                selectedLocationId: paramMap.get(
                  'selectedLocationId',
                ) as string,
              })),
            ),
          ),
          tap(
            ([
              { deletedLocationId, floorsWithLocations },
              { selectedFloorId, selectedLocationId },
            ]) => {
              this.locationNavigationService.handleNavigation(
                selectedFloorId,
                selectedLocationId,
                floorsWithLocations,
                deletedLocationId,
              );
            },
          ),
        )
        .subscribe(),
    );

    this.paramMapSubscription.add(
      this.activatedRoute.paramMap.subscribe((paramMap) => {
        this.selectedFloorId = paramMap.get('selectedFloorId') ?? undefined;
      }),
    );

    this.addDevicesSubscription.add(
      this.deviceActionService.openAddDevicesModal$.subscribe(() => {
        if (this.addDevicesModalRef) {
          this.siModalService.show(this.addDevicesModalRef, {
            class: 'modal-dialog-centered',
            ignoreBackdropClick: false,
            keyboard: false,
          });
        }
      }),
    );
  }

  private completeSubscriptions(): void {
    this.newLocationToNavigateSubscription.unsubscribe();
    this.deletedLocationIdSubscription.unsubscribe();
    this.paramMapSubscription.unsubscribe();
    this.addDevicesSubscription.unsubscribe();
  }
}
