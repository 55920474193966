import { Injectable } from '@angular/core';
import { Customer } from '@building-x/common-ui-ng';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { ActiveProjectState } from '../../active-project/models/active-project-state.model';
import { AuthState } from '../../auth/models/auth-state.model';
import { EdgeDeviceState } from '../../devices-commissioning/models/edge-device-state.model';
import { ApiClientModule } from '../api-client.module';
import { Project } from './lcas-api-client/models/project.model';
import { Building } from './location-api-client/models/building.model';

@Injectable({ providedIn: ApiClientModule })
export class ApiClientHelperService {
  constructor(
    private store: Store<{
      auth: AuthState;
      project: ActiveProjectState;
      edgeDeviceState: EdgeDeviceState;
    }>,
  ) {}

  callAndAddStore<T>(
    call: (store: {
      customer: Customer | undefined;
      project: Project | undefined;
      building: Building | undefined;
      edgeDeviceId: string | undefined;
      edgeConfigId: string | undefined;
    }) => Observable<T>,
  ): Observable<T> {
    return this.store
      .select((store) => store)
      .pipe(
        first(),
        map((store) => ({
          customer: store.auth.selectedCustomer,
          project: store.project.activeProject,
          building: store.project.activeBuilding,
          edgeDeviceId: store.edgeDeviceState?.edgeDevice?.id,
          edgeConfigId:
            store.edgeDeviceState?.edgeDevice?.instance?.edgeConfig?.deviceId,
        })),
        switchMap((store) => call(store)),
      );
  }
}
