import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import {
  Subscription as CustomerSubscription,
  Partition,
} from '@building-x/common-ui-ng';

import { environment } from '../../../../../environments/environment';
import { ApiClientModule } from '../../api-client.module';
import { ApiClientHelperService } from '../api-client-helper.service';
import { CreatePartitionRequestBody } from './models/create-partition-request-body.model';
import { AcceptedBody } from './models/accepted-body.model';
import { HorizonError } from './models/horizon-error.model';
import {
  ListOrganizationChildren,
  OrganizationChildren,
} from './models/list-organization-children.model';
import { UpdatePartitionRequestBody } from './models/update-partition-request-body.model';

@Injectable({ providedIn: ApiClientModule })
export class SubscriptionApiClientService {
  constructor(
    private httpClient: HttpClient,
    private apiHelperService: ApiClientHelperService,
  ) {}

  postPartition(
    customerId: string,
    partitionData: CreatePartitionRequestBody,
  ): Observable<AcceptedBody | HorizonError> {
    return this.httpClient.post<AcceptedBody | HorizonError>(
      `${environment.subscriptionApi}/${environment.subscriptionApiVersion}/customers/${customerId}/partitions`,
      {
        data: partitionData,
      },
    );
  }

  patchPartition(
    customerId: string,
    partitionData: UpdatePartitionRequestBody,
  ): Observable<Partition> {
    return this.httpClient
      .patch<OrganizationChildren>(
        `${environment.subscriptionApi}/${environment.subscriptionApiVersion}/customers/${customerId}/partitions/${partitionData.id}`,
        { data: partitionData },
      )
      .pipe(
        switchMap((response: OrganizationChildren) =>
          response.data
            ? of(response.data)
            : throwError(() => new Error('Request failed')),
        ),
      );
  }

  getPartitions(customerId: string): Observable<Partition[]> {
    return this.httpClient
      .get<ListOrganizationChildren>(
        `${environment.subscriptionApi}/${environment.subscriptionApiVersion}/customers/${customerId}/partitions?include=ownedByCustomer.name`,
      )
      .pipe(
        switchMap((response: ListOrganizationChildren) =>
          response.data
            ? of(response.data)
            : throwError(() => new Error('Request failed')),
        ),
      );
  }

  getCustomerSubscriptions(): Observable<CustomerSubscription[]> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient
        .get<{
          data: CustomerSubscription[];
        }>(
          `${environment.subscriptionApi}/${environment.subscriptionApiVersion}/customers/${store.customer?.id}/subscriptions`,
        )
        .pipe(map((subs) => subs.data)),
    );
  }
}
