import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LocalizedDecimalPipe } from '../../../../shared/pipes/localized-decimal.pipe';

@Pipe({
  name: 'pointVerticalValuePipe',
})
export class PointVerticalValuePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string | undefined | null, pattern = '1.0-9'): string {
    if (!value) {
      return '';
    }

    if (Number(value)) {
      return this.transformNumberString(value, pattern);
    }

    if (this.isBoolean(value)) {
      return this.transformBooleanString(value);
    }

    return value;
  }

  private transformNumberString(value: string, pattern: string): string {
    const localizedDecimalPipe: LocalizedDecimalPipe = new LocalizedDecimalPipe(
      this.translateService,
    );
    return localizedDecimalPipe.transform(value, pattern);
  }

  private transformBooleanString(value: string): string {
    return value === 'true' ? '1' : '0';
  }

  private isBoolean(value: string): boolean {
    const lowerCaseValue = value.toLowerCase();
    return !!(lowerCaseValue === 'true' || lowerCaseValue === 'false');
  }
}
