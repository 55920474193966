import { AbstractControl, ValidatorFn } from '@angular/forms';

import { ProductCatalogArticle } from '../../../../../shared/api-client/services/lcas-api-client/models/catalog-product.model';
import { ProductType } from '../../../../../shared/api-client/services/lcas-api-client/models/product-type.enum';

export class AddProductsQuantityValidators {
  static maxInstancesOfType(
    catalog: ProductCatalogArticle,
    maxQuantity: number,
    type: ProductType,
  ): ValidatorFn {
    return (control: AbstractControl) => {
      if (
        control.value + catalog.instances > maxQuantity &&
        catalog.dataType === type
      ) {
        return { exceededProductQuantity: true };
      }
      return null;
    };
  }

  static minInstancesOfType(
    catalog: ProductCatalogArticle,
    minQuantity: number,
    type: ProductType,
  ): ValidatorFn {
    return (control: AbstractControl) => {
      if (
        control.value + catalog.instances < minQuantity &&
        catalog.dataType === type
      ) {
        return { insufficientProductQuantity: true };
      }
      return null;
    };
  }
}
