<div class="modal-header">
  <h4
    class="modal-title"
    [attr.data-testid]="'thing-instance-update-modal-title'"
  >
    {{ 'THING_INSTANCE_UPDATE_MODAL.TITLE' | translate }}
  </h4>
  <button
    type="button"
    class="btn btn-circle btn-sm btn-ghost element-cancel"
    aria-label="Close modal"
    [attr.data-testid]="'thing-instance-update-modal-close-element-btn'"
    (click)="modalRef.hide()"
  ></button>
</div>
<div
  class="modal-body"
  *ngIf="linkToAssetsManager$ | async as linkToAssetsManager"
>
  <p
    class="si-body-1 mt-4"
    [attr.data-testid]="'thing-instance-update-modal-paragraph'"
    [innerHTML]="
      'THING_INSTANCE_UPDATE_MODAL.CHECK_EDGE_FIRMWARE'
        | translate
          : {
              link:
                '<a href=' +
                linkToAssetsManager +
                ' target=\'_blank\'><b>here</b></a>'
            }
    "
  ></p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    id="btn-cancel"
    [attr.data-testid]="'thing-instance-update-modal-cancel-btn'"
    (click)="modalRef.hide()"
  >
    {{ 'GLOBALS.BUTTON.CANCEL' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-primary"
    [attr.data-testid]="'thing-instance-update-modal-confirm-btn'"
    (click)="onClickConfirm()"
  >
    {{ 'GLOBALS.BUTTON.CONFIRM' | translate }}
  </button>
</div>
