import { Component } from '@angular/core';
import { FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-boolean-wrapper',
  templateUrl: './formly-field-boolean-wrapper.component.html',
  styleUrls: ['./formly-field-boolean-wrapper.component.scss'],
})
export class FormlyFieldBooleanWrapperComponent extends FieldWrapper {
  hideLabel(input: FormlyFieldProps) {
    const inputIem = input;
    inputIem.description = inputIem.label;
    inputIem.label = undefined;
    inputIem.hideLabel = false;
  }
}
