import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { ApiClientModule } from '../../api-client.module';
import { ApiClientHelperService } from '../api-client-helper.service';
import { BuildingData } from './models/building-data.model';
import { Address } from './models/address.model';
import { BuildingUpdate } from './models/update-building.model';
import { AddressUpdate } from './models/update-address.model';

@Injectable({ providedIn: ApiClientModule })
export class LocationApiClientService {
  constructor(
    private httpClient: HttpClient,
    private apiHelperService: ApiClientHelperService,
  ) {}

  getBuildings(): Observable<{ data: BuildingData[]; included: Address[] }> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.get<{ data: BuildingData[]; included: Address[] }>(
        `${environment.locationApi}/${environment.locationApiVersion}/partitions/${store.project?.id}/locations?include=hasPostalAddress&filter[type]=Building`,
      ),
    );
  }

  assignDeviceToBuilding(edgeId: string): Observable<unknown> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch(
        `${environment.locationApi}/${environment.locationApiVersion}/partitions/${store.project?.id}/assets/${edgeId}/relationships/has-location`,
        {
          data: {
            id: store.building?.id,
            type: 'Building',
            meta: {
              assetType: 'Device',
            },
          },
        },
        this.getLocationApiClientContentType(),
      ),
    );
  }

  patchBuilding(
    buildingId: string,
    updatedBuilding: BuildingUpdate,
  ): Observable<unknown> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch(
        `${environment.locationApi}/${environment.locationApiVersion}/partitions/${store.project?.id}/locations/${buildingId}`,
        {
          data: updatedBuilding,
        },
        this.getLocationApiClientContentType(),
      ),
    );
  }

  patchAddress(
    addressId: string,
    updatedAddress: AddressUpdate,
  ): Observable<unknown> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.httpClient.patch(
        `${environment.locationApi}/${environment.locationApiVersion}/partitions/${store.project?.id}/addresses/${addressId}`,
        {
          data: updatedAddress,
        },
        this.getLocationApiClientContentType(),
      ),
    );
  }

  private getLocationApiClientContentType(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }),
    };
  }
}
