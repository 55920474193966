import { createAction, props } from '@ngrx/store';

import { CreateDevice } from '../../../../shared/api-client/services/lcas-api-client/models/create-device.model';
import { Device } from '../../../../shared/api-client/services/lcas-api-client/models/device.model';
import { Floor } from '../../../../shared/api-client/services/lcas-api-client/models/floor.model';
import { Location } from '../../../../shared/api-client/services/lcas-api-client/models/location.model';
import { Product } from '../../../../shared/api-client/services/lcas-api-client/models/product.model';
import { SetupFormModel } from '../../../../shared/api-client/services/lcas-api-client/models/setup-form.model';
import { UpdateDevice } from '../../../../shared/api-client/services/lcas-api-client/models/update-device.model';
import { FloorWithLocations } from '../../../../sidemenu/planning/disciplines-and-functions/models/floor-with-locations.model';
import { CreateLocation } from '../../../../sidemenu/planning/models/create-location.model';
import { DeviceDetailsRow } from '../../generic-components/devices-details/models/device-details-row.model';
import { MoveLocation } from '../../modal-dialogs/move-device-modal/models/move-location.model';

const tag = 'Location';

export const LocationActions = {
  showLoading: createAction(`[${tag}] Show Loading Spinner`),
  stopLoading: createAction(`[${tag}] Stop Showing Loading Spinner`),
  getFloorsAndLocations: createAction(`[${tag}] Get Floors And Locations`),
  initLoadFloorsAndLocations: createAction(
    `[${tag}] Init Load Floors And Locations`,
  ),
  loadFloorsAndLocationsSuccess: createAction(
    `[${tag}] Load Floors And Locations Success`,
    props<{ floorsWithLocations: FloorWithLocations[] }>(),
  ),
  initAddFloor: createAction(
    `[${tag}] Init Add Floor`,
    props<{
      floorName: string;
    }>(),
  ),
  addFloorSuccess: createAction(
    `[${tag}] Add Floor Success`,
    props<{
      floor: Floor;
    }>(),
  ),
  initPatchFloor: createAction(
    `[${tag}] Init Patch Function`,
    props<{ floor: Floor }>(),
  ),
  patchFloorSuccess: createAction(
    `[${tag}] Patch Function Success`,
    props<{ floor: Floor }>(),
  ),
  initDeleteFloor: createAction(
    `[${tag}] Init Delete Floor`,
    props<{ floorId: string }>(),
  ),
  deleteFloorSuccess: createAction(
    `[${tag}] Delete Floor Success`,
    props<{ floorId: string }>(),
  ),
  initAddLocation: createAction(
    `[${tag}] Init Add Location`,
    props<{
      floorId: string;
      location: CreateLocation;
    }>(),
  ),
  addLocationSuccess: createAction(
    `[${tag}] Add Location Success`,
    props<{
      location: Location;
    }>(),
  ),
  initPatchLocation: createAction(
    `[${tag}] Init Patch Location`,
    props<{
      location: Location;
    }>(),
  ),
  patchLocationSuccess: createAction(
    `[${tag}] Patch Location Success`,
    props<{ location: Location }>(),
  ),
  initDeleteLocation: createAction(
    `[${tag}] Init Delete Location`,
    props<{ floorId: string; locationId: string }>(),
  ),
  deleteLocationSuccess: createAction(
    `[${tag}] Delete Location Success`,
    props<{ floorId: string; locationId: string }>(),
  ),
  addFloorError: createAction(`[${tag}] Add Floor Error`),
  patchFloorError: createAction(`[${tag}] Patch Floor Error`),
  deleteFloorError: createAction(`[${tag}] Delete Floor Error`),
  addLocationError: createAction(`[${tag}] Add Location Error`),
  patchLocationError: createAction(`[${tag}] Patch Location Error`),
  deleteLocationError: createAction(`[${tag}] Delete Location Error`),
  deleteDeviceError: createAction(`[${tag}] Delete Device Error`),
  loadProductsError: createAction(`[${tag}] Load Products Error`),
  addMultipleDevicesError: createAction(`[${tag}]  Add Multiple Devices Error`),
  moveDeviceError: createAction(`[${tag}] Move Device Error`),
  patchDeviceError: createAction(`[${tag}] Patch Device Error`),
  getLocationWithDevices: createAction(
    `[${tag}] Get Location With Devices`,
    props<{ locationId: string }>(),
  ),
  initLoadLocationWithDevices: createAction(
    `[${tag}] Init Load Locations With Devices`,
    props<{ locationId: string }>(),
  ),
  loadLocationWithDevicesSuccess: createAction(
    `[${tag}] Load Location With Devices Success`,
    props<{ locationId: string; devices: Device[] }>(),
  ),
  addMultipleDevices: createAction(
    `[${tag}] Add Multiple Devices`,
    props<{ devices: CreateDevice[] }>(),
  ),
  addMultipleDevicesSuccess: createAction(
    `[${tag}]  Add Multiple Devices Success`,
    props<{
      locationId: string;
      devices: Device[];
    }>(),
  ),
  initDeleteDevice: createAction(
    `[${tag}] Init Delete Device`,
    props<{
      locationId: string;
      device: DeviceDetailsRow;
      force?: boolean;
    }>(),
  ),
  deleteDeviceSuccess: createAction(
    `[${tag}] Delete Device Success`,
    props<{ device: Device }>(),
  ),
  getBuildingProducts: createAction(`[${tag}] Get Building Products`),
  initLoadProducts: createAction(`[${tag}] Init Load Products`),
  loadProductsSuccess: createAction(
    `[${tag}] Load Products Success`,
    props<{ products: Product[] }>(),
  ),
  initMoveDevice: createAction(
    `[${tag}] Init Move Device`,
    props<{ moveLocation: MoveLocation }>(),
  ),
  moveDeviceSuccess: createAction(
    `[${tag}] Move Device Success`,
    props<{ device: Device; moveLocation: MoveLocation }>(),
  ),
  initRenameDevice: createAction(
    `[${tag}] Init Rename Device`,
    props<{ device: UpdateDevice }>(),
  ),
  patchDeviceSuccess: createAction(
    `[${tag}] Patch Device Success`,
    props<{ device: Device }>(),
  ),
  patchFormDevice: createAction(
    `[${tag}] Patch Form Device`,
    props<{ deviceId: string; setupFormModel: SetupFormModel; eTag: number }>(),
  ),
  clearState: createAction(`[${tag}]  Clear the state`),
};
