import { createAction, props } from '@ngrx/store';

import { PlannedDevice } from '../../api-client/services/lcas-api-client/models/planned-device.model';

export const EdgeDeviceActions = {
  setEdgeDevice: createAction(
    '[Edge Device] Set',
    props<{
      edgeDevice: PlannedDevice;
    }>(),
  ),
  updateEdgeDevice: createAction(
    '[Edge Device] Update',
    props<{
      properties: Partial<PlannedDevice>;
    }>(),
  ),
  clearEdgeStore: createAction('[Edge Device] Clear Store'),
};
