import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { activeProjectCanActivateGuard } from '../shared/active-project/guards/active-project-can-activate.guard';
import { SidemenuComponent } from './sidemenu.component';

export const setupFormComponentRoutePath = ':projectId/setup-form';

const routes: Routes = [
  {
    path: '',
    component: SidemenuComponent,
    children: [
      {
        path: ':projectId/dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [activeProjectCanActivateGuard],
        data: { breadcrumb: 'OPERATION.TITLE' },
      },
      {
        path: ':projectId/system-view',
        loadChildren: () =>
          import('./system-view/system-view.module').then(
            (m) => m.SystemViewModule,
          ),
        canActivate: [activeProjectCanActivateGuard],
        data: { breadcrumb: 'OPERATION.TITLE' },
      },
      {
        path: ':projectId/commissioning',
        loadChildren: () =>
          import('./commissioning/commissioning.module').then(
            (m) => m.CommissioningModule,
          ),
        canActivate: [activeProjectCanActivateGuard],
        data: { breadcrumb: 'COMMISSIONING.TITLE' },
      },
      {
        path: 'planning',
        loadChildren: () =>
          import('./planning/planning.module').then((m) => m.PlanningModule),
        data: { breadcrumb: 'PLANNING.TITLE' },
      },
      {
        path: ':projectId/building',
        loadChildren: () =>
          import('../v2/sidemenu/building/building.module').then(
            (m) => m.BuildingModule,
          ),
        canActivate: [activeProjectCanActivateGuard],
        data: { breadcrumb: 'PLANNING.TITLE' },
      },
      {
        path: setupFormComponentRoutePath,
        loadChildren: () =>
          import('../shared/formly/shared-formly-routing.module').then(
            (m) => m.SharedFormlyRoutingModule,
          ),
        canActivate: [activeProjectCanActivateGuard],
        data: { breadcrumb: 'PLANNING.TITLE' },
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SidemenuRoutingModule {}
