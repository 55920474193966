<div class="has-navbar-fixed-top si-layout-fixed-height h-100">
  <si-navbar-primary
    [appTitle]="'LANDING_PAGE.APP_NAME' | translate"
    [account]="accountInfo"
    [accountItems]="accountItems"
    [appItems]="(appItems$ | async)!"
    [attr.data-testid]="
      'sidemenu-' + (sidemenuTestIds.length > 1) ? sidemenuTestIds : ''
    "
  >
    <si-navbar-item
      quickAction
      [item]="{ title: 'Toggle tutorials', icon: 'element-info' }"
      siTooltip="Enable tutorials"
      placement="bottom"
      [attr.data-testid]="'toggle-tutorials'"
      (click)="enableTutorials()"
    ></si-navbar-item>
  </si-navbar-primary>
  <si-navbar-vertical
    *ngIf="menuItems$ | async as menuItems"
    [items]="menuItems"
    [visible]="!isProjectsPageActive() && !isEditProjectPageActive()"
  >
    <div class="si-layout-fixed-height mb-6 si-layout-content-padding">
      <si-side-panel [mode]="mode" [size]="size" (closed)="onClose()">
        <router-outlet></router-outlet>
      </si-side-panel>
    </div>
  </si-navbar-vertical>
</div>
<ng-template #sidePanelContent cdkPortal>
  <si-side-panel-content [heading]="sidePanelHeadingTranslation">
    <si-customer-list
      [allPermissions]="customerPermissions"
      [appPermission]="appPermission"
      [customers]="customers"
      [customerPartitions]="
        (isAllPartitionsLoaded$ | async)! ? customerPartitions : []
      "
      [isAllPartitionsLoaded]="isCustomerDataLoaded"
      [isSwitcherPartitionDataLoading]="!isCustomerDataLoaded"
      [doSelectAllPartitionsByDefault]="true"
      [selectedCustomer]="selectedCustomer"
      [showSubscriptionNameToggleButton]="false"
      (customerChange)="selectCustomer($event)"
      (selectedCustomerPartitions)="applySelectedPartitions()"
    >
    </si-customer-list>
  </si-side-panel-content>
</ng-template>
