import { Provider } from '@angular/core';
import { Router } from '@angular/router';

import { LcasApiClientService } from '../../../api-client/services/lcas-api-client/lcas-api-client.service';
import { SetupFormDeviceHelperService } from '../services/setup-form-device-helper.service';
import { SetupFormFunctionHelperService } from '../services/setup-form-function-helper.service';

export const TypedSetupFormHelperFactory: Provider = {
  provide: 'typedSetupFormHelper',
  useFactory: (router: Router, apiClientService: LcasApiClientService) =>
    router.url.includes('device')
      ? new SetupFormDeviceHelperService(router, apiClientService)
      : new SetupFormFunctionHelperService(router, apiClientService),
  deps: [Router, LcasApiClientService],
};
