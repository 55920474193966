import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalRef, SiModalService } from '@simpl/element-ng';

import { Floor } from '../../../../../shared/api-client/services/lcas-api-client/models/floor.model';
import { Location } from '../../../../../shared/api-client/services/lcas-api-client/models/location.model';
import {
  maximumLengthForName,
  minimumLengthForName,
} from '../../../../shared/devices-setup-form/constants/form-input.constants';
import { LocationType } from '../../../../shared/models/location-type.enum';

@Component({
  selector: 'app-edit-location-base-modal',
  templateUrl: './edit-location-base-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLocationBaseModalComponent implements OnChanges {
  @Input() modalRef?: ModalRef;

  @Input() type?: LocationType;

  @Input() location?: Floor | Location;

  @Output() save: EventEmitter<string> = new EventEmitter();

  form?: FormGroup;

  minimumLengthForName = minimumLengthForName;

  maximumLengthForName = maximumLengthForName;

  locationType = LocationType;

  constructor(
    private formBuilder: FormBuilder,
    private siModalService: SiModalService,
  ) {}

  ngOnChanges(): void {
    if (this.location) {
      this.form = this.formBuilder.group({
        [this.location.id]: [
          this.location.name,
          [
            Validators.required,
            Validators.minLength(minimumLengthForName),
            Validators.maxLength(maximumLengthForName),
          ],
        ],
      });
    } else {
      throw new Error('Location not provided!');
    }
  }

  asLocation(location?: Location | Floor): Location | undefined {
    return location as Location;
  }

  close() {
    this.modalRef?.hide();
    this.siModalService.detach(this.modalRef!);
  }
}
