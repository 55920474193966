import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  Camera,
  CameraSwitchControl,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  RectangularViewfinder,
  RectangularViewfinderStyle,
  RectangularViewfinderLineStyle,
  configure as CoreConfig,
  Viewfinder,
  TorchSwitchControl,
} from 'scandit-web-datacapture-core';
import {
  BarcodeCapture,
  BarcodeCaptureListener,
  BarcodeCaptureOverlay,
  BarcodeCaptureOverlayStyle,
  BarcodeCaptureSession,
  BarcodeCaptureSettings,
  barcodeCaptureLoader as CaptureLoader,
  Symbology,
} from 'scandit-web-datacapture-barcode';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-scandit-wrapper',
  templateUrl: './scandit-wrapper.component.html',
})
export class ScanditWrapperComponent implements OnInit, OnDestroy {
  @Input() timeBetweenSuccessScans = 10000;

  @Input() highEndBlurryRecognition = false;

  @Input() formats: Symbology[] = [];

  @Output() scan: EventEmitter<string | undefined> = new EventEmitter();

  @ViewChild('cameraContainer') private cameraContainer:
    | ElementRef<HTMLInputElement>
    | undefined;

  private camera: Camera = Camera.default;

  private view: DataCaptureView | undefined;

  private viewFinder: Viewfinder | undefined;

  private barcodeCapture: BarcodeCapture | undefined;

  private barcodeCaptureOverlay: BarcodeCaptureOverlay | undefined;

  private barcodeCaptureListener: BarcodeCaptureListener | undefined;

  private dataCaptureContext: DataCaptureContext | undefined;

  private resultTimeout: ReturnType<typeof setTimeout> | undefined;

  async ngOnInit(): Promise<void> {
    await this.scanditInit();
  }

  async ngOnDestroy(): Promise<void> {
    await this.scanditDestroy();
  }

  private async scanditInit(): Promise<void> {
    await this.scanditConfig();
    await this.scanditInitScan();
  }

  private async scanditInitScan(): Promise<void> {
    this.dataCaptureContext = await DataCaptureContext.create();
    await this.dataCaptureContext.setFrameSource(this.camera);

    const barcodeCaptureSettings = new BarcodeCaptureSettings();
    barcodeCaptureSettings.enableSymbologies(this.formats);
    barcodeCaptureSettings.codeDuplicateFilter = this.timeBetweenSuccessScans;
    this.formats.forEach((f) => {
      barcodeCaptureSettings.settingsForSymbology(f).isColorInvertedEnabled =
        true;
    });

    this.barcodeCapture = await BarcodeCapture.forContext(
      this.dataCaptureContext,
      barcodeCaptureSettings,
    );
    await this.barcodeCapture.setEnabled(false);

    this.view = await DataCaptureView.forContext(this.dataCaptureContext);
    this.barcodeCaptureOverlay =
      await BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
        this.barcodeCapture,
        this.view,
        BarcodeCaptureOverlayStyle.Frame,
      );
    this.barcodeCaptureListener = this.getBorderCaptureListener();
    this.barcodeCapture.addListener(this.barcodeCaptureListener);

    this.bindViewToHtmlElement();
    this.viewFinder = new RectangularViewfinder(
      RectangularViewfinderStyle.Square,
      RectangularViewfinderLineStyle.Light,
    );
    this.setViewFinderAndStartScan();
  }

  private async scanditConfig(): Promise<void> {
    await CoreConfig({
      licenseKey: environment.scanditApiKey,
      libraryLocation: './assets/scandit',
      moduleLoaders: [
        CaptureLoader({
          highEndBlurryRecognition: this.highEndBlurryRecognition,
        }),
      ],
    });
  }

  private async setViewFinderAndStartScan(): Promise<void> {
    await this.barcodeCaptureOverlay?.setViewfinder(this.viewFinder || null);
    await this.camera.switchToDesiredState(FrameSourceState.On);
    await this.barcodeCapture?.setEnabled(true);
  }

  private async scanditDestroy(): Promise<void> {
    await this.dataCaptureContext?.frameSource?.switchToDesiredState(
      FrameSourceState.Off,
    );
    if (this.barcodeCaptureListener) {
      await this.barcodeCapture?.removeListener(this.barcodeCaptureListener);
    }
    clearTimeout(this.resultTimeout);
    await this.dataCaptureContext?.dispose();
  }

  private getBorderCaptureListener(): BarcodeCaptureListener {
    return {
      didScan: async (
        barcodeCaptureMode: BarcodeCapture,
        session: BarcodeCaptureSession,
      ) => {
        await this.barcodeCaptureOverlay?.setViewfinder(null);
        await this.barcodeCapture?.setEnabled(false);
        this.handleResult(session.newlyRecognizedBarcodes[0].data || undefined);
      },
    };
  }

  private bindViewToHtmlElement(): void {
    if (!this.cameraContainer) {
      throw new Error('Unable to find container element for camera output');
    }
    this.view?.connectToElement(this.cameraContainer?.nativeElement);
    this.view?.addControl(new CameraSwitchControl());
    this.view?.addControl(new TorchSwitchControl());
  }

  private handleResult(result: string | undefined): void {
    this.resultTimeout = setTimeout(() => {
      this.barcodeCapture?.setEnabled(true);
      this.barcodeCaptureOverlay?.setViewfinder(this.viewFinder || null);
    }, this.timeBetweenSuccessScans);

    this.scan.emit(result);
  }
}
