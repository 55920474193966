import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PollingService {
  private stopPolling$: Subject<undefined> = new Subject();

  startPolling<T>(
    observable: Observable<T>,
    timeoutInMilliseconds: number,
  ): Observable<T> {
    return timer(0, timeoutInMilliseconds).pipe(
      switchMap(() => observable),
      takeUntil(this.stopPolling$),
    );
  }

  stopPolling(): void {
    this.stopPolling$.next(undefined);
  }
}
