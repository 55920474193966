import { Component, Input } from '@angular/core';
import { ModalRef } from '@simpl/element-ng';

import { ParentEntity } from '../../../../../shared/models/business/parent-entity.enum';

@Component({
  selector: 'app-edge-device-modal',
  templateUrl: './edge-device-modal.component.html',
})
export class EdgeDeviceModalComponent {
  @Input() entityToDelete: ParentEntity | undefined;

  @Input() modalRef: ModalRef<unknown> | undefined;
}
