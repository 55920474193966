import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppsUrlHelperService } from './apps-url/apps-url-helper.service';
import { SessionService } from './auth/services/session.service';
import { UrlBuilderService } from './details-card/services/url-builder.service';
import { DeviceCommissioningService } from './device-commissioning/device-commissioning.service';
import { FunctionCommissioningService } from './function-commissioning/function-commissioning.service';
import { LanguageService } from './i18n/language.service';
import { BooleanToNumberPipe } from './pipes/boolean-to-number.pipe';
import { DisciplineNamePipe } from './pipes/discipline-name.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { LocalizedDecimalPipe } from './pipes/localized-decimal.pipe';
import { RoundingDecimalPipe } from './pipes/rounding-decimal.pipe';
import { UuidProvider } from './providers/uuid.provider';
import { BorderRouterDeletionService } from './services/border-router-deletion.service';
import { ContentActionBarService } from './services/content-action-bar.service';
import { DeletionPermissionService } from './services/deletion-permission.service';
import { FormAbstractionService } from './services/form-abstraction.service';
import { UpdatePartitionService } from './services/update-partition.service';
import { UpdateProjectStatusService } from './services/update-project-status.service';
import { UpdateLocationVerticalService } from './services/update-location-vertical.service';
import { EditProjectService } from '../sidemenu/planning/services/edit-project.service';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  providers: [
    LanguageService,
    UpdatePartitionService,
    FormAbstractionService,
    UuidProvider,
    DeviceCommissioningService,
    FunctionCommissioningService,
    ContentActionBarService,
    DeletionPermissionService,
    BorderRouterDeletionService,
    AppsUrlHelperService,
    UrlBuilderService,
    SessionService,
    UpdateProjectStatusService,
    UpdateLocationVerticalService,
    EditProjectService,
  ],
  declarations: [
    LocalizedDatePipe,
    LocalizedDecimalPipe,
    BooleanToNumberPipe,
    DisciplineNamePipe,
    RoundingDecimalPipe,
  ],
  exports: [
    ReactiveFormsModule,
    BooleanToNumberPipe,
    LocalizedDatePipe,
    LocalizedDecimalPipe,
    RoundingDecimalPipe,
    DisciplineNamePipe,
  ],
})
export class SharedModule {}
