import { CdkPortal } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  Customer,
  LoadingState,
  Partition,
  Permission,
  SiAppDataService,
} from '@building-x/common-ui-ng';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  AccountItem,
  MenuItem,
  NavbarItem,
  SiSidePanelService,
  SidePanelMode,
  SidePanelSize,
} from '@simpl/element-ng';
import { OAuthService } from 'angular-oauth2-oidc';
import { isEqual } from 'lodash';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  forkJoin,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { ActiveProjectState } from '../shared/active-project/models/active-project-state.model';
import { AodsApiClientService } from '../shared/api-client/services/aods-api-client/aods-api-client.service';
import { SubscriptionApiClientService } from '../shared/api-client/services/subscription-api-client/subscription-api-client.service';
import { AppsUrlHelperService } from '../shared/apps-url/apps-url-helper.service';
import { Area } from '../shared/auth/models/area.enum';
import { AuthState } from '../shared/auth/models/auth-state.model';
import { UserPermission } from '../shared/auth/models/user-permission.enum';
import { AreaAccessService } from '../shared/auth/services/area-access.service';
import { AuthActions } from '../shared/auth/store/auth.actions';
import { TutorialState } from '../shared/tutorial/models/tutorial-show.model';
import { TutorialActions } from '../shared/tutorial/store/tutorial.actions';
import { IdentityClaims } from './dashboard/models/identity-claims.model';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
})
export class SidemenuComponent implements OnInit, OnDestroy {
  @ViewChild('sidePanelContent', { read: CdkPortal })
  sidePanelContent?: CdkPortal;

  menuItems$: Observable<NavbarItem[]> = combineLatest([
    this.store.select((store) => store.project.activeProject?.id),
    this.store.select((store) => store.auth.selectedCustomerUserPermissions),
  ]).pipe(
    distinctUntilChanged(isEqual),
    map(([projectId, userPermissions]) =>
      this.getAvailableMenuItems(projectId || '', userPermissions || []),
    ),
  );

  accountItems: MenuItem[] = [
    {
      title: 'SIDEMENU.APP_ITEMS.CUSTOMERS',
      icon: 'element-project',
      link: '',
      action: (): void => {
        this.mode = 'over';
        this.sidePanelService.setSidePanelContent(this.sidePanelContent);
        this.sidePanelService.open();
      },
    },
    { title: '-' },
    {
      title: 'SIDEMENU.APP_ITEMS.LOGOUT',
      icon: 'element-logout',
      action: () => this.store.dispatch(AuthActions.logOut()),
    },
  ];

  appItems$: Observable<MenuItem[]> = combineLatest([
    this.store.select((store) => ({
      selectedCustomer: store.auth.selectedCustomer,
      selectedPartition: store.project.activeProject,
    })),
    this.translateService.get('SIDEMENU.APP_SWITCHER'),
  ]).pipe(
    map(([{ selectedCustomer, selectedPartition }, { ACCOUNTS, DEVICES }]) => [
      {
        title: ACCOUNTS,
        icon: 'element-account',
        href: this.appsUrlHelperService.getAccountAppUrl(
          selectedCustomer?.id,
          selectedPartition?.id,
        ),
      },
      {
        title: DEVICES,
        icon: 'element-canvas',
        href: this.appsUrlHelperService.getAssetsAppUrl(
          selectedCustomer?.id,
          selectedPartition?.id,
        ),
      },
    ]),
  );

  private getDataCustomer$ = new Subject();

  readonly isAllPartitionsLoaded$: Observable<boolean> =
    this.getDataCustomer$.pipe(
      startWith(false),
      switchMap(() => {
        this.isCustomerDataLoaded = false;
        const customerPermissions =
          this.aodsApiClientService.getPermissionsByCustomerId(
            this.selectedCustomer.id!,
          );
        const customerPartitions =
          this.subscriptionApiClientService.getPartitions(
            this.selectedCustomer.id!,
          );
        return forkJoin([customerPermissions, customerPartitions]).pipe(
          map(([permissions, partitions]) => {
            this.customerPermissions = permissions;
            this.customerPartitions = partitions;
            this.isCustomerDataLoaded = !!permissions && !!partitions;
            return this.isCustomerDataLoaded;
          }),
        );
      }),
    );

  private readonly projectsPageURL = '/sidemenu/planning/projects';

  private readonly createProjectPageURL = '/sidemenu/planning/create';

  private readonly editProjectPageURL = '/sidemenu/planning/edit/';

  private subscriptions: Subscription[] = [];

  accountInfo?: AccountItem;

  showTutorials = false;

  customers: Customer[] = [];

  customerPermissions!: Permission;

  customerPartitions: Partition[] = [];

  isCustomerDataLoaded = false;

  selectedCustomer!: Customer;

  mode: SidePanelMode = 'scroll';

  size: SidePanelSize = 'regular';

  appPermission = 'app.lcs';

  sidemenuTestIds: string[] = ['GLOBALS.HOME'];

  sidePanelHeadingTranslation: string = '';

  currentPathUrl: string = '';

  constructor(
    private oAuthService: OAuthService,
    private store: Store<{
      project: ActiveProjectState;
      auth: AuthState;
      tutorial: TutorialState;
    }>,
    private translateService: TranslateService,
    private router: Router,
    private subscriptionApiClientService: SubscriptionApiClientService,
    private sidePanelService: SiSidePanelService,
    private aodsApiClientService: AodsApiClientService,
    private areaAccessService: AreaAccessService,
    private siAppDataService: SiAppDataService,
    private appsUrlHelperService: AppsUrlHelperService,
  ) {}

  onClose(): void {
    this.mode = 'scroll';
    this.sidePanelService.setSidePanelContent(undefined);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.listenForUrlChange();
    this.sidePanelHeadingTranslation = this.translateService.instant(
      'HORIZON_UI.CUSTOMER_PARTITION_SWITCHER.TITLE',
    );
    this.accountInfo = this.getAccountInfo(
      this.oAuthService.getIdentityClaims() as IdentityClaims,
    );

    this.store
      .select((store) => store.auth.customers)
      .pipe(first())
      .subscribe((customers) => {
        this.customers = customers;
        this.siAppDataService.isAllCustomersLoaded$.next(LoadingState.Complete);
      });

    this.subscriptions.push(
      this.store
        .select((store) => store.tutorial.showTutorials)
        .pipe(filter((showTutorials) => showTutorials !== undefined))
        .subscribe((showTutorials) => {
          this.showTutorials = showTutorials;
        }),
    );

    this.store
      .select((store) => store.auth.selectedCustomer)
      .pipe(first())
      .subscribe((selectedCustomer) => {
        if (selectedCustomer) {
          this.selectCustomer(selectedCustomer);
        }
      });
  }

  selectCustomer(selectedCustomer: Customer): void {
    this.selectedCustomer = selectedCustomer;
    this.getDataCustomer$.next(undefined);
  }

  applySelectedPartitions(): void {
    this.projectsListRedirect();
    this.sidePanelService.close();
    this.store.dispatch(
      AuthActions.setSelectedCustomer({
        selectedCustomer: this.selectedCustomer,
      }),
    );
  }

  projectsListRedirect(): void {
    if (!this.isProjectsPageActive() || this.isEditProjectPageActive())
      this.router.navigate([this.projectsPageURL]);
  }

  isProjectsPageActive(): boolean {
    return (
      this.router.url === this.projectsPageURL ||
      this.router.url === this.createProjectPageURL
    );
  }

  isEditProjectPageActive(): boolean {
    return this.router.url.startsWith(this.editProjectPageURL);
  }

  enableTutorials(): void {
    this.store.dispatch(
      TutorialActions.setTutorials({
        showTutorials: false,
      }),
    );
  }

  private listenForUrlChange(): void {
    this.currentPathUrl = this.router.url;
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter(
            (event): event is NavigationEnd => event instanceof NavigationEnd,
          ),
          map((event: NavigationEnd) => event.url),
          tap((url) => {
            this.currentPathUrl = url;
          }),
        )
        .subscribe(),
    );
  }

  private getAvailableMenuItems(
    projectId: string,
    userPermissions: UserPermission[],
  ): NavbarItem[] {
    const menuItems: NavbarItem[] = [
      {
        title: 'GLOBALS.HOME',
        icon: 'element-home',
        link: ['/sidemenu', 'planning', 'projects'],
        tooltip: 'GLOBALS.HOME',
      },
    ];

    if (
      this.areaAccessService.hasAccessToArea(userPermissions, Area.Planning)
    ) {
      menuItems.push(this.getPlanningMenuItem(projectId));
    }

    if (
      this.areaAccessService.hasAccessToArea(
        userPermissions,
        Area.Commissioning,
      )
    ) {
      menuItems.push(this.getCommissioningMenuItem(projectId));
    }

    if (
      this.areaAccessService.hasAccessToArea(userPermissions, Area.Operation)
    ) {
      menuItems.push(this.getOperationMenuItem(projectId));
    }

    return menuItems;
  }

  private getAccountInfo(claims: IdentityClaims): AccountItem {
    return {
      title: `${claims.given_name} ${claims.family_name}`,
      email: claims.email,
      image: claims.picture,
    };
  }

  private getPlanningMenuItem(projectId: string): NavbarItem {
    if (!this.sidemenuTestIds.includes('PLANNING.PHYSICAL_ENVIRONMENT.TITLE')) {
      this.sidemenuTestIds.push(
        'PLANNING.PHYSICAL_ENVIRONMENT.TITLE',
        'DISCIPLINES_AND_FUNCTIONS.TITLE',
      );
    }

    return {
      title: 'PLANNING.TITLE',
      icon: 'element-building',
      tooltip: 'PLANNING.TITLE',
      items: [
        {
          title: 'PLANNING.PHYSICAL_ENVIRONMENT.TITLE',
          link: ['/sidemenu', projectId, 'building'],
          tooltip: 'PLANNING.PHYSICAL_ENVIRONMENT.TITLE',
        },
        {
          title: 'DISCIPLINES_AND_FUNCTIONS.TITLE',
          link: [
            '/sidemenu',
            'planning',
            projectId,
            'disciplines-and-functions',
          ],
          tooltip: 'DISCIPLINES_AND_FUNCTIONS.TITLE',
        },
      ],
    };
  }

  private getCommissioningMenuItem(projectId: string): NavbarItem {
    if (!this.sidemenuTestIds.includes('COMMISSIONING.SCAN_AND_ASSIGN.TITLE')) {
      this.sidemenuTestIds.push(
        'COMMISSIONING.SCAN_AND_ASSIGN.TITLE',
        'DISCIPLINES_AND_FUNCTIONS.TITLE',
        'COMMISSIONING.SYSTEM_TEST.TITLE',
      );
    }
    return {
      title: 'COMMISSIONING.TITLE',
      icon: 'element-installer',
      tooltip: 'COMMISSIONING.TITLE',
      items: [
        {
          title: 'COMMISSIONING.SCAN_AND_ASSIGN.TITLE',
          link: ['/sidemenu', projectId, 'commissioning', 'scan-and-assign'],
          tooltip: 'COMMISSIONING.SCAN_AND_ASSIGN.TITLE',
        },
        {
          title: 'DISCIPLINES_AND_FUNCTIONS.TITLE',
          link: [
            '/sidemenu',
            projectId,
            'commissioning',
            'disciplines-and-functions',
          ],
          tooltip: 'DISCIPLINES_AND_FUNCTIONS.TITLE',
        },
        {
          title: 'COMMISSIONING.SYSTEM_TEST.TITLE',
          link: ['/sidemenu', projectId, 'commissioning', 'system-test'],
          tooltip: 'COMMISSIONING.SYSTEM_TEST.TITLE',
        },
      ],
    };
  }

  private getOperationMenuItem(projectId: string): NavbarItem {
    if (!this.sidemenuTestIds.includes('DASHBOARD.TITLE')) {
      this.sidemenuTestIds.push(
        'DASHBOARD.TITLE',
        'SYSTEM_VIEW.NAVIGATION_TITLE',
      );
    }
    return {
      title: 'OPERATION.TITLE',
      icon: 'element-dashboard',
      tooltip: 'OPERATION.TITLE',
      items: [
        {
          title: 'DASHBOARD.TITLE',
          link: ['/sidemenu', projectId, 'dashboard'],
          tooltip: 'DASHBOARD.TITLE',
        },
        {
          title: 'SYSTEM_VIEW.NAVIGATION_TITLE',
          link: ['/sidemenu', projectId, 'system-view'],
          tooltip: 'SYSTEM_VIEW.NAVIGATION_TITLE',
        },
      ],
    };
  }
}
