import { Injectable } from '@angular/core';

import {
  DeviceStatusCondition,
  deviceStatusConfig,
} from '../../constants/device-status-config.constants';
import { DeviceStatus } from '../../models/business/device-status.enum';

@Injectable({ providedIn: 'root' })
export class QualityDeviceStatusMappingService {
  private readonly qualityDeviceStatusMapping: { [k: number]: DeviceStatus } = {
    0: 'connected',
    1: 'error',
    2: 'not-connected',
    3: 'error',
    4: 'pending',
    6: 'authentication-failure',
  };

  private readonly secondaryDeviceStatusMapping: { [k: string]: DeviceStatus } =
    {
      BattStaEmpty: 'battery-empty',
      FrPrtSta: 'frost',
      BattStaLow: 'battery-low',
      ActrFlt: 'actuator-fault',
    };

  readonly deviceStatusConfig = deviceStatusConfig;

  getDeviceStatus(qualityOfValue: number): DeviceStatus {
    return this.qualityDeviceStatusMapping[qualityOfValue];
  }

  getSecondaryDeviceStatus(
    dataType: string,
    value: string,
  ): DeviceStatus | undefined {
    const statusConfig = this.deviceStatusConfig.find(
      (config) => config.dataType === dataType,
    );

    if (statusConfig) {
      switch (statusConfig.condition) {
        case DeviceStatusCondition.EQUALS:
          return statusConfig.value === value
            ? this.secondaryDeviceStatusMapping[dataType]
            : undefined;

        case DeviceStatusCondition.NOT_EQUALS:
          return statusConfig.value !== value
            ? this.secondaryDeviceStatusMapping[dataType]
            : undefined;

        default:
          throw new Error(`Unsupported condition: ${statusConfig.condition}`);
      }
    }

    return undefined;
  }
}
