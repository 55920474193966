import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DeviceApiClientService } from '../../api-client/services/device-api-client/device-api-client.service';
import { Feature } from '../../api-client/services/device-api-client/models/feature.model';
import { PlannedDevice } from '../../api-client/services/lcas-api-client/models/planned-device.model';
import { DeviceStatus } from '../../models/business/device-status.enum';
import { EdgeConnectivityInfo } from '../../models/edge-connectivity-info.model';
import { PollingService } from './polling.service';

@Injectable({ providedIn: 'root' })
export class EdgeConnectivityService {
  constructor(
    private deviceApiClientService: DeviceApiClientService,
    private pollingService: PollingService,
  ) {}

  appendOnlineState(edgeDevice: PlannedDevice): Observable<PlannedDevice> {
    return edgeDevice.instance?.edgeConfig?.deviceId
      ? this.deviceApiClientService
          .getDeviceFeatures(edgeDevice.instance?.edgeConfig?.deviceId)
          .pipe(
            map((featuresData) => ({
              ...edgeDevice,
              deviceStatus: (this.getOnlineState(featuresData.data)
                ? 'connected'
                : 'not-connected') as DeviceStatus,
            })),
            catchError(() =>
              of({
                ...edgeDevice,
                deviceStatus: 'not-connected' as DeviceStatus,
              }),
            ),
          )
      : of(edgeDevice);
  }

  public pollEdgeConnectivityStatus(
    edgeDevice: PlannedDevice,
  ): Observable<boolean> {
    return this.pollingService.startPolling(
      this.getEdgeConnectivityStatus(edgeDevice),
      5000,
    );
  }

  public stopPollingEdgeConnectivityStatus(): void {
    this.pollingService.stopPolling();
  }

  public getEdgeConnectivityStatus(
    edgeDevice: PlannedDevice,
  ): Observable<boolean> {
    return edgeDevice.instance?.edgeConfig?.deviceId
      ? this.deviceApiClientService
          .getDeviceFeatures(edgeDevice.instance?.edgeConfig?.deviceId)
          .pipe(
            map((featuresData) => this.getOnlineState(featuresData.data)),
            catchError(() => of(false)),
          )
      : of(false);
  }

  public shouldChangeEdgeConnectivityInfo(
    currentIsEdgeConnected: boolean,
    edgeConnectivityInfo: EdgeConnectivityInfo,
  ): boolean {
    return (
      this.firstTimePollingEdgeState(edgeConnectivityInfo) ||
      this.edgeChangedState(currentIsEdgeConnected, edgeConnectivityInfo)
    );
  }

  private getOnlineState(features: Feature[]): boolean {
    return !!features.find((feature) => feature.type === 'Connectivity')
      ?.attributes.connected;
  }

  private firstTimePollingEdgeState(
    edgeConnectivityInfo: EdgeConnectivityInfo,
  ): boolean {
    return edgeConnectivityInfo.fetchedConnectivity === false;
  }

  private edgeChangedState(
    currentIsEdgeConnected: boolean,
    edgeConnectivityInfo: EdgeConnectivityInfo,
  ): boolean {
    return currentIsEdgeConnected !== edgeConnectivityInfo.connected;
  }
}
