<app-breadcrumb *ngIf="!isModal"></app-breadcrumb>
<div
  [siBackdropWithSpinner]="(isSchedulerLoading$ | async) === true"
  class="overflow-hidden"
  [ngClass]="{ 'scheduler-view': !isModal }"
>
  <div class="modal-header" [ngClass]="{ 'justify-content-end': isModal }">
    <p *ngIf="!isModal" class="si-title-1 px-4 mb-0">{{ schedulerName }}</p>
    <button
      *ngIf="isModal"
      type="button"
      class="btn-close"
      aria-label="Close"
      [attr.data-testid]="'close-setup-form-modal-btn'"
      (click)="close()"
    ></button>
  </div>
  <form
    *ngIf="fields$ | async as fields"
    [formGroup]="form"
    (ngSubmit)="submit(fields.model)"
    class="d-flex flex-column"
  >
    <formly-form
      [form]="form"
      [fields]="fields.config"
      [model]="fields.model"
      [class.max-vh-50]="isModal"
      [class.overflow-auto]="isModal"
      class="modal-body mx-8 form-group form-horizontal row mr-10 d-flex mb-6"
    ></formly-form>
    <div
      [ngClass]="{ 'fixed-bottom': !isModal, 'z-index-1': !isModal }"
      class="modal-footer"
    >
      <div *ngIf="!isModal; else defaultButtonLayout">
        <button
          type="button"
          (click)="close()"
          class="btn btn-primary"
          [attr.data-testid]="'back-btn'"
          [disabled]="(isSchedulerLoading$ | async) === true"
        >
          {{ 'GLOBALS.BUTTON.BACK' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>

<ng-template #defaultButtonLayout>
  <button
    type="button"
    (click)="close()"
    class="btn btn-secondary"
    [attr.data-testid]="'setup-form-cancel-btn'"
  >
    {{ 'GLOBALS.BUTTON.CANCEL' | translate }}
  </button>
  <button
    type="submit"
    [disabled]="form.invalid"
    class="btn btn-primary"
    [attr.data-testid]="'setup-form-save-btn'"
  >
    {{ 'GLOBALS.BUTTON.SAVE' | translate }}
  </button>
</ng-template>
