import { Component, ViewEncapsulation } from '@angular/core';

import { BackdropService } from '../services/backdrop.service';

@Component({
  selector: 'app-backdrop',
  templateUrl: './backdrop.component.html',
  styleUrls: ['./backdrop.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BackdropComponent {
  constructor(public backdropService: BackdropService) {}
}
