import { Provider } from '@angular/core';
import * as uuid from 'uuid';

export type Uuid = typeof uuid;

export class UuidService {}

export const UuidProvider: Provider = {
  provide: UuidService,
  useFactory: (): Uuid => uuid,
};
