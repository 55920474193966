import { createAction, props } from '@ngrx/store';

import { Project } from '../../api-client/services/lcas-api-client/models/project.model';
import { Building } from '../../api-client/services/location-api-client/models/building.model';
import { ProductCatalogArticle } from '../../api-client/services/lcas-api-client/models/catalog-product.model';

const DEVICES_CATALOG_TAG = '[Devices Catalog]';
const FUNCTIONS_CATALOG_TAG = '[Functions Catalog]';

export const ActiveProjectActions = {
  set: createAction(
    '[Active Project Set]',
    props<{
      project: Project;
      skipGetBuilding?: boolean;
    }>(),
  ),

  setBuilding: createAction(
    '[Active Building Set]',
    props<{
      building: Building;
    }>(),
  ),
  initLoadDevicesCatalog: createAction(
    `${DEVICES_CATALOG_TAG} Init Load Devices Catalog`,
  ),
  loadDevicesCatalogSuccess: createAction(
    `${DEVICES_CATALOG_TAG} Load Devices Catalog Success`,
    props<{ latestDeviceProducts: ProductCatalogArticle[] }>(),
  ),
  initLoadFunctionsCatalog: createAction(
    `${FUNCTIONS_CATALOG_TAG} Init Load Functions Catalog`,
  ),
  loadFunctionsCatalogSuccess: createAction(
    `${FUNCTIONS_CATALOG_TAG} Load Functions Catalog Success`,
    props<{ latestFunctionsProducts: ProductCatalogArticle[] }>(),
  ),
  clearActiveProjectState: createAction('[Active Project] Clear Store'),
};
