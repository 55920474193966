import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundingDecimal',
  pure: true,
})
export class RoundingDecimalPipe implements PipeTransform {
  transform(
    value: string | number | undefined,
    multipleOf?: string | number | undefined,
  ): string {
    if (value === undefined) {
      return '';
    }

    if (multipleOf === undefined) {
      return Number(value).toFixed(2);
    }

    const decimalPrecision = multipleOf.toString().split('.')[1]?.length ?? 0;

    return (
      Math.round(Number(value) / Number(multipleOf)) * Number(multipleOf)
    ).toFixed(decimalPrecision);
  }
}
