import { Injectable } from '@angular/core';
import { Customer } from '@building-x/common-ui-ng';
import { catchError, Observable, throwError } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { ApiClientModule } from '../../api-client.module';
import { UserPaginationService } from '../pagination/user-pagination.service';

@Injectable({ providedIn: ApiClientModule })
export class UserApiClientService {
  constructor(private userPaginationService: UserPaginationService) {}

  getCustomers(): Observable<Customer[]> {
    return this.userPaginationService
      .getAllPaginatedItems<Customer>(
        `${environment.userApi}/${environment.userApiVersion}/me/customers`,
      )
      .pipe(
        catchError((error) =>
          throwError(
            () =>
              new Error(
                `Customers could not be loaded. ${JSON.stringify(error)}`,
              ),
          ),
        ),
      );
  }
}
