import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import {
  SchedulerApi,
  SchedulerConfig,
  SiSchedulerConfig,
} from '@simpl/scheduler-ng';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SchedulerConstants } from './scheduler.constants';
import { PrototypeValueService } from './services/prototype-value/prototype-value.service';
import { SetupFormOutputService } from '../../setup-form/services/setup-form-output.service';

@Component({
  selector: 'app-scheduler-form-type',
  templateUrl: './scheduler-form-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulerFormTypeComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  readonly schedulerObjectId = 'main';

  private subscriptions: Subscription = new Subscription();

  private cdRef = inject(ChangeDetectorRef);

  constructor(
    private schedulerConfigService: SiSchedulerConfig,
    private schedulerApiService: SchedulerApi,
    private prototypeValueService: PrototypeValueService,
    private setupFormOutputService: SetupFormOutputService,
  ) {
    super();
    this.cdRef.markForCheck();
  }

  ngOnInit(): void {
    const schedulerConfig: SchedulerConfig = {
      ...SchedulerConstants.schedulerConfig,
      continuousSchedule: this.model.continuousSchedule ?? false,
    };
    this.schedulerConfigService.update(schedulerConfig);
    this.prototypeValueService.setPrototypeValue(
      this.schedulerObjectId,
      this.props.prototypeValue,
    );
    this.schedulerApiService.updateWeeklySchedule(
      this.schedulerObjectId,
      this.model.days,
    );
    if (this.model.defaultValue) {
      this.schedulerApiService.updateScheduleOptions(this.schedulerObjectId, {
        defaultValue: +this.model.defaultValue,
      });
    }
    this.subscriptions.add(
      this.schedulerApiService
        .getSchedule(this.schedulerObjectId)
        .pipe(
          filter(
            ({ value }) =>
              !_.isEqual(value.days, this.model.days) ||
              value.defaultValue !== this.model.defaultValue,
          ),
        )
        .subscribe(({ value }) => {
          this.model.days = value.days;
          this.model.defaultValue = value.defaultValue;
          this.setupFormOutputService.routedSetupFormOutput.next(this.model);
          this.setupFormOutputService.isSchedulerLoading$.next(true);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
