import { Customer, Subscription } from '@building-x/common-ui-ng';
import { createAction, props } from '@ngrx/store';

import { UserPermission } from '../models/user-permission.enum';

export const AuthActions = {
  initLogin: createAction(
    '[Auth Init Login]',
    props<{
      shouldLoadOrganizations: boolean;
      shouldSkipSso: boolean;
      redirectComponentRoute?: string[];
    }>(),
  ),
  loginInitiliazed: createAction(
    '[Auth Login Initialized]',
    props<{ loggedIn: boolean }>(),
  ),
  loadCustomers: createAction(
    '[Auth Load Customers]',
    props<{ redirectComponentRoute: string[] }>(),
  ),
  loadCustomersSuccess: createAction('[Auth Load Customers Success]'),
  setCustomers: createAction(
    '[Auth Set Customers]',
    props<{
      customers: Customer[];
      redirectComponentRoute: string[];
    }>(),
  ),
  setSelectedCustomer: createAction(
    '[Auth Set Selected Customer]',
    props<{
      selectedCustomer: Customer;
    }>(),
  ),
  loadSelectedCustomerUserPermissions: createAction(
    '[Auth Load User Permissions For Selected Customer]',
  ),
  setSelectedCustomerUserPermissions: createAction(
    '[Auth Set User Permissions For Selected Customer]',
    props<{
      selectedCustomerUserPermissions: UserPermission[] | undefined;
    }>(),
  ),
  loadSelectedCustomerSubscriptions: createAction(
    '[Auth Load Subscriptions For Selected Customer]',
  ),
  setSelectedCustomerSubscriptions: createAction(
    '[Auth Set Subscriptions For Selected Customer]',
    props<{
      selectedCustomerSubscriptions: Subscription[] | undefined;
    }>(),
  ),
  logOut: createAction('[Auth LogOut]'),
  reset: createAction('[Auth Reset]'),
};
