<div
  class="h-100 w-100 d-flex flex-column elevation-1 position-absolute overflow-auto"
  *ngIf="rows$ | async as rows"
>
  <ng-container *ngIf="form">
    <form [formGroup]="form">
      <ngx-datatable
        siResizeObserver
        #table
        *ngIf="rows.length; else emptyRoom"
        class="table-element h-100 devices-table-scrollbar flex-grow-1 si-layout-fixed-height"
        [cssClasses]="devicesTableConfig?.tableConfig.cssClasses"
        [headerHeight]="devicesTableConfig?.tableConfig.headerHeight"
        [rows]="rows"
        [rowHeight]="devicesTableConfig?.tableConfig.rowHeightSmall"
        [columnMode]="devicesTableConfig?.tableConfig.columnMode"
        [footerHeight]="0"
        (siResizeObserver)="recalculateTable($event)"
      >
        <ngx-datatable-column
          [width]="devicesTableConfig?.tableConfig.actionsColumnWidth"
          [resizeable]="false"
          [canAutoResize]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <img
              class="device-image py-5 my-1"
              [style.cursor]="row.type !== edgeDeviceType && cursorStyle"
              [src]="
                (mediaBaseUrl || '') +
                (row.thumbnail || '/assets/images/device-fallback.svg')
              "
              [width]="40"
              alt="Device image"
              (click)="openDetailsCardOnTap(row)"
              [attr.data-testid]="'device-' + row.instance?.type + '-image'"
            />
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          prop="name"
          [name]="'GLOBALS.TABLE.HEADER.NAME' | translate"
          [headerClass]="devicesTableConfig?.tableConfig.headerClass"
          [cellClass]="devicesTableConfig?.tableConfig.cellClass"
          [minWidth]="devicesTableConfig?.tableConfig.deviceNameColumnMinWidth"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <si-form-container
              *ngIf="!devicesTableConfig?.showReadonlyDeviceName"
              #formContainer
              [form]="form"
              [errorCodeTranslateKeyMap]="errorCodeTranslateKeyMap"
              [controlNameTranslateKeyMap]="controlNameTranslateKeyMap"
              [disableContainerBreakpoints]="true"
            >
              <div si-form-container-content class="d-flex flex-column">
                <si-form-item
                  [ngClass]="
                    formContainer.getValidationErrors(row.id).length
                      ? 'mb-0'
                      : 'mb-4'
                  "
                >
                  <input
                    type="text"
                    class="form-control mt-8"
                    [formControl]="$any(form.controls[row.id])"
                    [attr.data-testid]="
                      'device-' + row.instance?.model + '-name-input'
                    "
                    (keypress)="onInputKeyPress($event, row.id)"
                    [value]="row.name"
                  />
                  <div class="invalid-feedback">
                    @for (
                      error of formContainer.getValidationErrors(row.id);
                      track $index
                    ) {
                      <span>
                        {{
                          error.errorCodeTranslationKey
                            ? (error.errorCodeTranslationKey
                              | translate: error.errorParams)
                            : error.errorCode
                        }}
                      </span>
                    }
                  </div>
                </si-form-item>

                <span class="si-caption ms-2">{{ row.instance.model }}</span>
              </div>
            </si-form-container>

            <div
              *ngIf="devicesTableConfig?.showReadonlyDeviceName"
              class="d-flex flex-column"
              [style.cursor]="row.type !== edgeDeviceType && cursorStyle"
              (click)="openDetailsCardOnTap(row)"
            >
              <span class="si-body-2 mb-5 text-break text-wrap">{{
                row.name
              }}</span>
              <span class="si-caption text-break text-wrap">{{
                row.instance.model
              }}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>

        @if (devicesTableConfig?.showValueColumn) {
          <ngx-datatable-column
            prop="measurement"
            [name]="
              (!isMobileResolution ? 'GLOBALS.TABLE.HEADER.VALUE' : '')
                | translate
            "
            [sortable]="false"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <ng-container
                *ngIf="row.type === edgeDeviceType; else regularDeviceValue"
              >
                <span
                  *ngIf="row.status"
                  [attr.data-testid]="'device-' + row.instance?.type + '-value'"
                  >{{ statusMapping[row.status].text | translate }}</span
                >
              </ng-container>

              <ng-template #regularDeviceValue>
                <ng-container *ngIf="row.measurement">
                  <span
                    [attr.data-testid]="
                      'device-' + row.instance?.type + '-value'
                    "
                    [class.text-muted]="
                      !edgeConnectivityInfo?.connected &&
                      edgeConnectivityInfo?.fetchedConnectivity
                    "
                  >
                    <ng-container
                      *ngIf="
                        row.measurement?.instance?.enum ||
                          (row.measurement?.instance &&
                            row.measurement?.instance['btzf:hasEnumMap']);
                        else primitiveValue
                      "
                    >
                      {{
                        row.measurement.value
                          | propertyEnum: row.measurement.instance
                      }}
                    </ng-container>
                    <ng-template #primitiveValue>
                      {{
                        ![
                          'error',
                          'not-connected',
                          'authentication-failure'
                        ].includes(row?.status)
                          ? (row.measurement.multipleOf
                              ? (row.measurement.value
                                | booleanToNumber
                                | roundingDecimal: row.measurement.multipleOf
                                | localizedDecimal)
                              : (row.measurement.value
                                | booleanToNumber
                                | localizedDecimal)) || '- - -'
                          : '- - -'
                      }}
                    </ng-template>
                  </span>
                  <span
                    [class.text-muted]="
                      !edgeConnectivityInfo?.connected &&
                      edgeConnectivityInfo?.fetchedConnectivity
                    "
                    >{{ row.measurement.unit | translate }}</span
                  >
                </ng-container>
              </ng-template>
            </ng-template>
          </ngx-datatable-column>
        }

        @if (devicesTableConfig?.showStatusColumn && !isMobileResolution) {
          <ngx-datatable-column
            prop="status"
            [name]="'GLOBALS.TABLE.HEADER.STATUS' | translate"
            [resizeable]="false"
            [canAutoResize]="false"
            [sortable]="false"
          >
            <ng-template
              let-row="row"
              let-status="value"
              ngx-datatable-cell-template
            >
              @if (row.status) {
                <i
                  class="si-h1 {{ statusMapping[row.status].icon }}"
                  [attr.data-testid]="
                    'device-' + row.instance?.type + '-row.status'
                  "
                  siTooltip="{{ statusMapping[row.status].text | translate }}"
                  placement="end"
                ></i>
              }
              @if (row.secondaryStatus) {
                <i
                  class="si-h1 {{ statusMapping[row.secondaryStatus].icon }}"
                  [attr.data-testid]="
                    'device-' + row.instance?.type + '-secondary-status'
                  "
                  siTooltip="{{
                    statusMapping[row.secondaryStatus].text | translate
                  }}"
                  placement="end"
                ></i>
              }
            </ng-template>
          </ngx-datatable-column>
        }

        @if (devicesTableConfig?.showAssignButton && !isMobileResolution) {
          <ngx-datatable-column [resizeable]="false" [canAutoResize]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="d-flex justify-content-end mx-sm-5">
                @if (row.setupStatus !== 'COMMISSIONED') {
                  <button
                    type="button"
                    [attr.data-testid]="
                      'device-' + row.instance?.model + '-assign-btn'
                    "
                    [ngClass]="
                      row.setupStatus === 'READY_FOR_RECOMMISSIONING'
                        ? 'btn-warning'
                        : 'btn-primary'
                    "
                    class="btn w-100 d-none d-sm-flex"
                    (click)="assignClick.emit(row)"
                  >
                    <i
                      [ngClass]="
                        row.setupStatus === 'READY_FOR_RECOMMISSIONING'
                          ? 'element-connection-lost '
                          : 'element-scan-qr-code '
                      "
                      class="icon"
                    ></i>
                    @if (row.setupStatus === 'READY_FOR_RECOMMISSIONING') {
                      <span>
                        {{ 'GLOBALS.BUTTON.RECONNECT' | translate }}
                      </span>
                    } @else {
                      <span>
                        {{ 'GLOBALS.BUTTON.ASSIGN' | translate }}
                      </span>
                    }
                  </button>
                }
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        @if (
          devicesTableConfig?.showStatusAndConnectColumn && isMobileResolution
        ) {
          <ngx-datatable-column
            [minWidth]="
              devicesTableConfig?.tableConfig.statusAndConnectColumnWidth
            "
            [maxWidth]="
              devicesTableConfig?.tableConfig.statusAndConnectColumnWidth
            "
          >
            <ng-template
              let-row="row"
              let-status="value"
              ngx-datatable-cell-template
            >
              <div class="d-flex justify-content-center">
                @if (row.setupStatus !== 'COMMISSIONED') {
                  <button
                    (click)="assignClick.emit(row)"
                    type="button"
                    [attr.data-testid]="
                      'device-' + row.instance?.model + '-assign-mobile-btn'
                    "
                    [ngClass]="
                      row.setupStatus === 'READY_FOR_RECOMMISSIONING'
                        ? 'element-connection-lost btn-warning'
                        : 'element-scan-qr-code btn-primary'
                    "
                    class="btn btn-circle"
                    aria-label="badge"
                  ></button>
                } @else {
                  @if (row.status) {
                    <i
                      class="si-h1 {{ statusMapping[row.status].icon }}"
                      [attr.data-testid]="
                        'device-' + row.instance?.type + '-row.status'
                      "
                      siTooltip="{{
                        statusMapping[row.status].text | translate
                      }}"
                      placement="end"
                    ></i>
                  }
                  @if (row.secondaryStatus) {
                    <i
                      class="si-h1 {{
                        statusMapping[row.secondaryStatus].icon
                      }}"
                      [attr.data-testid]="
                        'device-' + row.instance?.type + '-secondary-status'
                      "
                      siTooltip="{{
                        statusMapping[row.secondaryStatus].text | translate
                      }}"
                      placement="end"
                    ></i>
                  }
                }
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        <ngx-datatable-column
          [minWidth]="
            isMobileResolution
              ? devicesTableConfig?.tableConfig
                  .secondaryActionsColumnMinWidthOnSmallerScreens
              : devicesTableConfig?.tableConfig
                  .secondaryActionsColumnMinWidthOnLargerScreens
          "
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="d-flex justify-content-end">
              @if (
                devicesTableConfig?.showDetailsButtonOnSmallerScreens ||
                (!devicesTableConfig?.showDetailsButtonOnSmallerScreens &&
                  !isMobileResolution)
              ) {
                @if (row.primaryActions.length) {
                  <button
                    type="button"
                    [attr.data-testid]="
                      'device-' + row.instance?.model + '-details-btn'
                    "
                    class="btn btn-tertiary d-none d-sm-flex"
                    (click)="detailsClick.emit(row)"
                  >
                    <i class="icon element-settings"></i>
                    <span>
                      {{ 'GLOBALS.BUTTON.DETAILS' | translate }}
                    </span>
                  </button>
                  <button
                    (click)="detailsClick.emit(row)"
                    type="button"
                    [attr.data-testid]="
                      'device-' + row.instance?.model + '-mobile-details-btn'
                    "
                    class="btn btn-circle btn-sm btn-tertiary element-settings d-block d-sm-none"
                    aria-label="badge"
                  ></button>
                }
              }
              @if (row.secondaryActions.length) {
                <si-content-action-bar
                  [secondaryActions]="row.secondaryActions"
                  [actionParam]="row"
                  viewType="expanded"
                  [attr.data-testid]="
                    'action-menu-device-' + row.instance?.model
                  "
                >
                </si-content-action-bar>
              }
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </form>
  </ng-container>
</div>

<ng-template #emptyRoom>
  <app-empty-location
    *ngIf="(isLoadingDevices$ | async) === false"
    [selectedLocation]="selectedLocation"
    [showAddDevicesButton]="devicesTableConfig?.showAddDevicesButton"
    (addDevices)="onAddDevicesClick()"
  ></app-empty-location>
</ng-template>

<ng-template #moveDeviceModal let-modalRef="modalRef">
  <app-move-device-modal
    [device]="selectedDevice"
    [location]="selectedLocation"
    [modalRef]="modalRef"
  ></app-move-device-modal>
</ng-template>

<ng-template #forceDeleteModalTemplate let-modalRef="modalRef">
  <app-force-delete-modal
    [modalRef]="modalRef"
    (forceDelete)="initForceDelete()"
  ></app-force-delete-modal>
</ng-template>
