/* eslint-disable no-unused-vars */
import { ConfigOption, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import {
  booleanValidator,
  floatingPointValidator,
  integerValidator,
} from './validator-functions';

export function registerValidation(
  translateService: TranslateService,
): ConfigOption {
  return {
    validators: [
      {
        name: 'int',
        validation: integerValidator,
      },
      {
        name: 'boolean',
        validation: booleanValidator,
      },
      {
        name: 'floatingPoint',
        validation: floatingPointValidator,
      },
    ],
    validationMessages: [
      {
        name: 'min',
        message: (error: any, field: FormlyFieldConfig) =>
          translateService
            .stream(
              'COMMISSIONING.METERS.ELECTRIC_METER_SETUP.ERROR_MESSAGE.MIN',
              { min: field.props?.min },
            )
            .pipe(map((message) => `${message}`)),
      },
      {
        name: 'max',
        message: (error: any, field: FormlyFieldConfig) =>
          translateService
            .stream(
              'COMMISSIONING.METERS.ELECTRIC_METER_SETUP.ERROR_MESSAGE.MAX',
              { max: field.props?.max },
            )
            .pipe(map((message) => `${message}`)),
      },
      {
        name: 'int',
        message: (error: any, field: FormlyFieldConfig) =>
          translateService.stream(
            'COMMISSIONING.METERS.ELECTRIC_METER_SETUP.ERROR_MESSAGE.INT',
          ),
      },
      {
        name: 'boolean',
        message: (error: any, field: FormlyFieldConfig) =>
          translateService.stream(
            'COMMISSIONING.SETUP_FORM.ERROR_MESSAGE.BOOLEAN',
          ),
      },
      {
        name: 'floatingPoint',
        message: (error: any, field: FormlyFieldConfig) =>
          translateService.stream(
            'COMMISSIONING.SETUP_FORM.ERROR_MESSAGE.FLOATING_POINT',
          ),
      },
    ],
  };
}
