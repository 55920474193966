import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalRef } from '@simpl/element-ng';

import { DisciplinesAndFunctionsState } from '../../sidemenu/commissioning/disciplines-and-functions/models/disciplines-and-functions-state.model';
import { FunctionsCommissioningActions } from '../../sidemenu/commissioning/disciplines-and-functions/store/disciplines-and-functions.actions';

@Component({
  selector: 'app-force-delete-modal',
  templateUrl: './force-delete-modal.component.html',
})
export class ForceDeleteModalComponent {
  constructor(
    private store: Store<{
      functionsCommissioning: DisciplinesAndFunctionsState;
    }>,
  ) {}

  @Input() modalRef?: ModalRef;

  @Output() forceDelete = new EventEmitter();

  onForceDelete(): void {
    this.forceDelete.emit();
    this.modalRef?.hide();
  }

  onCloseModal(): void {
    this.store.dispatch(
      FunctionsCommissioningActions.forceDeleteFunctionCancel(),
    );
    this.modalRef?.hide();
  }
}
