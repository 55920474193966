import { Injectable } from '@angular/core';
import { SiToast, SiToastNotificationService } from '@simpl/element-ng';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SessionService {
  private readonly isSessionExpiredMessageActive = new BehaviorSubject(false);

  private readonly isSessionTerminating = new BehaviorSubject(false);

  private activeExpirationToast: SiToast | undefined;

  private readonly logoutTimeout = 5000;

  constructor(
    private siToastNotificationService: SiToastNotificationService,
    private oauthService: OAuthService,
  ) {}

  terminateExpiredSession(): void {
    if (
      !this.isSessionExpiredMessageActive.value &&
      !this.isSessionTerminating.value
    ) {
      this.isSessionTerminating.next(true);
      this.isSessionExpiredMessageActive.next(true);
      this.showToast('GLOBALS.SESSION_EXPIRED');
      setTimeout(() => {
        this.siToastNotificationService.hideToastNotification(
          this.activeExpirationToast,
        );
        this.oauthService.logOut();
        this.isSessionExpiredMessageActive.next(false);
      }, this.logoutTimeout);
    }
  }

  private showToast(message: string): void {
    this.activeExpirationToast =
      this.siToastNotificationService.queueToastNotification(
        'danger',
        message,
        '',
        true,
      );
  }
}
