import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SiToastNotificationService } from '@simpl/element-ng';
import { sortBy } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { first, map, mergeMap, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { PointApiClientService } from '../../../shared/api-client/services/point-api-client/point-api-client.service';
import { statusMapping } from '../constants/device-status.constants';
import { EdgeDeviceState } from '../../../shared/devices-commissioning/models/edge-device-state.model';
import { EdgeConnectivityInfo } from '../../../shared/models/edge-connectivity-info.model';
import { functionStatusMapping } from '../../../sidemenu/commissioning/disciplines-and-functions/functions/functions-table/constants/function-status-mapping.constants';
import { DeviceDetailsRow } from '../generic-components/devices-details/models/device-details-row.model';
import { DeviceTableProperty } from '../generic-components/devices-details/models/device-table-property.model';
import { DetailCardProperty } from './models/detail-card-property.model';
import { DetailPropertiesPollingService } from './services/detail-properties-polling.service';
import { DetailsCardSessionStorageService } from './services/details-card-session-storage.service';
import { DetailsPollingService } from './services/details-polling.service';

@Component({
  selector: 'app-details-card',
  templateUrl: './details-card.component.html',
  styleUrls: ['./details-card.component.scss'],
  providers: [DetailsPollingService, DetailPropertiesPollingService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsCardComponent implements OnChanges, OnDestroy {
  @Input() device: DeviceDetailsRow | undefined;

  @Input() edgeConnectivityInfo: EdgeConnectivityInfo | undefined;

  @Output() closeDetails: EventEmitter<void> = new EventEmitter();

  readonly statusMapping = { ...statusMapping, ...functionStatusMapping };

  readonly mediaBaseUrl = environment.mediaBaseUrl;

  formGroup?: FormGroup;

  properties$?: Observable<DetailCardProperty[]>;

  inputs: FormControl[] = [];

  formControlsSubscription: Subscription = new Subscription();

  isPointPatchInProgress = false;

  constructor(
    public pollingService: DetailPropertiesPollingService,
    private detailsPollingService: DetailsPollingService,
    private pointApiClientService: PointApiClientService,
    private store: Store<{ edgeDeviceState: EdgeDeviceState }>,
    private siToastNotificationService: SiToastNotificationService,
    private detailsCardSessionStorageService: DetailsCardSessionStorageService,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(): void {
    this.properties$ = this.getProperties();
    if (this.hasInstanceAndProperties(this.device)) {
      const orderedPropertiesArray = this.createOrderedPropertiesArray(
        this.device!,
      );

      this.device = this.constructDeviceWithOrderedProperties(
        this.device!,
        orderedPropertiesArray,
      );
    }
  }

  ngOnDestroy(): void {
    this.closeDetails.emit();
    this.formControlsSubscription.unsubscribe();
  }

  hasInstanceAndProperties(
    deviceOrFunction: DeviceDetailsRow | undefined,
  ): boolean {
    return !!deviceOrFunction?.instance?.properties;
  }

  createOrderedPropertiesArray(deviceOrFunction: DeviceDetailsRow): {
    prop: DeviceTableProperty;
    name: string;
  }[] {
    return sortBy(
      Object.keys(deviceOrFunction.instance!.properties!).map((index) => ({
        prop: deviceOrFunction.instance!.properties![index],
        name: index,
      })),
      (property) => property.prop.unit,
    ).sort((p1, p2) => {
      if (p1.prop.unit === p2.prop.unit) {
        return p1.name > p2.name ? 1 : -1;
      }
      return 1;
    });
  }

  constructDeviceWithOrderedProperties(
    deviceOrFunction: DeviceDetailsRow,
    orderedProperties: {
      prop: DeviceTableProperty;
      name: string;
    }[],
  ): DeviceDetailsRow {
    return {
      ...deviceOrFunction,
      instance: {
        ...this.device?.instance,
        properties: orderedProperties.reduce(
          (acc, curr) => ({ ...acc, [curr.name]: curr.prop }),
          {},
        ),
        version: this.device?.instance?.version ?? {
          model: this.translateService.instant('ERRORS.UNKNOWN_VERSION'),
        },
      },
    };
  }

  startPolling(): void {
    this.pollingService.resume();
  }

  stopPolling(): void {
    this.pollingService.pause();
  }

  setValueForPoint(valueObject?: { id: string; value: any }): void {
    if (valueObject) {
      this.detailsCardSessionStorageService.addId(
        valueObject?.id,
        valueObject.value,
      );
      this.isPointPatchInProgress = true;
      this.pointApiClientService
        .setPoint(valueObject.id, `${valueObject.value}`)
        .pipe(first())
        .subscribe(() => {
          this.isPointPatchInProgress = false;
        });
    }
  }

  getProperties(): Observable<DetailCardProperty[]> {
    return this.store
      .select((state) => state.edgeDeviceState.edgeDevice)
      .pipe(
        first(),
        mergeMap((edgeDevice) =>
          edgeDevice?.instance?.edgeConfig?.appId &&
          this.device?.setupStatus === 'COMMISSIONED'
            ? this.detailsPollingService.getPropertiesWithRealTimeData(
                this.device,
              )
            : this.detailsPollingService.getTemplate(this.device!).pipe(
                tap(() => {
                  if (!edgeDevice?.instance?.edgeConfig?.appId) {
                    this.showEdgeOfflineToast(
                      'PLANNING.PHYSICAL_ENVIRONMENT.TOAST.MESSAGE.OFFLINE_DETAILS_CARD',
                    );
                  }
                }),
              ),
        ),
        map((properties) =>
          properties.filter((item) => !!item.data['lcas:cloudPush']),
        ),
        // the following map can be removed once the FW reliably returns boolean values instead of numbers
        map((properties) =>
          properties.map((property) =>
            property.data.type === 'boolean' && !Number.isNaN(+property.value!)
              ? {
                  ...property,
                  value: property.value === '0' ? 'false' : 'true',
                }
              : property,
          ),
        ),
        map((properties) => {
          this.detailsCardSessionStorageService.deleteOldIds();
          return this.mapStorageValueIfAvailable(properties);
        }),
      );
  }

  private mapStorageValueIfAvailable(
    properties: DetailCardProperty[],
  ): DetailCardProperty[] {
    return properties.map((property) => {
      const storedSessionProperty = this.detailsCardSessionStorageService.getId(
        property.id!,
      );
      return storedSessionProperty
        ? { ...property, value: storedSessionProperty.value }
        : property;
    });
  }

  private showEdgeOfflineToast(messageContent: string): void {
    this.siToastNotificationService.queueToastNotification(
      'info',
      'PLANNING.PHYSICAL_ENVIRONMENT.TOAST.TITLE.NO_EDGE_DEVICE_CONNECTED',
      messageContent,
    );
  }
}
