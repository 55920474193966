import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permission } from '@building-x/common-ui-ng';
import { map, Observable } from 'rxjs';

import { ApiClientHelperService } from '../api-client-helper.service';
import { environment } from '../../../../../environments/environment';
import { ApiClientModule } from '../../api-client.module';

@Injectable({ providedIn: ApiClientModule })
export class AodsApiClientService {
  constructor(
    private apiHelperService: ApiClientHelperService,
    private http: HttpClient,
  ) {}

  getPermissionsByCurrentCustomerId(): Observable<Permission> {
    return this.apiHelperService.callAndAddStore((store) =>
      this.getPermissionsByCustomerId(store.customer?.id || ''),
    );
  }

  getPermissionsByCustomerId(selectedCustomer: string): Observable<Permission> {
    return this.http
      .get<{
        data: { attributes: Permission[] };
      }>(
        `${environment.aodsApi}/${environment.aodsApiVersion}/me/permissions/customers/${selectedCustomer}`,
      )
      .pipe(map((response) => response?.data.attributes[0] || []));
  }
}
